import React from 'react'

const IconCall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37.622}
    height={37.622}
    viewBox="0 0 37.622 37.622"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rect\xE1ngulo 1132"
          fill="#fff"
          d="M0 0h37.622v37.622H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 231" clipPath="url(#a)">
      <path
        data-name="Trazado 266"
        d="M18.8 0A18.811 18.811 0 1 1 0 18.812 18.814 18.814 0 0 1 18.8 0m4.64 32.508a9.859 9.859 0 0 0 2.863-.449 6.4 6.4 0 0 0 1.846-.814 1.409 1.409 0 0 0 .481-2.182c-.237-.391-.5-.764-.76-1.143a24.717 24.717 0 0 0-2.747-3.4c-.66-.675-1.1-.7-1.855-.121a14.9 14.9 0 0 0-1.272 1.175c-.794.765-1.339.764-2.127-.015-.039-.038-.076-.078-.114-.117a26.084 26.084 0 0 1-5.387-8.842 6.626 6.626 0 0 1-.479-1.794 1.007 1.007 0 0 1 .653-1.125 5.269 5.269 0 0 1 .68-.229 15.03 15.03 0 0 0 1.524-.485 1.134 1.134 0 0 0 .777-1.581 19.981 19.981 0 0 0-.875-2.53 24.521 24.521 0 0 0-1.428-3.007 1.3 1.3 0 0 0-1.784-.613 2.966 2.966 0 0 0-.7.353c-3.346 2.38-4.568 5.517-3.9 9.167a22.1 22.1 0 0 0 1.906 5.515 40.391 40.391 0 0 0 2.613 4.671 23.9 23.9 0 0 0 4.406 5.317 8.475 8.475 0 0 0 5.675 2.247"
        fill="#fff"
      />
    </g>
  </svg>
);
export default IconCall;
