import { useState } from "react";

const useSlider = (content) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [nextSlide, setNextSlide] = useState(1);

    const handleNext = () => {
        setCurrentSlide((prevItem) => (prevItem === content.length - 1 ? 0 : prevItem + 1));
        setNextSlide((prevItem) => (prevItem === content.length - 1 ? 0 : prevItem + 1))
      }
      const handlePrevious = () => {
        setCurrentSlide((prevItem) => (prevItem === 0 ? content.length - 1 : prevItem - 1));
        setNextSlide((prevItem) => (prevItem === 1 ? 0 : content.length - 1));
      };
    return {
        currentSlide,
        handleNext,
        handlePrevious,
        nextSlide
    }
}

export default useSlider;