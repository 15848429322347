import React from "react";
const Location = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rect\xE1ngulo_1226"
          data-name="Rect\xE1ngulo 1226"
          width={6.861}
          height={9.5}
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="Grupo_5343" data-name="Grupo 5343" transform="translate(9920 21503)">
      <circle
        id="Elipse_254"
        data-name="Elipse 254"
        cx={9.5}
        cy={9.5}
        r={9.5}
        transform="translate(-9920 -21503)"
        fill="#5d2648"
      />
      <g
        id="Grupo_4883"
        data-name="Grupo 4883"
        transform="translate(-9913.931 -21498.25)"
      >
        <g id="Grupo_342" data-name="Grupo 342">
          <g id="Grupo_341" data-name="Grupo 341" clipPath="url(#clip-path)">
            <path
              id="Trazado_3420"
              data-name="Trazado 3420"
              d="M6.835,3.045A3.375,3.375,0,0,0,5.536.724,3.351,3.351,0,0,0,4.118.068C4,.044,3.881.033,3.762.017c-.015,0-.031,0-.037-.018H3.1A.733.733,0,0,1,2.849.05,3.372,3.372,0,0,0,.419,1.787,3.291,3.291,0,0,0,.008,3.664a3.685,3.685,0,0,0,.506,1.6q1.1,1.916,2.21,3.829a.809.809,0,0,0,1.4.015Q5.28,7.123,6.413,5.129a3.34,3.34,0,0,0,.423-2.083m-3.4,1.394A1.248,1.248,0,1,1,4.675,3.182,1.246,1.246,0,0,1,3.433,4.439"
              transform="translate(0.001 0.001)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Location;
