import React from 'react'
import bg from '../../assets/despacho/internacional/ND-AlcanceInt_escritorio.jpg'

function InternacionalContainer({items}){
    return(
        <div id="international-reach" className='flex w-screen  h-full bg-[#6F2A43] flex-col md:flex-row'>
            <div className='flex md:w-3/6 w-full py-[80px] md:py-0'>
                <div className='flex flex-col md:pt-[180px] pt-0 md:pl-[120px] pl-0'>
                    <div className='flex flex-col justify-center md:border-l-[#D1C5B2] md:border-solid md:border-l-4 p-[20px]'>
                        <p style={{fontFamily: 'Merriweather'}} className='text-[#fff] sm:text-[40px] text-[30px]'>{items.title}</p>
                        <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] sm:text-[30px] text-[20px]'>{items.subtitle}</p>
                    </div>
                    <div style={{fontFamily:'Poppins Light'}} className='text-[#fff] text-[16px] sm-px-0 px-[30px] max-w-[590px]'>
                        <p dangerouslySetInnerHTML={{ __html: items.p1 }} className='mb-[30px]'/>
                        <p>{items.p2}</p>
                    </div>
                </div>
            </div>
            <div className='md:w-3/6 w-full'>
                <img src={bg} className='max-h-[673px] md:h-screen w-full'></img>
            </div>
        </div>
    )
}

export default InternacionalContainer;