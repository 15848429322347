import React from 'react'
import useSlider from '../../hooks/slider'
import Button from '../../components/common/Button'
import ArrowLeft from '../../assets/common/ArrowLeft'
import ArrowRight from '../../assets/common/ArrowRight'
import { useLocation } from "wouter";

function SociosSlider({ items }){
    const [location, setLocation] = useLocation();
    const slider = useSlider(items)

    const navigate = (url) => {
        setLocation(url);
      };

    return (
            <div className='flex justify-center items-center w-full lg:mt-[-50px] px-[20px] py-[20px] sm:p-0 sm:py-0'>
                <div className='sm:mr-[70px]'>
                    <Button click={slider.handlePrevious} hover="sm:hover:bg-[#D1C5B2] hover:none" backgroundColor="sm:bg-primary bg-none" width="w-[50px] h-[50px] flex justify-center items-center" border="sm:rounded-[8px] sm:border-solid sm:border-2 sm:border-[#fff] border-none">
                        <div className='sm:w-[9px] w-[16px]'>
                            <ArrowLeft fill='#fff'/>
                        </div>
                    </Button>
                </div>
                <div className='relative w-[240px] sm:w-auto'>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='first-image relative z-10'>
                            <img src={items[slider.currentSlide].image}></img>
                        </div>
                        <div className='second-image absolute sm:top-0 top-[65px] sm:left-[-80px] left-[90px] w-[200px] sm:w-auto'>
                            <img src={items[slider.nextSlide].image_2}></img> 
                        </div>
                        <p style={{fontFamily: 'Merriweather Bold'}} className='text-[#fff] sm:text-[40px] text-[24px] mt-[20px] mb-[15px]'>{items[slider.currentSlide].name}</p> 
                        <p style={{fontFamily: 'Poppins Light'}} className='sm:text-[18px] text-[14px] text-[#fff] mb-[40px]'>{items[slider.currentSlide].title}</p>
                        <Button click={()=> navigate(items[slider.currentSlide].link)} backgroundColor="bg-[#fff]" color="text-[#000]" hover="hover:bg-none">{items[slider.currentSlide].button}</Button>
                    </div>
                </div>
                <div className='relative z-10'>
                    <Button click={slider.handleNext} hover="sm:hover:bg-[#D1C5B2] hover:none" backgroundColor="sm:bg-primary bg-none" width="w-[50px] h-[50px] flex justify-center items-center" border="sm:rounded-[8px] sm:border-solid sm:border-2 sm:border-[#fff] border-none">
                        <div className='sm:w-[9px] w-[16px]'>
                            <ArrowRight fill='#fff'/>
                        </div>
                    </Button>
                </div>
            </div>
    )
}

export default SociosSlider;