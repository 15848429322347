const es = {
    "slider": [
        {
            "title": "Porque estamos convencidos de ello y lo valoramos, mantenemos firme nuestra responsabilidad frente a:",
            "list":["<span class='mr-[5px]'>•</span> nuestros clientes", "<span class='mr-[5px]'>•</span> la sociedad", "<span class='mr-[5px]'>•</span> la diversidad", "<span class='mr-[5px]'>•</span> la incluisión", "<span class='mr-[5px]'>•</span> la sustentabilidad", "<span class='mr-[5px]'>•</span> las oportunidades laborales"],
            "p": "Conoce más acerca de nuestro Despacho, sus valores, su enfoque y sus compromisos."
        },
        {
            "title": "Creel/abogados:",
            "subtitle": "contigo en la misma dirección",
            "list": ["<div class='flex'><span class='mr-[5px]'>•</span><p style='font-family: Poppins Medium' class='mr-[5px]'>Compromiso</p> con nuestros clientes</div>", 
            "<div class='flex'><span class='mr-[5px]'>•</span><p style='font-family: Poppins Medium' class='mr-[5px]'> Conocimiento</p> del entorno de negocios</div>", 
            "<div class='flex'><span class='mr-[5px]'>•</span><p style='font-family: Poppins Medium' class='mr-[5px]'> Constancia, consistencia y confianza en nuestros servicios</p>",
        ],
            "p": "Conoce más acerca de nuestro Despacho, sus valores, su enfoque y sus compromisos."
        },
        {
            "title": "Nuestros servicios están sustentados en nuestro compromiso frente a los clientes y la convicción de construir relaciones duraderas.",
            "p": "Conoce más acerca de nuestro Despacho, sus valores, su enfoque y sus compromisos."
        }
    ],
    "expertos": {
        "title": "Expertos en Derecho de los Negocios",
        "subtitle": "Asumimos de forma integral y selectiva retos y asuntos complejos de nuestros clientes,",
        "subtitle2": "anticipando problemas y diseñando oportunamente soluciones efectivas.",
        "text": "Excelencia al servicio de negocios responsables;",
        "text2": "siempre pensando y actuando en la dirección correcta."
    },
    "somos": {
        "title": "Somos un Despacho comprometido con sus clientes",
        "tabs": [
            {
                "key": "valores",
                "value": "Valores"
            },
            {
                "key": "enfoque",
                "value": "Enfoque"
            },
            {
                "key": "compromiso",
                "value": "Compromiso"
            }
        ],
        "content": {
            "valores": {
                "items": [
                    "<p class='pb-[20px]'> <span style='font-family: Poppins Medium'> Confianza, respeto, integridad, excelencia, tradición e innovación </span> son los valores que nos unen en <span style='font-family: Poppins Medium'> Creel/abogados </span>; que promovemos y protegemos. Nuestros valores definen quienes somos, qué defendemos y cómo realizamos nuestro trabajo. Nos mantienen alineados y nos permiten alcanzar los altos estándares que nuestros clientes exigen. </p>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> Nuestras relaciones están basadas en la confianza y el respeto, fomentamos la colaboración dentro del Despacho y creamos vínculos con claridad, apertura y transparencia. </p></div>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p>  La prestación de servicios responsables con un sentido honesto y ético de trabajo es tan importante para nosotros, como llevarlos a cabo con impecable preparación y ejecución, siempre aspirando a la excelencia. </p></div>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> Valoramos nuestras raíces y resguardamos la tradición y cultura de nuestro Despacho. Día a día nos esforzamos por mantener vigente el legado de nuestros fundadores y fortalecer su espíritu y principios de servicio. </p></div>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> Reconociendo la relevancia de nuestra historia, estamos asimismo conscientes del dinamismo de los negocios y constantemente buscamos desarrollar y mejorar nuestro desempeño, y ser siempre innovadores para responder a los retos futuros de nuestros clientes. </p></div>"
                ],
                "image": require('../../assets/despacho/somos/ND-Valores_escritorio.jpg')
            },
            "enfoque": {
                "p1": "<div class='flex'> <span class='ml-[20px] mr-[10px]'> •</span> <p> Enfocamos nuestros servicios en el conocimiento, la anticipación y la atención de las necesidades estructurales y estratégicas de nuestros clientes.</p></div>",
                "p2": "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> Brindamos asesoría jurídica especializada en las Áreas de Práctica relacionadas con los negocios: desde la organización y mantenimiento de las operaciones de nuestros clientes, hasta sus proyectos de expansión y consolidación. </p></div>",
                "image": require('../../assets/despacho/somos/enfoque_escritorio.jpg')
            },
            "compromiso": {
                "p1": "<div class='flex'> <span class='ml-[20px] mr-[10px]'> •</span> <p> Somos un Despacho comprometido con sus clientes.</p></div>",
                "p2": "<div class='flex'> <span class='ml-[20px] mr-[10px]'> •</span> <p> Dedicamos nuestros esfuerzos en brindar a nuestros clientes asesoría de la más alta calidad y velamos celosamente por sus intereses, para construir con ellos relaciones estables y duraderas.</p></div>",
                "p3": "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> El servicio profesional, responsable y oportuno que prestamos es el eje de nuestro Despacho.</p></div>",
                "image": require('../../assets/despacho/somos/enfoque_escritorio.jpg')
            },
            "text": "Nuestros valores, enfoque y compromiso son nuestros principios rectores y nos han permitido consolidarnos como un Despacho de primer nivel y alcance regional e internacional."
        }
    },
    "sustentabilidad": {
        "title": "Sustentabilidad",
        "subtitle": "Para nosotros el futuro es tan importante como el presente, en el que podemos desarrollar nuestro ejercicio profesional.",
        "p1": "Para dicho fin, participamos en diversas iniciativas y acciones que buscan crear un equilibrio entre el crecimiento económico, el cuidado del medio ambiente y el bienestar social.",
        "p2": "Sabemos que este recorrido a la sustentabilidad es algo que se construye y conocemos la trayectoria que aún nos falta por recorrer, pero estamos trabajando en sumar todos los días nuevas estrategias que apoyen esta causa."
    },
    "cultura": {
        "title": "Cultura Creel/abogados",
        "p": "Operamos dentro de una cultura que privilegia la excelencia en el servicio, el trabajo en equipo y el desarrollo profesional de sus miembros.",
        "accordion": [
            {
                "title": "Conocimiento, Capacitación y Resultados",
                "p": ["Procuramos conocer y entender el entorno de negocios, las actividades y la estrategia de nuestros clientes, a fin de brindarles asesoría legal diseñada específicamente para atender cada asunto y operación desde su misma óptica y perspectiva; adaptándonos a sus necesidades particulares y alineando nuestros objetivos con sus expectativas.", "Reconocemos el carácter dinámico y cambiante de la economía y los mercados, y por ello nos actualizamos y capacitamos constantemente para mantenernos a la vanguardia; proporcionando soluciones creativas e innovadoras en nuestras Áreas de Práctica."]
            },
            {
                "title": "Experiencia y Especialidad",
                "p": ["La base de nuestra práctica profesional es el Derecho Corporativo, teniendo como eje central a las empresas y hacia ellas se enfocan nuestras Áreas de Práctica especializadas: Derecho Corporativo y Mercantil, Fusiones y Adquisiciones, Derecho Financiero, Derecho Fiscal, Derecho Inmobiliario, Derecho Laboral, Competencia Económica, Litigio y Solución de Controversias, Recursos Naturales, ASG y Cumplimiento Regulatorio.", "Nuestros servicios legales se sustentan en una sólida experiencia y conocimientos especializados en nuestras Áreas de Práctica, lo que nos permite brindar a nuestros clientes asesoría jurídica especializada para cada asunto y operación."] 
            },
            {
                "title": "Red de Relaciones",
                "p": ["Con la probada experiencia y especialidad de nuestros Equipos, complementada con nuestra red de relaciones con firmas de abogados líderes en otras regiones del país y en las principales capitales del mundo, proveemos una cobertura completa de los servicios legales requeridos por nuestros clientes en el desarrollo de sus negocios tanto en México como en el extranjero.", "Nuestra red de relaciones se enriquece con un conjunto de firmas de abogados y asesores independientes que abarca una diversidad de áreas de especialidad, incluyendo especialistas en Economía, Política y Finanzas, así como múltiples sectores industriales, tales como el inmobiliario, construcción, farmacéutico, aeronáutico, deporte, entretenimiento, tecnología y medios de comunicación, entre otros."]
            },
            {
                "title": "Trabajo en Equipo",
                "p": ["Contamos con un Equipo de talentosos abogados graduados de las mejores universidades del país. Nuestros Socios y Asociados cuentan con estudios de postgrado en reconocidas universidades de México, Estados Unidos y Europa, y fueron entrenados en firmas de abogados líderes en el mundo. Todos los miembros de nuestro Equipo reciben capacitación continua en nuestras Áreas de Práctica, lo que les permite mantenerse siempre al día y desarrollar adecuadamente sus habilidades.", "A través de equipos de abogados cuidadosamente seleccionados, brindamos la atención personalizada requerida para atender cada asunto u operación que se nos encomienda. Nuestros Equipos de Trabajo son liderados por un Socio, quien actúa como enlace principal con el cliente, coordina y supervisa al Equipo, y se responsabiliza de la prestación de nuestros servicios y del logro de los objetivos planteados por el cliente."]
            },
            {
                "title": "Desarrollo Profesional",
                "p": ["Reconociendo que las instituciones trascienden a las personas que las integran, estamos convencidos que el desarrollo profesional de nuestros abogados es indispensable para garantizar la calidad de los servicios que prestamos, así como para fortalecer la vigencia, continuidad y proyección del Despacho.", "Nuestra vocación por el desarrollo profesional de los miembros del Despacho se sustenta en la convicción de ser responsables del firme y sostenido crecimiento de nuestro personal. Promovemos un entorno laboral que fomente nuestra cultura y espíritu de trabajo, y que con la debida supervisión de los socios permita a los miembros de nuestro Equipo involucrarse activamente y de manera progresiva en proyectos cada vez más sofisticados y demandantes.", "En la motivación y preparación de nuestro Equipo radica la solidez de su crecimiento profesional y a la vez, el impulso en la evolución ordenada del Despacho."]
            }
        ],
        "title2": "En Creel/abogados impulsamos la igualdad de oportunidades para todos nuestros colaboradores.",
        "p2": "Por ello, contamos con un programa de diversidad e inclusión dentro de nuestra organización."
    },
    "proBono": {
        "title": "Pro Bono",
        "subtitle": "Responsabilidad e integridad en nuestras acciones con la sociedad y la comunidad.",
        "p1": "Reconocemos la importancia de la labor altruista y por ello promovemos la participación activa de todos los miembros del Despacho en asuntos con alto impacto social y en la asesoría legal gratuita a fundaciones e instituciones de asistencia privada.",
        "p2": "Prestamos asesoría pro bono a diversas instituciones y fundaciones, en muy diversas áreas y con distintas iniciativas de apoyo a la sociedad en general, incluyendo a organizaciones sociales privadas enfocadas en sectores como lo son la Educación y la Salud, así como a grupos y comunidades en condición de vulnerabilidad. Entre las instituciones y fundaciones que asistimos, se encuentran:"
    },
    "internacional": {
        "title": "Alcance Internacional",
        "subtitle": "Alianzas estratégicas de alto valor.",
        "p1": "<span style='font-family: Poppins Medium'>Creel/abogados</span> tiene un alcance global. A través de los años, hemos identificado en diversas regiones y jurisdicciones del mundo a las firmas de abogados líderes que comparten nuestro enfoque en la excelencia del servicio y nuestros valores.",
        "p2": "Mediante la colaboración en transacciones y proyectos hemos confirmado su calidad y valores, creando una red de aliados sin igual, para el beneficio integral de nuestros clientes."
    },
    "banner":{
        "title": "El legado Creel/abogados surge hace más de medio siglo y es testimonio de tres generaciones de distinguidos abogados corporativos.",
        "subtitle": "Nos sentimos orgullosos de nuestra historia y valoramos nuestra tradición y sólida cultura en el Despacho.",
        "button": "CONOCE NUESTRA HISTORIA"
    },
    "unete": {
        "title": "Únete al Equipo de Creel/abogados y suma tu experiencia a nuestro Despacho, para continuar creciendo juntos",
        "subtitle": "Deja tus datos para contactarte a la mayor brevedad.",
        "p": "Buscamos estudiantes de Derecho y jóvenes abogados que se interesen en formar parte de nuestro Equipo, que se identifiquen con la práctica, cultura y espíritu del Despacho, que los motiven los retos y se interesen en el desarrollo integral de su profesión dentro de un entorno que los expone a las operaciones más complejas y demandantes del mercado.",
        "tabs": [
            {
                "key": "capacitacion",
                "value": "Capacitación, Mentoría y Rotación de Prácticas"
            },
            {
                "key": "internacional",
                "value": "Experiencia Internacional"
            },
            {
                "key": "profesional",
                "value": "Algo más que un trabajo"
            }
        ],
        "content": {
            "capacitacion": {
                "p1": "Dedicamos nuestro esfuerzo a la constante capacitación de nuestros pasantes y asociados, lo cual llevamos a cabo mediante la interacción diaria con los Socios y Asociados de más experiencia, la exposición paulatina y ordenada en los asuntos y operaciones de nuestros clientes, y la constante realización de cursos, seminarios y mesas redondas en áreas y temática de actualidad dirigidas por los socios y reconocidos expertos en las materias que se tratan.",
                "p2": "Cada pasante y abogado cuenta con el apoyo de un mentor, que supervisa su trabajo cotidiano, lo apoya en la realización de sus tareas y asignaturas, tiene a su cargo transmitir los principios de trabajo, calidad, dedicación y responsabilidad del Despacho, para contribuir en el desarrollo integral de sus competencias profesionales y personales.",
                "p3": "Los pasantes que cursan su último año de estudios y en tanto reciben su título profesional, son invitados a conocer y familiarizarse con nuestras Áreas de Práctica, con el propósito de que cuenten con la oportunidad de interactuar con los diferentes grupos de especialidad, desarrollen un mayor conocimiento del Derecho y definan su vocación por una práctica profesional en particular.",
                "image": require('../../assets/despacho/unete/ND-Unete-Capacitacion_escritorio.jpg')
            },
            "internacional": {
                "p1": "Convencidos del valor que genera la experiencia académica y práctica en el extranjero, como parte del crecimiento profesional de nuestros abogados, procuramos su desarrollo a través de la participación en cursos de especialidad y programas de postgrado en reconocidas Universidades de los Estados Unidos y Europa, así como del acceso a programas de entrenamiento dentro de firmas de abogados líderes en el mundo con las que mantenemos relaciones de colaboración.",
                "image": require('../../assets/despacho/unete/ND-Unete-exp_escritorio_2.jpg')
            },
            "profesional": {
                "p1": "Para nosotros el Despacho es un lugar en donde no solamente se trabaja, sino en donde también se establecen vínculos de amistad y se comparten aficiones y preocupaciones por los problemas de la sociedad.",
                "p2": "Nos esforzamos mantener siempre un ambiente cordial y respetuoso que genere la seguridad y confianza en la pertenencia a un Equipo profesional que valora no solo la dedicación y excelencia en el trabajo, sino el desarrollo integral de nuestro Equipo, así como el firme compromiso de contribuir activamente en proyectos de impacto social.",
                "p3": "Recursos Humanos: +52 (55) 1167-3106; rh@creelabogados.com",
                "image": require('../../assets/despacho/unete/ND-Unete-trabajo_escritorio_3.jpg')
            }
        },
        "form": {
            "title": "Llenar formulario",
            "description": "Campos obligatorios",
            "termns": "Acepto los términos del Aviso de Privacidad",
            "button": "Enviar",
            "fields": {
                "name": "Nombre",
                "lastname": "Apellido",
                "email": "Correo electrónico",
                "phone": "Teléfono",
                "company": "Universidad/Empresa",
                "country": "País",
                "comments": "Comentarios"
            }
        }
    }
}

export default es;