const es = {
    "team": [
        {
            "name": "ALFONSO RAZÚ A.",
            "phone": "+52 (55) 1167-3027",
            "email": "alfonso.razu@creelabogados.com",
            "image": require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
            "more": "Conocer más", 
            "link": "/partners/4",
        },
       {
        "name": "PABLO ARELLANO Q.",
        "phone": "+52 (55) 1167-3040",
        "email": "pablo.arellano@creelabogados.com",
        "image": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
        "more": "Conocer más",
        "link": "/partners/7",
    },
       {
        "name": "TSEN FANG-LIN",
        "phone": "+52 (55) 1167-3034",
        "email": "tsen-fang.lin@creelabogados.com",
        "image": require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg'),
        "more": "Conocer más",
        "link": "/associates/4",
    },
        {
            "name": "PABLO TORRES A.",
            "phone": "+52 (55) 1167-3039",
            "email": "pablo.torres@creelabogados.com",
            "image": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg'),
            "more": "Conocer más",
            "link": "/associates/11",
        },
    ],
    "button": "CONOCE A NUESTRO EQUIPO"
}
export default es;