import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'
import Layout from '../../Layout/MainLayout'
import HeaderContainer from './HeaderContainer';
import FormContainer from './FormContainer';
import MapContainer from './MapContainer';

import esLang from '../../translations/contact/es.js'
import enLang from '../../translations/contact/en.js'

function Contacto(){
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <Layout>
            <HeaderContainer items={translation}/>
            <FormContainer items={translation} />
            <MapContainer/>
        </Layout>
        
)}
export default Contacto;