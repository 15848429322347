import React from "react";
const Mail = (props) => (
  <svg
    data-name="Grupo 235"
    xmlns="http://www.w3.org/2000/svg"
    width={19.176}
    height={19.176}
    viewBox="0 0 19.176 19.176"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rect\xE1ngulo 1133"
          fill={props || "#5d2648"}
          d="M0 0h19.176v19.176H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 234">
      <g data-name="Grupo 233" clipPath="url(#a)" fill={props || "#5d2648"}>
        <path
          data-name="Trazado 267"
          d="M0 9.589a9.588 9.588 0 1 1 9.589 9.587A9.587 9.587 0 0 1 0 9.589m9.6-4H3.042c-.116 0-.144.026-.144.143q.006 3.854 0 7.708c0 .115.025.144.143.144q6.545-.005 13.09 0c.116 0 .144-.026.144-.143q-.006-3.854 0-7.708c0-.115-.025-.144-.143-.144q-3.266.006-6.532 0"
        />
        <path
          data-name="Trazado 268"
          d="M4.141 6.228h10.895l-.933.726-4 3.105a.729.729 0 0 1-1.037 0L4.247 6.313l-.111-.088"
        />
        <path
          data-name="Trazado 269"
          d="M15.022 12.948H4.144c.079-.063.138-.112.2-.159l3.492-2.717c.082-.064.132-.077.22 0 .226.189.463.366.7.546a1.338 1.338 0 0 0 1.674 0q.366-.282.729-.569c.05-.039.084-.055.145-.007q1.843 1.438 3.689 2.872a.409.409 0 0 1 .035.037"
        />
        <path
          data-name="Trazado 270"
          d="M15.639 6.566v6.033c-.051 0-.074-.036-.1-.058l-3.7-2.878c-.056-.044-.1-.066-.013-.136q1.886-1.459 3.767-2.925l.052-.036"
        />
        <path
          data-name="Trazado 271"
          d="M3.536 12.617V6.588c.045-.012.067.03.1.052q1.853 1.438 3.7 2.877c.055.043.121.065.018.144q-1.881 1.454-3.756 2.917l-.061.043"
        />
      </g>
    </g>
  </svg>
);
export default Mail;
