import React from "react";

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98.282}
    height={98.198}
    viewBox="0 0 98.282 98.198"
    {...props}
  >
    <path
      id="Trazado_229"
      data-name="Trazado 229"
      d="M49.13.031C64.841.031,80.552.05,96.263,0c1.515,0,2.021.373,2.018,1.952q-.063,47.112,0,94.223c0,1.529-.434,2-1.993,2-11.372-.062-22.745-.031-34.118-.043-.5,0-1.21.258-1.46-.214-.3-.56.309-1.051.613-1.534a39.1,39.1,0,0,0,5.765-18.215c.065-.854-.182-1.153-1.041-1.15-7.093.02-14.187.032-21.28-.009-1.167-.007-1.149.747-1.258,1.486a17.8,17.8,0,0,1-4.161,9.685c-3.342,3.7-7.682,5.278-12.525,5.481-10.228.429-17.66-4.986-20.532-14.867-2.666-9.172-2.632-18.258,1.2-27.134C13.339,38.08,29.855,37.59,38.1,43.832a13.9,13.9,0,0,1,5.17,8.509c.266,1.241.732,1.734,2.087,1.719q10.023-.11,20.048,0c1.424.014,1.714-.314,1.589-1.784-.99-11.6-7.121-19.952-16.8-25.688C37.3,18.943,23.58,17.972,9.442,22.84A35.885,35.885,0,0,0,1.586,26.7c-.375.244-.742.744-1.222.525-.507-.23-.329-.819-.329-1.27,0-8.145.04-16.29-.034-24.435C-.014-.006.711.014,1.821.015Q25.475.049,49.13.031"
      transform="translate(0 0.001)"
      fill="#fff"
    />
  </svg>
);
export default Logo;