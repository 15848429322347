import React, { useContext, useState, useEffect } from 'react';
import MainLayout from '../../Layout/MainLayout'
import EquipoContainer from './EquipoContainer';
import Modal from '../../components/NuestroEquipo/Modal';
import { LanguageContext } from '../../context/LanguageContext'
import esLang from '../../translations/equipo/es.json'
import enLang from '../../translations/equipo/en.json'
import esTeamLang from '../../translations/equipo/equipoEs.js'
import enTeamLang from '../../translations/equipo/equipoEn.js'
import bg from '../../assets/equipo/Header.jpg'

function NuestroEquipo(){
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dataModal, setDataModal] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleModal = (data) => {
        setIsModalOpen(!isModalOpen)
        setDataModal(data)
    }
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang
    const translationTeam = language === 'es' ? esTeamLang : enTeamLang
    
    return(
        <MainLayout modal={isModalOpen} noNavbar={isModalOpen}>
            {
                isModalOpen && <div className='absolute z-[9999] bg-[#000] bg-opacity-[.70] w-screen h-full flex justify-center items-center'><Modal closeModal={setIsModalOpen} items={dataModal} /></div>
            }
            <div className='w-screen'>
                <div className='w-full sm:max-h-[590px] h-screen relative'>
                    <img src={bg} className='object-cover w-full sm:max-h-[590px] h-screen'></img>
                    <div className='w-full h-full sm:p-[5%] pt-[160px] sm:pt-unset px-[20px] sm:px-unset pb-[20px] sm:pb-unset absolute top-0'>
                        <div className='w-full flex flex-col justify-center bg-[#000000] bg-opacity-[.70] h-full p-[5%]'>
                            <p style={{fontFamily: 'Merriweather Italic Bold'}} className='text-[30px] text-[#d1c5b2] pb-[40px] sm:text-left text-center'>{translation.title}</p>
                            <p style={{fontFamily: 'Poppins Light'}} className='text-[24px] text-[#fff] sm:text-left text-center'>{translation.subtitle}</p>
                        </div>
                    </div>
                </div>

                <EquipoContainer setIsModalOpen={handleModal} items={translation} team={translationTeam }  />
            </div>
        </MainLayout>
    )
}

export default NuestroEquipo;