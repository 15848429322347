import * as React from "react";
const LogoFooter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={113.576}
    viewBox="0 0 113.576 36.628"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rect\xE1ngulo_37"
          data-name="Rect\xE1ngulo 37"
          width={113.096}
          height={20.417}
          fill="#fff"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect
          id="Rect\xE1ngulo_43"
          data-name="Rect\xE1ngulo 43"
          width={43.197}
          height={21.585}
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Grupo_205"
      data-name="Grupo 205"
      transform="translate(-495 -463.789)"
    >
      <g id="Grupo_22" data-name="Grupo 22" transform="translate(495 480)">
        <g id="Grupo_21" data-name="Grupo 21" clipPath="url(#clip-path)">
          <path
            id="Trazado_34"
            data-name="Trazado 34"
            d="M10.215.006c3.267,0,6.533,0,9.8-.007.315,0,.42.078.42.406q-.013,9.8,0,19.59c0,.318-.09.417-.414.415-2.365-.013-4.729-.006-7.094-.009-.1,0-.252.054-.3-.044-.062-.116.064-.218.127-.319a8.13,8.13,0,0,0,1.2-3.787c.014-.177-.038-.24-.216-.239-1.475,0-2.95.007-4.424,0-.243,0-.239.155-.261.309a3.7,3.7,0,0,1-.865,2.014,3.552,3.552,0,0,1-2.6,1.14,4.01,4.01,0,0,1-4.269-3.091,8.117,8.117,0,0,1,.249-5.642A4.26,4.26,0,0,1,7.921,9.113,2.89,2.89,0,0,1,9,10.882c.055.258.152.361.434.357q2.084-.023,4.168,0c.3,0,.356-.065.33-.371a6.638,6.638,0,0,0-3.493-5.341,9.724,9.724,0,0,0-8.473-.779,7.461,7.461,0,0,0-1.633.8C.252,5.6.175,5.706.075,5.66S.007,5.49.007,5.4C.006,3.7.015,2.009,0,.316,0,0,.148,0,.378,0q4.918.007,9.836,0"
            transform="translate(0 0.001)"
            fill="#fff"
          />
          <path
            id="Trazado_35"
            data-name="Trazado 35"
            d="M552.445,61.674c.069-.76.069-.76.793-.76.875,0,.875,0,.875.866q0,2.447,0,4.895a2.587,2.587,0,0,1-2.639,2.815,3.985,3.985,0,0,1-2.332-.4,1.927,1.927,0,0,1-.989-1.327c-.04-.183,0-.272.211-.257a6.611,6.611,0,0,0,.73,0c.4-.017.723-.02.974.436s.782.475,1.276.405a1.164,1.164,0,0,0,1.008-.776,4.207,4.207,0,0,0,.108-1.534,2.17,2.17,0,0,1-1.446.891,2.463,2.463,0,0,1-2.966-2.016,3.93,3.93,0,0,1,.507-3.169,2.462,2.462,0,0,1,3.891-.066m.026,2.12a2.323,2.323,0,0,0-.152-.863,1.284,1.284,0,0,0-1.246-.889,1.2,1.2,0,0,0-1.2.714,2.5,2.5,0,0,0,0,2.179,1.291,1.291,0,0,0,2.129.326,1.892,1.892,0,0,0,.474-1.467"
            transform="translate(-467.747 -51.858)"
            fill="#fff"
          />
          <path
            id="Trazado_36"
            data-name="Trazado 36"
            d="M171.813,47.3a2.1,2.1,0,0,0-.328,0c-.4.064-.607-.062-.752-.48a1.957,1.957,0,0,0-1.974-1.023,2.113,2.113,0,0,0-1.9,1.7,3.761,3.761,0,0,0,.416,3.283,2.026,2.026,0,0,0,2.059.81,1.753,1.753,0,0,0,1.532-1.461c.09-.369.236-.46.581-.43a8.558,8.558,0,0,0,1.059,0c.214-.008.288.023.252.277a3.712,3.712,0,0,1-4.64,3.141,4.03,4.03,0,0,1-3.284-3.661,4.971,4.971,0,0,1,.982-3.841,3.753,3.753,0,0,1,2.386-1.351,4.079,4.079,0,0,1,3.226.68,3.362,3.362,0,0,1,1.275,2.106c.034.163,0,.253-.2.242-.231-.012-.463,0-.694,0"
            transform="translate(-140.678 -37.733)"
            fill="#fff"
          />
          <path
            id="Trazado_37"
            data-name="Trazado 37"
            d="M454.835,48.621a2.539,2.539,0,0,1,3.207-.57,2.783,2.783,0,0,1,1.273,1.993,4.212,4.212,0,0,1-.529,3.219,2.534,2.534,0,0,1-3.969.144c-.017.013-.048.026-.048.041-.038.674-.038.674-.736.674-.925,0-.925,0-.925-.927,0-2.449.005-4.9-.007-7.347,0-.289.075-.384.363-.361a5.951,5.951,0,0,0,.95,0c.319-.026.44.055.429.408-.029.888-.009,1.778-.009,2.726m2.862,2.384a2.547,2.547,0,0,0-.176-1.034,1.314,1.314,0,0,0-1.169-.955,1.184,1.184,0,0,0-1.207.594,2.683,2.683,0,0,0,.022,2.807,1.282,1.282,0,0,0,2.086.093,2.431,2.431,0,0,0,.444-1.5"
            transform="translate(-386.801 -38.827)"
            fill="#fff"
          />
          <path
            id="Trazado_38"
            data-name="Trazado 38"
            d="M644.168,53.377a2.576,2.576,0,0,1-4.3-.564,3.89,3.89,0,0,1,.365-4.193A2.478,2.478,0,0,1,644,48.556c.01.015.04.015.1.035,0-.92.013-1.828-.007-2.735-.007-.3.087-.376.369-.358.364.023.731.014,1.1,0,.191-.006.252.059.251.25q-.007,4.073,0,8.147c0,.2-.082.244-.26.239a7.8,7.8,0,0,0-.986,0c-.329.032-.445-.083-.393-.4a2.668,2.668,0,0,0,0-.362M641.173,50.8a2.785,2.785,0,0,0,.287,1.458A1.309,1.309,0,0,0,642.7,53a1.23,1.23,0,0,0,1.2-.789,3.2,3.2,0,0,0,.068-2.244,1.375,1.375,0,0,0-2.157-.673,1.881,1.881,0,0,0-.643,1.506"
            transform="translate(-545.86 -38.838)"
            fill="#fff"
          />
          <path
            id="Trazado_39"
            data-name="Trazado 39"
            d="M412.522,66.974c-.529,0-1.026.006-1.524,0-.207,0-.207-.017-.328-.546a3.034,3.034,0,0,1-1.361.651,2.807,2.807,0,0,1-2.137-.332,1.727,1.727,0,0,1-.691-1.7,1.541,1.541,0,0,1,.974-1.379,9.012,9.012,0,0,1,2.169-.431c.167-.029.333-.066.5-.111a.6.6,0,0,0,.494-.6.682.682,0,0,0-.454-.718,1.726,1.726,0,0,0-1.211,0c-.2.078-.407.193-.442.4-.078.47-.389.458-.741.434a4.26,4.26,0,0,0-.767,0c-.337.038-.353-.121-.293-.378a1.78,1.78,0,0,1,.979-1.291,4.476,4.476,0,0,1,3.809-.042,1.335,1.335,0,0,1,.822,1.273c.023.9.013,1.8.015,2.7a6.434,6.434,0,0,0,.19,2.055m-1.92-3.022a2.734,2.734,0,0,1-.972.259,3.292,3.292,0,0,0-.918.211.774.774,0,0,0-.53.716.722.722,0,0,0,.378.73,1.379,1.379,0,0,0,2.036-1.029,7.387,7.387,0,0,0,.005-.888"
            transform="translate(-346.993 -51.68)"
            fill="#fff"
          />
          <path
            id="Trazado_40"
            data-name="Trazado 40"
            d="M599.146,66.441a2.984,2.984,0,0,1-1.907.723c-1.946.076-2.585-1.27-2.169-2.57a1.669,1.669,0,0,1,1.263-1.045,15.421,15.421,0,0,1,1.8-.294c.167-.029.33-.077.494-.121a.592.592,0,0,0,.474-.612.654.654,0,0,0-.444-.685,1.745,1.745,0,0,0-1.213-.008.7.7,0,0,0-.507.531c-.039.31-.207.324-.448.314a8.172,8.172,0,0,0-1.023,0c-.327.028-.327-.131-.276-.367a1.862,1.862,0,0,1,1.165-1.4,4.7,4.7,0,0,1,3.318-.092,1.483,1.483,0,0,1,1.141,1.656c0,1.011,0,2.022,0,3.033a3.857,3.857,0,0,0,.159,1.3c.057.162.007.191-.136.19-.451,0-.9.005-1.353,0-.21,0-.209-.017-.335-.552m-.043-2.462a4.811,4.811,0,0,1-1.331.312,2.592,2.592,0,0,0-.593.16.789.789,0,0,0-.513.767.665.665,0,0,0,.427.694c.8.282,2.018.079,2.01-1.285,0-.2,0-.406,0-.649"
            transform="translate(-507.891 -51.701)"
            fill="#fff"
          />
          <path
            id="Trazado_41"
            data-name="Trazado 41"
            d="M257.968,64.39c-.646,0-1.292.013-1.938-.006-.257-.007-.3.084-.277.312a1.327,1.327,0,0,0,.92,1.192,1.659,1.659,0,0,0,1.812-.515.457.457,0,0,1,.444-.225,6.626,6.626,0,0,0,.84,0c.348-.03.319.119.215.355a2.686,2.686,0,0,1-2.188,1.711,3.365,3.365,0,0,1-2.568-.559,3.171,3.171,0,0,1-1.216-2.647,3.12,3.12,0,0,1,2.455-3.226,2.955,2.955,0,0,1,3.692,2.57c.034.264.038.532.066.8.021.194-.054.249-.246.247-.67-.011-1.341,0-2.011,0m-.862-1.086c.389,0,.778,0,1.166,0,.156,0,.215-.039.187-.21a1.285,1.285,0,0,0-1.278-1.117,1.379,1.379,0,0,0-1.432,1.093c-.034.183.008.239.19.235.389-.008.777,0,1.166,0"
            transform="translate(-216.842 -51.812)"
            fill="#fff"
          />
          <path
            id="Trazado_42"
            data-name="Trazado 42"
            d="M303.559,64.348c-.646,0-1.293.016-1.938-.007-.3-.011-.316.114-.276.344a1.325,1.325,0,0,0,1.024,1.186,1.577,1.577,0,0,0,1.7-.542.446.446,0,0,1,.441-.223c.316.015.634.007.951,0,.189,0,.218.052.15.24a2.747,2.747,0,0,1-2.506,1.853,3.07,3.07,0,0,1-2.907-1.24,3.436,3.436,0,0,1,1.2-5,2.968,2.968,0,0,1,4.311,2.127,4.947,4.947,0,0,1,.1.94c.01.25-.056.337-.32.329-.645-.019-1.292-.006-1.938-.006m.514-1.086c-.1-.821-.516-1.267-1.192-1.316a1.345,1.345,0,0,0-1.511,1.027c-.049.184-.067.3.188.293.826-.01,1.653,0,2.515,0"
            transform="translate(-255.761 -51.771)"
            fill="#fff"
          />
          <path
            id="Trazado_43"
            data-name="Trazado 43"
            d="M693.346,64.1a3.006,3.006,0,0,1-1.747,2.947,3.372,3.372,0,0,1-4.148-1,3.531,3.531,0,0,1-.45-2.835,3.224,3.224,0,0,1,6.345.89m-1.682-.041a3.483,3.483,0,0,0-.238-1.135,1.31,1.31,0,0,0-1.28-.846,1.283,1.283,0,0,0-1.289.776,3.069,3.069,0,0,0-.148,2,1.385,1.385,0,0,0,1.457,1.215,1.432,1.432,0,0,0,1.406-1.323c.042-.226.062-.457.092-.685"
            transform="translate(-586.377 -51.894)"
            fill="#fff"
          />
          <path
            id="Trazado_44"
            data-name="Trazado 44"
            d="M503.385,67.328a3.288,3.288,0,0,1-.018-6.574,3.287,3.287,0,0,1,.018,6.574M504.9,64a2.453,2.453,0,0,0-.294-1.3,1.3,1.3,0,0,0-1.25-.66,1.241,1.241,0,0,0-1.184.706,2.96,2.96,0,0,0-.078,2.436,1.316,1.316,0,0,0,1.325.845,1.343,1.343,0,0,0,1.29-.889A2.7,2.7,0,0,0,504.9,64"
            transform="translate(-426.98 -51.864)"
            fill="#fff"
          />
          <path
            id="Trazado_45"
            data-name="Trazado 45"
            d="M738.183,62.723a2.708,2.708,0,0,0-.329,0,.441.441,0,0,1-.564-.333c-.149-.43-.777-.63-1.395-.5-.281.057-.557.137-.605.483-.05.364.2.523.478.639a9.934,9.934,0,0,0,1.447.371,4.55,4.55,0,0,1,1.04.334,1.772,1.772,0,0,1,.146,3.043,3.626,3.626,0,0,1-2.265.541,3.3,3.3,0,0,1-1.9-.555,2.026,2.026,0,0,1-.814-1.414c-.028-.186.049-.233.219-.23q.585.011,1.17,0c.147,0,.2.049.246.192a1.411,1.411,0,0,0,2.075.655.6.6,0,0,0-.055-1.1,5.967,5.967,0,0,0-1.464-.434,6.735,6.735,0,0,1-1.179-.373,1.646,1.646,0,0,1-.247-2.76,3.269,3.269,0,0,1,2-.554,3.208,3.208,0,0,1,2.107.567,1.928,1.928,0,0,1,.677,1.167c.047.218-.039.282-.246.267-.182-.014-.365,0-.548,0"
            transform="translate(-626.101 -51.839)"
            fill="#fff"
          />
          <path
            id="Trazado_46"
            data-name="Trazado 46"
            d="M349.292,49.859c0,1.3-.007,2.608.006,3.912,0,.27-.043.4-.352.371a10.233,10.233,0,0,0-1.133,0c-.19.006-.252-.056-.251-.249q.008-4.058,0-8.116c0-.222.084-.262.279-.256.378.012.757.02,1.133,0,.256-.015.328.063.326.322-.013,1.34-.006,2.681-.006,4.021"
            transform="translate(-296.702 -38.854)"
            fill="#fff"
          />
          <path
            id="Trazado_47"
            data-name="Trazado 47"
            d="M228.662,61.783a2.677,2.677,0,0,1,1.775-1.129c.543-.071.608-.025.608.513V61.2a2.287,2.287,0,0,1-.03.936c-.155.3-.553.05-.847.1a1.652,1.652,0,0,0-1.411,1.826c-.017.876-.014,1.753,0,2.628,0,.264-.052.369-.338.349a10.771,10.771,0,0,0-1.132,0c-.2.007-.275-.046-.274-.262q.012-2.848,0-5.7c0-.2.041-.287.26-.278.377.016.755.012,1.132,0,.187-.006.265.052.255.247-.013.242,0,.485,0,.728"
            transform="translate(-193.798 -51.756)"
            fill="#fff"
          />
          <path
            id="Trazado_48"
            data-name="Trazado 48"
            d="M372.926,53.158c.581-1.561,1.14-3.065,1.7-4.569.514-1.379,1.036-2.756,1.538-4.14a.4.4,0,0,1,.474-.323c.325.023.654.006,1.03.006l-.852,2.281c-.8,2.143-1.6,4.284-2.392,6.431a.4.4,0,0,1-.467.32c-.327-.025-.657-.006-1.031-.006"
            transform="translate(-318.357 -37.668)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Grupo_48"
        data-name="Grupo 48"
        transform="translate(565.379 463.789)"
      >
        <g id="Grupo_47" data-name="Grupo 47" transform="translate(0 0)">
          <g id="Grupo_44" data-name="Grupo 44">
            <g id="Grupo_43" data-name="Grupo 43" clipPath="url(#clip-path-2)">
              <path
                id="Trazado_51"
                data-name="Trazado 51"
                d="M10.991,53.99a.609.609,0,0,1,.237-.545q2.184-2.127,4.359-4.264a1.57,1.57,0,0,1,.225-.19.317.317,0,0,1,.423.047.323.323,0,0,1,.047.44,1.121,1.121,0,0,1-.152.174q-2.177,2.134-4.353,4.27a.731.731,0,0,1-.577.278l-.209-.209"
                transform="translate(-8.022 -35.731)"
                fill="#fff"
              />
              <path
                id="Trazado_52"
                data-name="Trazado 52"
                d="M54.494,11.821a.609.609,0,0,1-.237.545q-2.183,2.126-4.357,4.263a1.518,1.518,0,0,1-.224.19.346.346,0,0,1-.476-.48,1.177,1.177,0,0,1,.151-.175Q51.558,14,53.763,11.837a.553.553,0,0,1,.552-.195l.179.179"
                transform="translate(-35.881 -8.489)"
                fill="#fff"
              />
              <path
                id="Trazado_53"
                data-name="Trazado 53"
                d="M53.961,54.489a.608.608,0,0,1-.544-.235q-2.121-2.179-4.253-4.348a1.639,1.639,0,0,1-.178-.207.341.341,0,0,1,.042-.457.335.335,0,0,1,.443-.027,1.282,1.282,0,0,1,.157.139q2.165,2.209,4.331,4.418a.466.466,0,0,1,.151.568l-.149.149"
                transform="translate(-35.721 -35.887)"
                fill="#fff"
              />
              <path
                id="Trazado_54"
                data-name="Trazado 54"
                d="M14.5,13.506q1.086,1.086,2.172,2.174c.252.253.285.473.107.636s-.376.116-.617-.131l-4.3-4.391a.763.763,0,0,1-.181-.23.352.352,0,0,1,.523-.434,1.353,1.353,0,0,1,.169.158l2.173,2.173Z"
                transform="translate(-8.503 -8.077)"
                fill="#fff"
              />
              <path
                id="Trazado_55"
                data-name="Trazado 55"
                d="M7.1,38.488a.353.353,0,0,1-.169.585.968.968,0,0,1-.25.019q-3.1-.028-6.194-.06a1.017,1.017,0,0,1-.209-.019A.336.336,0,0,1,0,38.648a.338.338,0,0,1,.3-.326,1.024,1.024,0,0,1,.189-.008l6.236.059a.5.5,0,0,1,.371.114"
                transform="translate(0.001 -27.978)"
                fill="#fff"
              />
              <path
                id="Trazado_56"
                data-name="Trazado 56"
                d="M38.407,53.438a.352.352,0,0,1,.346-.081.323.323,0,0,1,.243.261,1.32,1.32,0,0,1,.015.251q-.034,3.086-.071,6.173a.955.955,0,0,1-.023.229.326.326,0,0,1-.354.265.332.332,0,0,1-.332-.294,1.007,1.007,0,0,1-.008-.21q.035-3.107.071-6.215a.518.518,0,0,1,.114-.379"
                transform="translate(-27.91 -38.952)"
                fill="#fff"
              />
              <path
                id="Trazado_57"
                data-name="Trazado 57"
                d="M39.544.119a.37.37,0,0,1,.1.3Q39.608,3.6,39.57,6.784c0,.268-.149.425-.371.419s-.348-.163-.345-.418q.033-3.182.071-6.363A.384.384,0,0,1,39.153.03a.331.331,0,0,1,.39.089"
                transform="translate(-28.372 0)"
                fill="#fff"
              />
              <path
                id="Trazado_58"
                data-name="Trazado 58"
                d="M53.417,38.949a.515.515,0,0,1,.4-.114l5.963.059c.127,0,.253,0,.379.007a.355.355,0,0,1,.359.362.364.364,0,0,1-.371.352c-.028,0-.056,0-.084,0l-6.3-.062c-.257,0-.392-.095-.434-.281a.366.366,0,0,1,.087-.322"
                transform="translate(-38.937 -28.358)"
                fill="#fff"
              />
              <path
                id="Trazado_59"
                data-name="Trazado 59"
                d="M48.37,12.7a.378.378,0,0,1,.07.405c-.251.594-.5,1.188-.762,1.778a.35.35,0,0,1-.469.2.346.346,0,0,1-.182-.476c.249-.6.5-1.2.771-1.8a.353.353,0,0,1,.573-.11"
                transform="translate(-34.315 -9.199)"
                fill="#fff"
              />
              <path
                id="Trazado_60"
                data-name="Trazado 60"
                d="M12.851,27.75a.376.376,0,0,1,.412-.064c.573.241,1.145.484,1.714.733a.358.358,0,1,1-.287.653c-.567-.236-1.134-.474-1.7-.722a.363.363,0,0,1-.143-.6"
                transform="translate(-9.306 -20.192)"
                fill="#fff"
              />
              <path
                id="Trazado_61"
                data-name="Trazado 61"
                d="M28.2,60.506a.364.364,0,0,1-.411.062.322.322,0,0,1-.187-.381,1.986,1.986,0,0,1,.133-.352q.3-.715.608-1.428a.368.368,0,0,1,.4-.267.323.323,0,0,1,.291.424c-.236.642-.511,1.27-.8,1.889a.55.55,0,0,1-.033.054"
                transform="translate(-20.149 -42.454)"
                fill="#fff"
              />
              <path
                id="Trazado_62"
                data-name="Trazado 62"
                d="M60.442,47.829a.366.366,0,0,1,.054.458.326.326,0,0,1-.42.141c-.216-.079-.426-.175-.638-.265q-.521-.221-1.04-.444c-.269-.116-.376-.312-.285-.519s.293-.258.559-.145q.79.335,1.579.675c.07.03.137.07.192.1"
                transform="translate(-42.413 -34.32)"
                fill="#fff"
              />
              <path
                id="Trazado_63"
                data-name="Trazado 63"
                d="M14.869,46.671a.346.346,0,0,1,0,.519.583.583,0,0,1-.162.1q-.827.337-1.656.67a.394.394,0,0,1-.365,0,.347.347,0,0,1-.187-.308.33.33,0,0,1,.2-.322q.9-.376,1.813-.73a.312.312,0,0,1,.352.071"
                transform="translate(-9.129 -34.011)"
                fill="#fff"
              />
              <path
                id="Trazado_64"
                data-name="Trazado 64"
                d="M58.4,28.971a1.034,1.034,0,0,1,.142-.081q.847-.344,1.7-.683a.357.357,0,0,1,.425.075.341.341,0,0,1-.111.562c-.6.255-1.206.493-1.814.728a.322.322,0,0,1-.415-.16.34.34,0,0,1,.076-.442"
                transform="translate(-42.554 -20.571)"
                fill="#fff"
              />
              <path
                id="Trazado_65"
                data-name="Trazado 65"
                d="M47.149,58.406a1.4,1.4,0,0,1,.085.159q.335.827.666,1.655a.362.362,0,0,1-.1.472.345.345,0,0,1-.54-.152c-.246-.587-.481-1.179-.711-1.773a.335.335,0,0,1,.156-.436.346.346,0,0,1,.446.076"
                transform="translate(-33.97 -42.558)"
                fill="#fff"
              />
              <path
                id="Trazado_66"
                data-name="Trazado 66"
                d="M28.817,12.661a1.415,1.415,0,0,1,.086.161c.222.552.441,1.1.663,1.656a.364.364,0,0,1-.071.444.343.343,0,0,1-.562-.114c-.255-.6-.491-1.205-.724-1.813a.323.323,0,0,1,.163-.414.344.344,0,0,1,.444.08"
                transform="translate(-20.582 -9.151)"
                fill="#fff"
              />
              <path
                id="Trazado_67"
                data-name="Trazado 67"
                d="M23.869,74.628a.377.377,0,0,1,0,.5.371.371,0,0,1-.513,0,.357.357,0,0,1,.515-.495"
                transform="translate(-16.984 -54.419)"
                fill="#fff"
              />
              <path
                id="Trazado_68"
                data-name="Trazado 68"
                d="M74.159,54.185a.372.372,0,0,1,.513-.005.365.365,0,0,1,0,.5.357.357,0,0,1-.493.02.364.364,0,0,1-.022-.511"
                transform="translate(-54.084 -39.491)"
                fill="#fff"
              />
              <path
                id="Trazado_69"
                data-name="Trazado 69"
                d="M3.519,23.86a.354.354,0,1,1-.511.49.355.355,0,0,1,.019-.508.351.351,0,0,1,.492.018"
                transform="translate(-2.125 -17.343)"
                fill="#fff"
              />
              <path
                id="Trazado_70"
                data-name="Trazado 70"
                d="M24.713,3.122a.379.379,0,0,1,0,.5.365.365,0,0,1-.511-.006.354.354,0,0,1,.018-.507.367.367,0,0,1,.5.014"
                transform="translate(-17.599 -2.204)"
                fill="#fff"
              />
              <path
                id="Trazado_71"
                data-name="Trazado 71"
                d="M2.659,53.481a.368.368,0,0,1,.5-.02.357.357,0,0,1-.476.531.367.367,0,0,1-.02-.511"
                transform="translate(-1.873 -38.976)"
                fill="#fff"
              />
              <path
                id="Trazado_72"
                data-name="Trazado 72"
                d="M54.323,3.413a.347.347,0,0,1,.008.49.359.359,0,0,1-.509.012.372.372,0,0,1,.007-.513.361.361,0,0,1,.494.01"
                transform="translate(-39.231 -2.417)"
                fill="#fff"
              />
              <path
                id="Trazado_73"
                data-name="Trazado 73"
                d="M75.02,24.561a.359.359,0,0,1,.006.494.367.367,0,0,1-.511,0,.36.36,0,0,1,0-.494.348.348,0,0,1,.506,0"
                transform="translate(-54.341 -17.858)"
                fill="#fff"
              />
              <path
                id="Trazado_74"
                data-name="Trazado 74"
                d="M53.474,74.914a.353.353,0,1,1-.491.507.353.353,0,1,1,.491-.507"
                transform="translate(-38.612 -54.633)"
                fill="#fff"
              />
            </g>
          </g>
          <text
            id="_50"
            data-name={50}
            transform="translate(8.175 10.787)"
            fill="#fff"
            fontSize={3}
            fontFamily="Helvetica-Bold, Helvetica"
            fontWeight={700}
          >
            <tspan x={0} y={0}>
              {"50"}
            </tspan>
          </text>
          <g id="Grupo_46" data-name="Grupo 46">
            <g id="Grupo_45" data-name="Grupo 45" clipPath="url(#clip-path-2)">
              <text
                id="A\xF1os"
                transform="translate(23.949 12.265)"
                fill="#fff"
                fontSize={6}
                fontFamily="Helvetica-Bold, Helvetica"
                fontWeight={700}
              >
                <tspan x={0} y={0}>
                  {"A\xF1os"}
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default LogoFooter;
