import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'
import esLang from '../../translations/tyc/es.js'
import enLang from '../../translations/tyc/en.js'
import bg from '../../assets/practica/bg_Header.jpg'

import Layout from '../../Layout/MainLayout';

function TYC(){
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <Layout showLanguageWhite>
            <div style={{backgroundImage: `url(${bg})`, backgroundSize: 'cover'}} className='flex justify-center items-center h-[300px]'>
                <h1 style={{fontFamily: 'Merriweather Italic Bold'}} className='text-[40px] text-center pb-[30px] text-[#D1C5B2]'>{translation.title}</h1>
            </div>
            <div className='w-screen h-full flex justify-center'>
                <div className='max-w-[1200px] text-[16px] sm:px-[190px] px-[90px] py-[100px]'>
                    {translation.p.map(p => <p dangerouslySetInnerHTML={{ __html: p }} className='pb-[15px] text-[#7F7F7F]'/>)}
                    <p className='text-[#7F7F7F]'>{translation.actualization}</p>
                </div>
            </div>
        </Layout>
    )
}

export default TYC;