import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'
import MainLayout from '../../Layout/MainLayout'
import esLang from '../../translations/recursos/es.js'
import enLang from '../../translations/recursos/en.js'
import HeroContainer from './HeroContainer'
import HighLightContainer from './HighlightContainer';
import ArticlesContainer from './ArticlesContainer';

function Recursos(params) {
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <MainLayout>
            <HeroContainer items={translation}/>
            {/* <HighLightContainer items={translation["highlight"]} /> */}
            <div className='pb-[120px]'>
                <ArticlesContainer items={translation["articles"]} />
                <ArticlesContainer items={translation["notes"]} />
            </div>
        </MainLayout>
    )
    
}
export default Recursos