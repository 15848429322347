import React from 'react'
import Notes from '../../assets/recursos/Notes';

function Card({card}){
    return(
        <div className='flex flex-col sm:w-[362px] h-[320px] w-[332px] shadow-md shadow-gray-500'>
            <div className='flex flex-col p-[20px] h-full'>
                <div className='flex mb-auto'>
                    <div className='w-[24px] h-[24px] mr-[15px]'>
                        <Notes />
                    </div>
                    <p style={{fontFamily: 'Poppins SemiBold'}} className='text-[#04141C] text-[20px] pb-[20px]' > {card.title} </p>
                </div>
                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7D878F] text-[16px]'>{card.description}</p>
            </div>
            <div style={{fontFamily: 'Poppins Light'}} className='flex justify-between mt-auto p-[20px] text-[#7D878F]'>
                <a download={card.link} href={card.link} className='cursor-pointer'>{card.more}</a>
                <p>{card.date}</p>
            </div>
        </div>
    )
}

export default Card;