import React from 'react'

function Hamburger(){
    return(
        <div className='w-[57px] h-[53px] sm:bg-[#131313] bg-primary sm:bg-opacity-[.69] border-solid border-[#fff] border-4 rounded-md sm:border-none flex flex-col justify-evenly px-[10px] cursor-pointer'>
          <div className='w-full h-[3px] bg-[#fff]'/>
          <div className='w-full h-[3px] bg-[#fff]'/>
          <div className='w-full h-[3px] bg-[#fff]'/>
        </div>
    )
}

export default Hamburger;