const es = {
    "title": "Más de Cincuenta años de experiencia a tu favor",
    "subtitle": "Brindamos asesoría especializada en las Áreas de Práctica vinculadas con los negocios.",
    "p": "La experiencia de <span style='font-family: Poppins Medium'> Creel/abogados </span> se refleja en nuestras diversas Áreas de Práctica. Descubre más sobre nuestra experiencia en cada especialidad.",
    "items": [
        {
            "name": "Derecho Corporativo y Mercantil",
            "image": require('../../assets/despacho/experiencia/ND-DCorporativo_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Derecho Financiero",
            "image": require('../../assets/despacho/experiencia/ND-DFinanciero_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Derecho Fiscal",
            "image": require('../../assets/despacho/experiencia/ND-DFiscal_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Derecho Laboral",
            "image": require('../../assets/despacho/experiencia/ND-DLaboral_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Derecho Inmobiliario",
            "image": require('../../assets/despacho/experiencia/ND-DInmobiliario_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Competencia Económica",
            "image": require('../../assets/despacho/experiencia/ND-Competencia_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Recursos Naturales",
            "image": require('../../assets/despacho/experiencia/ND-Recursos_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Litigio y Solución de Controversias",
            "image": require('../../assets/despacho/experiencia/ND-Litigio_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "ASG",
            "image": require('../../assets/despacho/experiencia/ND-ASG_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Cumplimiento Regulatorio",
            "image": require('../../assets/despacho/experiencia/ND-Cumplimiento_escritorio.jpg'),
            "link": "/practice-areas"
        }
    ]
}

export default es;