import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import Search from '../../assets/common/Search'

function Searchbar({ query, setQuery }){
    const { language } = useContext(LanguageContext);

    return <div>
        <div className="flex items-center w-[340px]">
        <input
            type="email"
            className="w-[268px] h-[49px] px-4 py-2 rounded-l border border-solid border-[#A79879] bg-[#fff] text-[#A79879] text-[11px] focus:outline-none focus:border-black"
            placeholder={language === 'es' ? 'Buscar' : 'Search'}
            onChange={e => setQuery(e)}
        />
        <button className="w-[72px] h-[49px] sm:w-auto px-4 py-2 rounded-r bg-[#fff] text-black font-medium border border-solid border-[#A79879]">
            <div className='w-[16px]'>
                <Search className='w-[16px] h-[16px]' fill="#A79879"/>
            </div>
        </button>
        </div>
</div>
}

export default Searchbar