const en = {
    title: 'Privacy Notice',
    p: [
        'Creel Abogados, S.C. located at Paseo de los Tamarindos 400 B, Piso 29, Col. Bosques de las Lomas, 05120 Mexico City, is responsible for the collection, use and protection of your personal data.',
        'Creel Abogados, S.C. located at Paseo de los Tamarindos 400 B, Piso 29, Col. Bosques de las Lomas, 05120 Mexico City, is responsible for the collection, use and protection of your personal data. Your personal information that is obtained will be used to provide our professional services of legal representation that you have engaged, to carry out the necessary procedures for the services of legal representation and to inform on the situation and status of the matters handled by our representation. For the aforementioned purposes, we require, among others, the following personal data: name, address, telephone number(s) and e-mail. The negative for the use of your personal data for such purposes may not be a reason for us to provide services that are requested and contracted with us.',
        `You have the right to access, rectify and cancel your personal data, as well as to oppose the treatment given to it or revoking your consent, through the procedures that have been implemented. To learn about the procedures, requirements and terms to access, rectify and cancel your personal data, you may contact: Antonio Peña, tel: +52 (55) 1167-3055 and e-mail: <a style="font-family: Poppins Bold" href='mailto:antonio.pena@creelabogados.com' class='text-[15px] text-[#415C87]'>antonio.pena@creelabogados.com </a>`,
        'Furthermore, please be informed that your personal data might be transferred and treated inside and outside the country, by third parties other than this Firm. For that matter, your information might be shared with people with whom we have a professional relationship to complement the legal services that have been contracted and to contact experts related with the matters in which you are represented. If you do not express your negative to transfer your personal data, it shall be understood that you have granted your consent therefor.',
        `We reserve the right to change or update this privacy notice at any time. These amendments will be available through our website: <a style="font-family: Poppins Bold" target="_blank" href='https://www.creelabogados.com' class='text-[15px] text-[#415C87] underline'>www.creelabogados.com</a>`
    ],
    actualization: 'Date of last updated: September, 2023'

}
export default en