import React from 'react'
import bg from '../../assets/contact/header.png'

function HeaderContainer({items}){
    return (
    <div style={{backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}  className='w-screen flex justify-center items-center bg-[#E2E2E2] h-[590px] px-[40px] py-[90px]'>
        <div className='w-[98%] max-w-[1460px] flex flex-col justify-center items-center bg-[#000000] bg-opacity-[.69] h-[95%] lg:p-[120px] p-[20px]'>
            <div>
                <h1 style={{fontFamily:'Merriweather Italic'}} className='text-[#D1C5B2] lg:text-[30px] text-[28px] text-center'> {items.title} </h1>
            </div>
        </div>
    </div>
)}

export default HeaderContainer