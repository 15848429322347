const es = {
    title: 'Estar en contacto contigo y conocerte es importante para nosotros',
    subtitle: 'En Creel/abogados te escuchamos',
    p: 'Creel/abogados',
    address:[
        {
            items:["Paseo de los Tamarindos 400 B, Piso 29", "Bosques de las Lomas", "05120 Ciudad de México"],
            phone: "+52 (55) 1167-3000"
        },
        {
            items: ["Magnia Corporate Center", "Calle 15 No. 503, por 18 y 22, PH 06", "Fracc. Altabrisa", "97130 Mérida, Yucatán"],
            phone: "+52 (99) 9518-2745"
        }
],
    form: {
        text: "*Campos obligatorios",
        terms: "Acepto los términos del Aviso de Privacidad",
        button: "ENVIAR",
        name: "Nombre",
        lastname: "Apellido",
        email: "*Correo electrónico",
        phone: "*Teléfono",
        country: "País",
        company: "Empresa",
        comments: "*Comentarios",
    }
}
export default es