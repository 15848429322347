import React from 'react'
import FormContainer from './FormContainer'
import Accordion from '../../components/NuestroDespacho/Accordion'
import Tabs from '../../components/common/Tabs'

function UneteContainer({ items }){
    const capacitacion = <div className='flex flex-col'>
            <div className='flex'>
                <img src={items.content.capacitacion.image} className='max-h-[500px]'></img>
                <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] px-[20px]'>
                    <p className='pb-[20px] leading-loose'>{items.content.capacitacion.p1}</p>
                    <p className='pb-[20px] leading-loose'>{items.content.capacitacion.p2}</p>
                    <p className='pb-[20px] leading-loose'>{items.content.capacitacion.p3}</p>
                </div>
            </div> 
        </div>

    const internacional = <div className='flex'>
            <img src={items.content.internacional.image}></img>
        <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] px-[20px]'>
            <p className='pb-[20px] leading-loose'>{items.content.internacional.p1}</p>
        </div>
</div>

    const profesional = <div className='flex flex-col'>
    <div className='flex'>
        <img src={items.content.profesional.image}></img>
        <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] px-[20px]'>
            <p className='pb-[20px] leading-loose'>{items.content.profesional.p1}</p>
            <p className='pb-[20px] leading-loose'>{items.content.profesional.p2}</p>
            <p className='pb-[20px] leading-loose'>{items.content.profesional.p3}</p>
        </div>
    </div> 
</div>

const accordionObject = [
    {
        title: "Capacitación, Mentoría y Rotación de Prácticas",
        p: [items.content.capacitacion.p1, items.content.capacitacion.p2, items.content.capacitacion.p3],
        image: ""
    },
    {
        title: "Experiencia Internacional", 
        p:[items.content.internacional.p1],
        image: ""
    },
    {
        title: "Experiencia Profesional",
        p: [items.content.profesional.p1, items.content.profesional.p2, items.content.profesional.p3],
        image: ""
    }
]

    return (
        <div id="join" className='bg-opacity-[.70] w-screen flex flex-col sm:px-[120px] sm:py-[20px] p-0'>
            <div className='flex flex-col py-[25px] px-[20px] sm:px-[40px]'>
                <p style={{fontFamily: 'Merriweather Bold'}} className="sm:text-[40px] text-[30px] text-center sm:text-start text-primary py-[20px]">{items.title}</p>
                <p style={{fontFamily: 'Merriweather Italic'}} className="sm:text-[30px] text-[20px] text-center sm:text-start text-[#D1C5B2]">{items.subtitle}</p>
                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] mt-[40px] max-w-[1066px]'>{items.p} </p>
            </div>
            <div className='w-full'>
                <FormContainer items={items.form}/>
            </div>
            <div className='sm:px-[100px] pt-[20px] px-[10px] hidden sm:block'>
                <Tabs v2 tabs={items.tabs} contents={[capacitacion, internacional, profesional]} />
            </div>
            <div className='sm:hidden block'>
                {
                    accordionObject.map(acc => <Accordion title={acc.title} content={acc.p} image={acc.image} />)
                }
            </div>
        </div>
    )
}
export default UneteContainer;