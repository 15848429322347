const es = {
   "hero.slider":[
    {
        "title": "Certeza legal para tu negocio",
        "button": "Conoce a nuestro Equipo",
        "button_hover": "Conocer más",
        "link": "/our-team"
    },
    {
        "title": "Prever es resolver el futuro",
        "button": "Descubre nuestras Áreas de Práctica",
        "button_hover": "Conocer más",
        "link": "practice-areas"
    }
],
"negocios": {
   "text": {
    "title": "Expertos en Derecho de los Negocios",
    "subtitle": "Hacemos nuestros los retos de tu organización y los atendemos con ejecución efectiva y un enfoque integral a resultados.",
    "p1": "Proyectamos y accionamos soluciones de alto impacto para resguardar y potenciar los intereses estructurales y estratégicos de nuestros clientes.",
    "p2": "La cercanía, atención personalizada y compromiso a la excelencia son nuestros principales pilares; estando siempre a la vanguardia de la nueva realidad del siglo XXI."
   },
   "showMore": [
    {
        "text": "Propósito Profesional",
        "more": "Leer más",
        "description": "Alineamos los objetivos estratégicos de nuestros clientes con los del Despacho. El éxito del Despacho se define por los logros de nuestros clientes.",
        "image": require('../../assets/home/showMore/H-PropositoP_escritorio.jpg'),
        "image_movil": require('../../assets/home/showMore/H-PropositoP_movil.jpg'),
        "link": "our-firm"
    },
    {
        "text": "Equipos de Trabajo",
        "more": "Leer más",
        "description": "Dentro de un entorno colaborativo, conformamos Equipos de Trabajo sólidamente capacitados y experimentados para atender las necesidades específicas de cada cliente, asunto o proyecto y brindar certeza legal y confianza con nuestros servicios.",
        "image": require('../../assets/home/showMore/H-EquiposT_escritorio.jpg'),
        "image_movil": require('../../assets/home/showMore/H-EquiposT_movil.jpg'),
        "link": "/our-team"
    },
    {
        "text": "Áreas de Práctica",
        "more": "Leer más",
        "description": "En cada una de nuestras Áreas de Práctica ofrecemos el conocimiento profundo y la experiencia probada que generan consistencia en nuestros servicios y contundencia en los resultados.",
        "image": require('../../assets/home/showMore/H-AreasP_escritorio.jpg'),
        "image_movil": require('../../assets/home/showMore/H-AreasP_movil.jpg'),
        "link": "/practice-areas"
    }
]
},
"socios": {
    "text": {
        "title": "Estamos siempre a tu lado",
        "subtitle": "El Equipo de Creel/abogados aporta el conocimiento y soporte legal que requieres para concentrarte en el camino al éxito de tu empresa.",
        "p1": "Nuestros Socios se involucran directamente y en todo momento en los asuntos y proyectos que nos encomiendan, con la ejecución puntual y efectiva de los objetivos mutuamente establecidos.",
        "p2": "Nos sentimos orgullosos de nuestro talentoso Equipo de abogados, con sólida experiencia y conocimiento en las áreas jurídicas que integran nuestra práctica."
    },
    "slider": [
        {
            "name": "Gustavo Struck Creel",
            "title": "Derecho Corporativo y  Mercantil",
            "button": "Contacto",
            "image": require('../../assets/home/slider/abogadoSlider1.png'),
            "image_2": require('../../assets/home/slider/abogadoSlider1_2.png'),
            "link": "/partners/3"
        },
        {
            "name": "Sandra López Ch.",
            "title": "Derecho Corporativo y Mercantil",
            "button": "Contacto",
            "image": require('../../assets/home/slider/abogadoSlider2_A.png'),
            "image_2": require('../../assets/home/slider/abogadoSlider2.png'),
            "link": "/associates/6"

        }
    ]
},
"meet": {
    "text":{
        "title": "Nuestro Equipo",
        "button": "CONOCER TODO EL EQUIPO"
    },
    "tabs": [
        {
            "key": "equipo", 
            "value": "Equipo"
        },
    ]
},
"form": {
    "text": {
        "title": "Estar en contacto contigo y conocer tus necesidades de asesoría jurídica es importante para nosotros.",
        "subtitle": "En Creel/abogados te escuchamos.",
        "description": "Formulario de contacto",
        "fields": "Campos obligatorios",
        "termns": "Acepto los términos del Aviso de Privacidad",
        "button": "ENVIAR FORMULARIO"
    },
    "fields": {
        "name": "Nombre",
        "lastname": "Apellido",
        "email": "Correo electrónico",
        "country": "País",
        "comments": "Comentarios",
        "company": "Empresa",
        "phone": "Teléfono"
    }
}
}
export default es;