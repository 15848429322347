import React from 'react'
import PropTypes from 'prop-types'
import SociosSlider from '../../components/Home/SociosSlider'

function SociosContainer({ items }){
    return (
        <div className='flex justify-center w-screen sm:h-screen h-full bg-secondary lg:min-h-screen xl:min-h-screen lg:h-full lg:pb-[45px]'>
            <div className='xl:max-w-[1366px] xl:w-full flex flex-col sm:flex-row'>
                <div className='pt-[20px] pl-[10px] sm:pt-[100px] md:pt-[45px] sm:pl-[45px] xl:pt-[100px] sm:w-3/6'>
                    <div style={{fontFamily: "Merriweather Bold"}} >
                        <p className='text-[#fff] sm:text-[40px] text-[30px] sm:mb-[56px] text-center sm:text-left px-[60px] py-[30px] sm:p-0'>{items.text.title}</p>
                        <p style={{fontFamily: "Merriweather Italic Bold"}} className='text-[#D1C5B2] sm:text-[30px] text-[18px] px-[32px] sm:px-[0]'>{items.text.subtitle}</p>
                    </div>
                    <div style={{fontFamily: 'Poppins Light'}} className='text-[#fff] leading-[1.9] mt-[64px] px-[32px] sm:px-[0]'>
                    <p>{items.text.p1}</p>
                    <p className='mt-[30px]'>{items.text.p2}</p>
                    </div>
                </div>
                <div className='sm:w-3/6 xl:pt-[100px] md:pt-[45px]'>
                    <SociosSlider items={items.slider} />
                </div>
            </div>
        </div>
    )
}

SociosContainer.propTypes = {
    items: PropTypes.object
}

export default SociosContainer;