import React, { useContext, useEffect } from 'react';
import MainLayout from '../../Layout/MainLayout'
import { LanguageContext } from '../../context/LanguageContext'
import { useLocation } from "wouter";
import esLang from '../../translations/biography/es.js'
import enLang from '../../translations/biography/en.js'
import Location from '../../assets/equipo/Location';
import Mail from '../../assets/equipo/Mail';
import Phone from '../../assets/equipo/Phone';

function Biography(){
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang
    const location = useLocation ()
    const item = translation[location[0]]

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <MainLayout showLanguage>
            {item ? <div className='flex flex-col sm:flex-row'>
                <div className='sm:w-1/3 w-full'>
                    <div className='bg-secondary flex justify-center p-[20px] pb-0 sm:h-auto h-[460px]'>
                        <img src={item?.image}></img>
                    </div>
                    <div className='flex justify-center items-center pt-[40px] pb-[100px]'>
                        <a href={item.vcard} download style={{fontFamily: 'Merriweather Italic'}} className='bg-[#6F2A43] text-[#D1C5B2] w-[203px] h-[50px] flex justify-center items-center cursor-pointer'>{language === 'es' ? 'Descargar vCard' : 'Download vCard'}</a>
                    </div>
                </div>
                <div className='sm:w-2/3 w-full flex flex-col pl-[50px] sm:pr-[120px] pr-[20px] pb-[90px]'>
                    <div className='flex flex-col sm:flex-row pb-[30px] pt-[60px]'>
                        <div className='flex flex-col sm:w-3/6 w-full'>
                            <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-primary text-[40px]'>{item.name}</h1>
                            <h2 style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] text-[30px] mb-[20px]'>{item.subtitle}</h2>
                            {
                                item.description?.map(description => 
                                <p style={{fontFamily: 'Poppins'}} className='text-primary text-[20px] my-[2px] sm:block hidden'>{description}</p>
                                )}
                        </div>
                        <div className='flex flex-col justify-end  sm:w-3/6 w-full sm:px-[50px] p-0'>
                            <div className='flex'>
                                <p style={{fontFamily: 'Poppins'}} className='text-primary text-[20px]'> {item.contact.title}</p>
                            </div>
                            <div className='flex'>
                                <Location className='mr-[10px]' />
                                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F]'> {item.contact.location}</p>
                            </div>
                            <div className='flex'>
                                <Phone fill='#5d2648' className='mr-[10px]' />
                                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F]'> {item.contact.phone}</p>
                            </div>
                            <div className='flex'>
                                <div className='mr-[10px] w-[19px]'>
                                    <Mail fill='#5d2648' width={19} />
                                </div>
                                <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F]'> {item.contact.email}</p>
                            </div>
                        </div>
                        <div className='sm:hidden block pt-[20px]'>
                        {
                                item.description?.map(description => 
                                <p style={{fontFamily: 'Poppins'}} className='text-primary text-[20px] my-[2px]'>{description}</p>
                                )}
                        </div>
                    </div>
                    <div>
                        {item.p?.map(paragraph => 
                        <p dangerouslySetInnerHTML={{ __html: paragraph }} style={{fontFamily: "Poppins Light"}} className='text-[#7F7F7F] text-[16px] mb-[30px]' />
                        )}
                    </div>
                    <div>
                        <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-primary text-[40px]'>{item.qualifications.title}</h1>
                        <div>
                            {
                                item.qualifications.items.map(quali =>
                                     <div> 
                                        <p style={{fontFamily: 'Poppins'}} className='text-[#AA9775] text-[20px] my-[20px]'>{quali.title}</p>
                                        {
                                            quali.p?.map(p => <p style={{fontFamily: "Poppins Light"}} className='text-[#7F7F7F] my-[10px]'>{p}</p>)
                                        }
                                     </div>
                            )}
                        </div>
                        <div className='flex sm:flex-row flex-col'>
                           { item.affiliations && <div className='mr-[150px]'>
                                <p style={{fontFamily: 'Poppins'}} className='text-[#AA9775] text-[20px] my-[20px]'>{item.affiliations.title}</p>
                                {item.affiliations.p.map(p =>  <p style={{fontFamily: "Poppins Light"}} className='text-[#7F7F7F] my-[10px]'>{p}</p>)}
                            </div>
                            }
                            <div>
                                <p style={{fontFamily: 'Poppins'}} className='text-[#AA9775] text-[20px] my-[20px]'>{item.languages.title}</p>
                                {item.languages.p.map(p =>  <p style={{fontFamily: "Poppins Light"}} className='text-[#7F7F7F] my-[10px]'>{p}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </MainLayout>
    )
}
export default Biography;