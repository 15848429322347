import React, { useState } from 'react'
import Accordion from '../../components/common/Accordion'
import image_1 from '../../assets/despacho/cultura/ND-Cultura_escritorio.jpg'
import image_2 from '../../assets/despacho/cultura/ND-Diversidad_escritorio.jpg'


function AccordionContainer({ items }) {
    const [currentIndex, setCurrentIndex] = useState(-1)

    const toggleAccordion = (idx) => {
       if(currentIndex === idx){
        setCurrentIndex(-1)
       }
       else {
        setCurrentIndex(idx)
       }

      };
    return(
        <div id='culture' className='w-screen flex flex-col'>
            <div className='flex flex-col sm:flex-row'>
                <div className='sm:w-3/6 w-screen'>
                    <div className='flex flex-col bg-[#D1C5B2] p-[40px] items-center'>
                        <div className='max-w-[514px]'>
                            <p style={{fontFamily: 'Merriweather Bold'}} className='text-primary text-[40px]'> { items.title } </p>
                            <p style={{fontFamily: 'Poppins Light'}} className='text-[#131313]'> { items.p } </p>
                            <img className='pt-[20px]' src={image_1}></img>
                        </div>
                    </div>
                </div>
                <div className='sm:w-3/6 w-screen'>
                    <div className='sm:p-[40px] p-0 pt-[80px]'>
                    {
                        items.accordion.map((item, index) =>  <Accordion index={index} currentIndex={currentIndex} toggleAccordion={toggleAccordion} title={item.title} content={ item.p.map(p => <p dangerouslySetInnerHTML={{ __html: p }} style={{fontFamily: 'Poppins Light'}} className="text-[#666666] px-0 mb-[20px]"/>) }/>)
                    }
                    </div>
                </div>
            </div>
            <div className='flex sm:flex-row flex-col'>
                <div className='sm:w-3/6 w-screen'>
                    <div className='flex justify-start py-[40px] pt-[90px]'>
                        <div className='w-full'>
                            <img className='object-cover w-full' src={image_2}></img>
                        </div>
                    </div>
                </div>
                <div className='sm:w-3/6 w-screen'>
                    <div className='p-[40px] sm:pt-[90px] pt-0 h-full'>
                        <div className='flex flex-col h-full justify-center border-t-[#D1C5B2] sm:border-l-[#D1C5B2] border-solid sm:border-l-8 border-t-4 sm:border-t-0 sm:pl-[30px] pl-0 pt-[40px] sm:pt-0'>
                            <p style={{fontFamily: 'Merriweather Italic Bold'}} className='text-[#D1C5B2] sm:text-[30px] text-[20px] text-center sm:text-start'>{items.title2}</p>
                            <p style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] sm:text-[20px] text-[16px] pt-[30px] text-center sm:text-start'>{items.p2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccordionContainer;