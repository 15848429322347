import React, { useRef, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader"
import './style.css'

function MapContainer(){
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"]
    });
    
    const mapRef = useRef(null);
    const mapStyles =  [
                { elementType: "geometry", stylers: [{ color: "#131313" }] },
                { elementType: "labels.text.stroke", stylers: [{ visibility: 'off' }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "poi",
                    stylers: [{ visibility: "off" }],
                  },
                {
                  featureType: "poi",
                  elementType: "labels.text.fill",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "geometry",
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text.fill",
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ color: "#212121" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "road",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#9ca5b3" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry",
                  stylers: [{ color: "#212121" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#515c6d" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#9ca5b3" }],
                },
                {
                  featureType: "transit",
                  elementType: "geometry",
                  stylers: [{ color: "#515c6d" }],
                },
                {
                  featureType: "transit.station",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [{ color: "#17263c" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#515c6d" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.stroke",
                  stylers: [{ color: "#515c6d" }],
                },
              ]
    let map = null;
    let infoWindow = null;
    let geocoder = null;

    const mapOptions = {
      center: { lat: 19.3879959, lng: -99.2537502 },
      zoom: 16,
      disableDefaultUI: true,
      styles: mapStyles
    };
    useEffect(() => {
        loader.importLibrary('maps')
        .then(({Map}) => {
          new Map(document.getElementById("map"), mapOptions);
        geocoder = new window.google.maps.Geocoder();
        map = new window.google.maps.Map(mapRef.current, mapOptions);
        infoWindow = new window.google.maps.InfoWindow();
        const address = 'Creel abogados' 
        geocoder.geocode({address}, (results, status) => {
          if(status == 'OK'){
            const location = results[0].geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            const position = { lat, lng }
            infoWindow.setContent(`<div class="flex justify-center items-center bg-secondary h-[149px] w-[381px] p-[20px] text-[#fff] font-Poppins-Light">
            <div class="flex justify-center items-center mr-[15px]">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30.815" height="42.666" viewBox="0 0 30.815 42.666">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectángulo_1226" data-name="Rectángulo 1226" width="30.815" height="42.666" fill="#d1c5b2"/>
              </clipPath>
            </defs>
            <g id="Grupo_343" data-name="Grupo 343" transform="translate(-664 -831.766)">
              <g id="Grupo_342" data-name="Grupo 342" transform="translate(664 831.766)">
                <g id="Grupo_341" data-name="Grupo 341" clip-path="url(#clip-path)">
                  <path id="Trazado_3420" data-name="Trazado 3420" d="M30.7,13.68A15.157,15.157,0,0,0,24.865,3.254,15.049,15.049,0,0,0,18.5.309C17.974.2,17.434.153,16.9.079,16.836.07,16.76.091,16.736,0H13.907A3.291,3.291,0,0,1,12.8.226a15.146,15.146,0,0,0-10.917,7.8A14.783,14.783,0,0,0,.039,16.46a16.551,16.551,0,0,0,2.271,7.165q4.954,8.6,9.928,17.2a3.632,3.632,0,0,0,6.284.069q5.192-8.9,10.281-17.852a15,15,0,0,0,1.9-9.357m-15.28,6.261A5.6,5.6,0,1,1,21,14.3a5.6,5.6,0,0,1-5.58,5.645" transform="translate(0.001 0.001)" fill="#d1c5b2"/>
                </g>
              </g>
            </g>
          </svg>
            </div>
            <div class="flex flex-col justify-center items center text-[12px]">
                <p>CREEL/ABOGADOS</p>
                <p>PASEO DE LOS TAMARINDOS 400 B, PISO 29</p>
                <p>BOSQUES DE LAS LOMAS</p>
                <p>05120 CIUDAD DE MÉXICO</p>
            </div>
                </div>`);
            infoWindow.setPosition(position)
            infoWindow.open(map);
          } else {
              console.log('Error al cargar geocode')
          }
    })
        } )
    }, []);
  
    return ( <div id="map" ref={mapRef} style={{ height: '400px', width: '100%' }} />)
}
export default MapContainer;