const en = {
    "slider": [
        {
            "title": "As firm believers in the foundations of our services, we maintain our responsibility towards:",
            "list":["<span class='mr-[5px]'>•</span> Our clients", "<span class='mr-[5px]'>•</span> Society", "<span class='mr-[5px]'>•</span> Diversity", "<span class='mr-[5px]'>•</span> Inclusion", "<span class='mr-[5px]'>•</span> Sustainability", "<span class='mr-[5px]'>•</span> Labor opportunities"],
            "p": "Discover more about our Firm, its values, focus and commitment."
        },
        {
            "title": "Creel/abogados:",
            "subtitle": "together in the same direction",
            "list": ["<div class='flex'><span class='mr-[5px]'>•</span><p style='font-family: Poppins Medium' class='mr-[5px]'> Commitment </p> to our clients", 
            "<div class='flex'><span class='mr-[5px]'>•</span><p style='font-family: Poppins Medium' class='mr-[5px]'> Insight </p> of the business environment",
             "<div class='flex'><span class='mr-[5px]'>•</span><p style='font-family: Poppins Medium' class='mr-[5px]'> Resolve, consistency and trust in our services</p>"],
            "p": "Discover more about our Firm, its values, focus and commitment."
        },
        {
            "title": "Our services are built on our commitment to our clients and the conviction to build lasting relationships with them.",
            "p": "Discover more about our Firm, its values, focus and commitment."
        }
    ],
    "expertos": {
        "title": "Experts in Business Law",
        "subtitle": "We thrive on taking on complex challenges and matters,",
        "subtitle2": "anticipating problems and providing timely effective solutions.",
        "text": "Excellence in serving responsible businesses;",
        "text2": "always thinking and acting in the right direction."
    },
    "somos": {
        "title": "We are a Firm deeply committed to its clients",
        "tabs": [
            {
                "key": "valores",
                "value": "Values"
            },
            {
                "key": "enfoque",
                "value": "Focus"
            },
            {
                "key": "compromiso",
                "value": "Commitment"
            }
        ],
        "content": {
            "valores": {
                "items":[
                    "<p class='pb-[20px]'> <span style='font-family: Poppins Medium'> Trust, respect, integrity, excellence, tradition and innovation </span> are the values that unite us at <span style='font-family: Poppins Medium'> Creel/abogados </span>; which we promote and uphold. Our values define who we are, what we stand for and how to perform our work. They bind us together and allow us to achieve the high standards that our clients expect. </p></div>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> Trust and respect form the basis of our relationships; we encourage collaboration within our Firm and approach others with clarity, openness and transparency. </p></div>", 
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> Providing responsible services with an honest and ethical sense of work is as crucial to us, as delivering them with flawless preparation and execution, always striving to excellence. </p></div>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> We value our roots and preserve our Firm’s tradition and culture. Each day, we work to maintain the legacy of our founders and strengthen their spirit and principles of service. </p></div>",
                    "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> While recognizing the relevance of our history, we are also aware of the dynamic nature of businesses and constantly seek to develop and improve our performance and remain innovative to meet our clients’ future challenges. </p></div>"
                ],
                "image": require('../../assets/despacho/somos/ND-Valores_escritorio.jpg')
            },
            "enfoque": {
                "p1": "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p>We focus our services on understanding, anticipating and meeting the structural and strategic needs of our clients. </p></div>",
                "p2": "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p>We provide specialized legal advice across various Practice Areas related to business: from the organization and maintenance of their operations to their expansion and consolidation projects.</p></div>",
                "image": require('../../assets/despacho/somos/enfoque_escritorio.jpg')
            },
            "compromiso": {
                "p1": "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> We are a firm committed to our clients. We devote our efforts to providing the highest quality advice and zealously safeguard our clients' best interests, in order to build stable and lasting relationships with them. </p></div>",
                "p2": "<div class='flex'> <span class='pb-[20px] ml-[20px] mr-[10px]'> •</span> <p> The professional, responsible and timely service we provide is the core of our Firm.</p></div>",
                "image": require('../../assets/despacho/somos/enfoque_escritorio.jpg')
            },
            "text": "Our values, focus and commitment constitute our guiding principles and have enabled us to consolidate a leading Firm with regional and international reach."
        }
    },
    "sustentabilidad": {
        "title": "Sustainability",
        "subtitle": "We recognize the importance of the future as much as the present in which we develop our professional practice.",
        "p1": "We actively participate in various initiatives and actions that aim to create a balance between economic development, care for the environment and social wellness.",
        "p2": "We understand that the path to sustainability requires consistent effort and faces significant challenges, but we are dedicated to implementing new strategies that support this cause."
    },
    "cultura": {
        "title": "Creel/abogados Culture",
        "p": "We operate within a culture that privileges service excellence, teamwork and the professional development of its members.",
        "accordion": [
            {
                "title": "Knowledge, Training and Results",
                "p": ["We strive to understand each of our clients’ industry, business environment, activities and strategy, in order to provide tailored legal advice designed to address each matter and transaction from our client’s perspective; adapting to their particular needs and aligning our objectives with their expectations. We recognize the dynamic and ever-changing nature of the economy and markets, and for this reason we continuously update and train our Team members to remain at the forefront and provide creative and innovative solutions in our Practice Areas."]
            },
            {
                "title": "Experience and Specialty",
                "p": ["Corporate Law is the cornerstone of our professional practice, and serves as the focal point for all our specialized Practice Areas: Corporate and Commercial, Mergers and Acquisitions, Finance, Tax, Real Estate, Labor and Employment, Antitrust and Competition, Litigation and Dispute Resolution, Natural Resources, ESG and Compliance.", "Our legal services are built on our experience and specialized knowledge within the Practice Areas we cover, which allow us to provide tailored legal advice with decisive results for our domestic and international clients."] 
            },
            {
                "title": "Network",
                "p": ["With the proven experience and expertise of our Teams, complemented by our network of relationships with leading law firms in other regions of the country and in the major capitals of the world, we offer comprehensive coverage of the legal services required by our clients in the development of their businesses both in Mexico and abroad.", "Our network is enhanced by a selected group of independent advisors covering a variety of specialties, including Economics, Politics and Finance, as well as multiple industrial sectors, such as real estate, construction, pharmaceuticals, aeronautics, sports, entertainment, technology and media, among others."]
            },
            {
                "title": "Teamwork",
                "p": ["Our Team is formed by talented lawyers graduated from top universities in the country. Our Partners and Senior Associates have postgraduate studies at renowned universities in Mexico, the United States and Europe, and have been trained at leading international law firms. All Team members receive continuous legal education, which allows them to remain up-to-date and excel in their relevant Practice Areas.", "By assembling carefully selected working teams, we provide specialized attention to each matter or transaction entrusted to us. A Partner leads each Working Team serving as the principal contact with our clients, coordinating and supervising the Team, and is ensuring the achievement of the specific objectives set by our clients."]
            },
            {
                "title": "Professional Development",
                "p": ["We believe that institutions transcend the individuals who form them, and are convinced that the professional development of our associates is critical to the quality of the services we provide, as well as to strengthen the continuity and projection of our Firm.", "We are committed to the constant and sustained professional development of our Team members, fostering a work environment that nurtures our culture and work spirit, which allows our Team members actively participate in increasingly demanding projects under the diligent supervision of our partners.", "The motivation and training of our Team drive their professional growth and contribute to the orderly evolution of our Firm."]
            }
        ],
        "title2": "At Creel/abogados we strongly believe in equal opportunities for all members of our Team.",
        "p2": "To that end, we have established a diversity and inclusion program within our organization"
    },
    "proBono": {
        "title": "Pro Bono",
        "subtitle": "Responsibility and integrity in serving society and the community.",
        "p1": "We acknowledge the importance of altruistic work and actively promote the participation of all the members of our Firm in matters that positively impact our society by providing of pro bono legal advice to private welfare institutions.",
        "p2": "We offer pro bono advice to several non-profit institutions and foundations focused on several fields and initiatives, including private associations involved in high impact sectors, such as Education and Health, as well as vulnerable groups and communities across Mexico. Following are some of the non-profit institutions and foundations we assist:"
    },
    "internacional": {
        "title": "International Reach",
        "subtitle": "A unique global network.",
        "p1": "Over the years, we have identified leading law firms in various regions and jurisdictions around the world that share our focus on service excellence and our values.",
        "p2": "Through collaboration in transactions and projects we have established a network of exceptional allies, for the comprehensive benefit of our clients."
    },
    "banner":{
        "title": "Creel/abogados legacy was created more than half a century ago and is testimony of three generations of distinguished corporate lawyers.",
        "subtitle": "We are proud of our history and value the tradition and strong culture that define our Firm.",
        "button": "KNOW OUR HISTORY"
    },
    "unete": {
        "title": "Join the Creel/abogados Team and contribute your experience to our Firm, so that we continue growing together",
        "subtitle": "Leave your contact information and we will reach to you shortly.",
        "p": "We are looking for law students and young lawyers who excel academically, are interested in being part of our Team, identify with the Firm’s practice, culture and spirit, are motivated by challenges and are committed to the comprehensive development of their profession within a work environment that exposes them to the most complex and demanding projects and transactions in the market.",
        "tabs": [
            {
                "key": "capacitacion",
                "value": "Training, Mentoring and Internship Rotation"
            },
            {
                "key": "internacional",
                "value": "International Experience"
            },
            {
                "key": "profesional",
                "value": "More than just a workplace"
            }
        ],
        "content": {
            "capacitacion": {
                "p1": "We are dedicated to the continuous training of our legal trainees and associates, through daily interaction with Partners and more senior Associates, gradual and organized exposure to projects and transactions, and their engagement in courses, seminars and roundtables on current issues and legal developments led by partners and recognized experts in the subjects discussed.",
                "p2": "All legal trainee and lawyers have an assigned mentor responsible for their daily work, support and professional development, in alignment with our work principles, quality, dedication and commitment.",
                "p3": "During their final years of law school and prior to obtaining their law degrees, legal trainees are invited to experience and participate in various Practice Areas of our Firm, allowing them to interact with different specialty Teams, develop a wider knowledge of Law and define their calling and taste for a particular professional practice.",
                "image": require('../../assets/despacho/unete/ND-Unete-Capacitacion_escritorio.jpg')
            },
            "internacional": {
                "p1": "Convinced of the value of academic and practical experience abroad, we encourage our lawyers to participate in specialty courses and postgraduate programs at renowned Universities in the United States of America and Europe, as well as in training programs in leading law firms with which we maintain collaborative relationships.",
                "image": require('../../assets/despacho/unete/ND-Unete-exp_escritorio_2.jpg')
            },
            "profesional": {
                "p1": "For us, our Firm is more than just a workplace but we foster an environment where we can establish friendships and share common interests in our community.",
                "p2": "We strive to maintain a cordial and respectful environment that instills a sense of security and confidence in belonging to a professional team that values ​​not only dedication and excellence at work, but also personal development and a firm commitment to actively contribute to projects that positively impact our society.",
                "p3": "Human Resources +52 (55) 1167-3106  rh@creelabogados.com",
                "image": require('../../assets/despacho/unete/ND-Unete-trabajo_escritorio_3.jpg')
            }
        },
        "form": {
            "title": "Contact",
            "description": "Mandatory fields",
            "termns": "I accept the Privacy Notice",
            "button": "Send request",
            "fields": {
                "name": "Name",
                "lastname": "Last name",
                "email": "Email",
                "phone": "Telephone",
                "company": "University/Company",
                "country": "Country",
                "comments": "Comments"
            }
        }
    }
}

export default en;