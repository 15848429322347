import React from 'react'
import Button from '../../components/common/Button'

function HighLightContainer({items}){
    return(
        <div className='w-screen bg-secondary px-[90px] py-[90px] flex justify-center'>
            <div className='flex w-full max-w-[1460px]'>
                <div className='w-3/6 min-h-[439px]'>
                    <div className='flex flex-col min-h-[439px] justify-between'>
                        <h3 style={{fontFamily: 'Merriweather bold'}} className='text-[#fff] text-[40px] max-w-[400px]'> {items.title} </h3>
                        <div className='flex flex-col text-[#fff] mt-[60px]'>
                            <p style={{fontFamily: 'Poppins Medium'}}>{items.description.title}</p>
                            <p style={{fontFamily: 'Poppins Light'}}>{items.description.name}</p>
                            <p style={{fontFamily: 'Poppins Light'}} className='mb-[30px]'>{items.description.extra}</p>
                            <Button style={{fontFamily: 'Poppins'}} backgroundColor='bg-[#fff] text-secondary'>{items.description.button}</Button>
                        </div>
                    </div>
                </div>
                <div className='w-3/6'>
                    <div style={{backgroundImage: `url(${items.image})`, backgroundRepeat: 'no-repeat'}} className='bg-[#232B4C] w-full max-w-[637px] rounded-[13px] min-h-[439px]'>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HighLightContainer