import React from 'react'
import despacho from '../../assets/despacho/bg_Header.jpg'
import despacho_movil from '../../assets/despacho/bg_Header_movil.jpg'
import Button from '../../components/common/Button';
import useSlider from '../../hooks/slider'

function SliderContainer({items}){
    const slider = useSlider(items)

    return (
        <div id='our-firm' className='w-screen sm:h-[594px] h-screen flex justify-center items-center'>
            <div className='absolute w-full sm:h-[594px]'>
                <img className='object-cover w-full h-full hidden sm:block' src={despacho} ></img>
                <img className='h-full sm:hidden block' src={despacho_movil} ></img>
            </div>
            <div className='transition-all flex justify-evenly items-center bg-[#000] bg-opacity-[.60] sm:w-[1280px] sm:h-[437px] w-screen h-screen px-[10px] sm:px-[40px] py-[15px] relative z-10 flex-col md:flex-row'>
                <div className='pr-[30px] sm:block hidden'>
                    <Button click={slider.handlePrevious} hover="hover:bg-[#D1C5B2]" backgroundColor="bg-primary" width="w-[50px] h-[50px]" border="rounded-[8px] border-solid border-2 border-[#fff]">
                        {`<`}
                    </Button> 
                </div>
                <div className='flex flex-col items-center justify-center w-full h-screen lg:h-auto'>
                    <div className='flex sm:flex-row flex-col items-center w-full justify-center h-full'>
                        <div style={{fontFamily: 'Merriweather Italic' }} className={`text-[#fff] lg:text-[30px] text-[24px] text-center sm:text-start sm:pl-[50px] px-[30px] sm:w-auto ${items[slider.currentSlide].list ? 'sm:w-[50%] w-full max-w-[500px]' : 'w-full'}`}>
                            <p className={`mb-[30px] sm:mb-0 ${items[slider.currentSlide].list ? '' : 'text-center'}`}>{items[slider.currentSlide].title}</p>
                            {
                                items[slider.currentSlide].subtitle && <p className='mb-[30px] sm:mb-0'>{items[slider.currentSlide].subtitle}</p>
                            }
                        </div>
                        {items[slider.currentSlide].list && 
                        <ul style={{fontFamily: 'Poppins'}} className='text-[#fff] text-[18px] sm:text-[20px] flex flex-col justify-center px-[5px] sm:h-[180px] min-w-[320px] max-w-[450px]'>
                            {items[slider.currentSlide].list.map(list =>
                                <li dangerouslySetInnerHTML={{ __html: list }} className='leading-loose sm:leading-normal' key={list}/>
                            )}
                        </ul>
                        }
                    </div>
                    <div className='text-[#fff] text-[22px] md:absolute bottom-[45px] flex flex-col justify-center items-center mt-auto md:mt-0'>
                        <p style={{fontFamily: 'Poppins'}} className='text-center py-[20px] md:py-0'>{items[slider.currentSlide].p}</p>
                    <div className='md:hidden flex mt-[20px]'>
                        <div className='pr-[30px]'>
                            <Button click={slider.handlePrevious} hover="hover:bg-[#D1C5B2]" backgroundColor="bg-primary" width="w-[50px] h-[50px]" border="rounded-[8px] border-solid border-2 border-[#fff]">
                                {`<`}
                            </Button> 
                        </div>
                        <div className='pl-[30px]'>
                            <Button click={slider.handleNext} hover="hover:bg-[#D1C5B2]" backgroundColor="bg-primary" width="w-[50px] h-[50px]" border="rounded-[8px] border-solid border-2 border-[#fff]">
                                {`>`}
                            </Button> 
                        </div>
                    </div>
                    </div>
                </div>
                <div className='pl-[30px] sm:block hidden'>
                    <Button click={slider.handleNext} hover="hover:bg-[#D1C5B2]" backgroundColor="bg-primary" width="w-[50px] h-[50px]" border="rounded-[8px] border-solid border-2 border-[#fff]">
                        {`>`}
                    </Button> 
                </div>
            </div>
        </div>
    )
}
export default SliderContainer;