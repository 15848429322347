import React from 'react'
import PaginatedList from '../../components/Recursos/PaginatedList';
function ArticlesContainer({items}){
    return (
        <div className='w-screen flex flex-col bg-[#fff] items-center'>
            <div className='w-full max-w-[1460px] 2xl:px-0 lg:p-[90px] p-[20px] pb-0'>
                {/* <div className='w-full flex justify-between items-baseline'>
                    <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-[#666666] text-[40px]'>{items.title}</h1>
                    <p style={{fontFamily: 'Poppins'}} className={`text-[#666666] text-[20px] ${items.items.length > 4 ? 'block' : 'hidden'}`}>{items.more}</p>
                </div> */}
                    <PaginatedList articles={items?.items} itemsPerPage={3} content={items} />
            </div>
        </div>
    )
}

export default ArticlesContainer;