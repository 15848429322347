import axios from 'axios';

export const postNewsLetter = async (email) => {
    try {
      const response = await axios.post('https://apicreel.digitalcoaster.mx/api/registro-newsletter', {
       email: email,
      });
      return response.data
    } catch (error) {
      console.error(error);
    }
  };