import React, { useContext, useEffect} from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import MainLayout from '../../Layout/MainLayout'
import SliderContainer from './SliderContainer';
import ExpertosContainer from './ExpertosContainer';
import SomosContainer from './SomosContainer';
import Sustentabilidad from './Sustentabilidad';
import ExperienciaContainer from './ExperienciaContainer';
import AccordionContainer from './AccordionContainer';
import ProBonoContainer from './ProBonoContainer';
import InternacionalContainer from './InternacionalContainer';
import BannerContainer from './BannerContainer';
import UneteContainer from './UneteContainer';
import letrasCreel from '../../assets/home/letrasCreel.jpg'
import esLang from '../../translations/despacho/es'
import enLang from '../../translations/despacho/en'
import esLangExperiencia from '../../translations/despacho/showMorees'
import enLangExperiencia from '../../translations/despacho/showMoreen'

function NuestroDespacho() {
    const {language, changeLanguage} = useContext(LanguageContext)
    const translation = language === 'es' ? esLang : enLang
    const transLationEx = language == 'es' ? esLangExperiencia : enLangExperiencia

    useEffect(() => {
        const { hash } = window.location
        const decodedHash = decodeURI(hash).replace('#', '')
        const element = document.getElementById(decodedHash)
        if(hash) {
        element.scrollIntoView({behavior: "smooth"})
        }
        else {
          window.scrollTo(0, 0);
        }
      }, []);

    return(
        <MainLayout>
            <div className='w-screen'>
                <SliderContainer items={translation["slider"]}/>
                <ExpertosContainer items={translation["expertos"]} />
                <SomosContainer items={translation["somos"]}/>
                <ExperienciaContainer items={transLationEx} />
                <AccordionContainer items={translation["cultura"]} />
                <div className='w-full bg-repeat-x h-[85px] py-[66px]' style={{backgroundImage: `url(${letrasCreel})`}}></div>
                <Sustentabilidad items={translation["sustentabilidad"]} />
                <ProBonoContainer items={translation["proBono"]} />
                <InternacionalContainer items={translation["internacional"]}/>
                <BannerContainer items={translation["banner"]} />
                <UneteContainer items={translation["unete"]} />
            </div>
        </MainLayout>
    )
}

export default NuestroDespacho;