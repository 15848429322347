const esTeamLang = {
    "socios": [
        {
            "name": "Carlos Creel C.",
            "link": "/partners/1",
            "image": require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg')
        },
        {
            "name": "Javier Portas L.",
            "link": "/partners/2",
            "image": require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg')
        },
        {
            "name": "Gustavo Struck Creel",
            "link": "/partners/3",
            "image": require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg')
        },
        {
            "name": "Alonso Razú A.",
            "link": "/partners/4",
            "image": require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg')
        },
        {
            "name": "César Morales G.",
            "link": "/partners/5",
            "image": require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg')
        },
        {
            "name": "Diego Valdés A.",
            "link": "/partners/6",
            "image": require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg')
        },
        {
            "name": "Pablo Arellano Q.",
            "link": "/partners/7",
            "image": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg')
        },
        {
            "name": "Ernesto Saldate A.",
            "link": "/partners/8",
            "image": require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg')
        },
        {
            "name": "Sofía Gómez B.",
            "link": "/partners/9",
            "image": require('../../assets/equipo/Socios/Home/SofiaGomez_Esc.png')
        },
        {
            "name": "Home",
            "link": "/"
        }
    ],
    "consejeros": [
        {
            "name": "Luis J. Creel C.",
            "link": "/advisers/1",
            "image": require('../../assets/equipo/Consejeros/Home/LuisJCreel_Esc.jpg')
        },
        {
            "name": "Jorge Torres B.",
            "link": "/advisers/2",
            "image": require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg')
        },
        {
            "name": "Alejandro Creel Y.",
            "link": "/advisers/3",
            "image": require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg')
        },
        // {
        //     "name": "Claudia Lelo de Larrea M.",
        //     "link": "/advisers/5"
        // },
        // {
        //     "name": "Patricia López Padilla B.",
        //     "link": "/advisers/6"
        // },
        {
            "name": "Home",
            "link": "/"
        }
    ],
    "asociados": [
        {
            "name": "Paulina Aparicio L.",
            "link": "/associates/1",
            "image": require('../../assets/equipo/Asociados/Home/PaulinaAparicio_Esc.jpg')
            
        },
        {
            "name": "Sebastián de Palma C.",
            "link": "/associates/13",
            "image": require('../../assets/equipo/Asociados/Home/SebastianDePalma_Esc.jpg')
        },
        {
            "name": "Rodrigo Estrada C.",
            "link": "/associates/12",
            "image": require('../../assets/equipo/Asociados/Home/RodrigoEstrada1.jpg')
        },
        {
            "name": "Tsen Fang-Lin",
            "link": "/associates/4",
            "image": require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg')
        },
        {
            "name": "Karla Grajales M.",
            "link": "/associates/5",
            "image": require('../../assets/equipo/Asociados/Home/KarlaGrajales_Esc.jpg')

        },
        {
            "name": "Sandra López Ch.",
            "link": "/associates/6",
            "image": require('../../assets/equipo/Asociados/Home/SandraLopez_Esc.jpg')

        },{
            "name": "Carolina Lopez de Llergo P.",
            "link": "/associates/7",
            "image": require('../../assets/equipo/Asociados/Home/CarolinaLopez_Esc.jpg')

        },
        {
            "name": "María Fernanda Melgar G.",
            "link": "/associates/8",
            "image": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg')
        },
        {
            "name": "Moisés Prochovnik A.",
            "link": "/associates/9",
            "image": require('../../assets/equipo/Asociados/Home/Moises_Esc.jpg')
        },
        {
            "name": "Jonathan Segura M.",
            "link": "/associates/10",
            "image": require('../../assets/equipo/Asociados/Home/JonathanSegura_Esc.jpg')
        },
        {
            "name": "Pablo Torres A.",
            "link": "/associates/11",
            "image": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg')
        }     
    ],
    "pasantes": [
        {
            "name": "Diego Casas C.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 129",
            "email": "diego.casas@creelabogados.com",
            "contact": "Contact"
        },
        {
            "name": "Estefanía Cendejas S.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 122",
            "email": "estefania.cendejas@creelabogados.com",
            "contact": "Contact"
        },
        {
            "name": "Diego Corvera H.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 130",
            "email": "diego.corvera@creelabogados.com",
            "contact": "Contact"
        },
        // {
        //     "name": "Jimena Cuéllar L.",
        //     "description": "More information",
        //     "jobTitle": "Legal Trainee",
        //     "phone": "+52 (55) 1167-3000 ext. 155",
        //     "email": "jimena.cuellar@creelabogados.com",
        //     "contact": "Contact"
        // },

        // {
        //     "name": "Andrea García M.",
        //     "description": "More information",
        //     "jobTitle": "Legal Trainee",
        //     "phone": "+52 (55) 1167-3000 ext. 121",
        //     "email": "andrea.garcia@creelabogados.com",
        //     "contact": "Contact"
        // },
        // {
        //     "name": "Mariana Gómez V.",
        //     "description": "More information",
        //     "jobTitle": "Legal Trainee",
        //     "phone": "+52 (55) 1167-3000 ext. 125",
        //     "email": "mariana.gomez@creelabogados.com",
        //     "contact": "Contact"
        // },
        {
            "name": "Brenda Hernández G.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 121",
            "email": "brenda.hernandez@creelabogados.com",
            "contact": "Contact"
        },
        {
            "name": "Regina Rea S.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 112",
            "email": "regina.rea@creelabogados.com",
            "contact": "Contact"
        },
        {
            "name": "Arturo Retana S.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 126",
            "email": "arturo.retana@creelabogados.com",
            "contact": "Contact"
        },
        {
            "name": "Juan Carlos Román A.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 116",
            "email": "juan.roman@creelabogados.com",
            "contact": "Contact"
        },
        {
            "name": "Sofía Tamer S.",
            "description": "More information",
            "jobTitle": "Legal Trainee",
            "phone": "+52 (55) 1167-3000 ext. 123",
            "email": "sofia.tamer@creelabogados.com",
            "contact": "Contact"
        },
    ]
}

export default esTeamLang