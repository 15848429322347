import React, { useContext, useEffect } from 'react';
import MainLayout from '../../Layout/MainLayout';
import { LanguageContext } from '../../context/LanguageContext'
import VideoContainer from './VideoContainer';
import NegociosContainer from './NegociosContainer';
import SociosContainer from './SociosContainer';
import MeetTeamContainer from './MeetTeamContainer';
import FormContainer from './FormContainer'
import LogoCreel from '../../assets/home/logo_blco'
import LogoMobile from '../../assets/sidebar/Logo'
import letrasCreel from '../../assets/home/letrasCreel.jpg'
import HeroSlider from '../../components/Home/HeroSlider';
import esLang from '../../translations/Home/es.js'
import enLang from '../../translations/Home/en.js'


function Home() {
  const { language, changeLanguage } = useContext(LanguageContext);
  const translation = language === 'es' ? esLang : enLang


  useEffect(() => {
    const { hash } = window.location
    const decodedHash = decodeURI(hash).replace('#', '')
    const element = document.getElementById(decodedHash)
    if(hash) {
    element.scrollIntoView({behavior: "smooth"})
    }
    else {
      window.scrollTo(0, 0);
    }
  }, []);

    return (
      <MainLayout>
          <VideoContainer changeLanguage={changeLanguage} language={language}>
            <div className='flex flex-col justify-center items-center'>
              <div className='mb-[35px] hidden sm:block'>
              <LogoCreel/>
              </div>
              <div className='mb-[35px] block sm:hidden'>
                <LogoMobile/>
              </div>
              <HeroSlider items={translation["hero.slider"]}/>
            </div>
          </VideoContainer>
          <NegociosContainer items={translation["negocios"]}/>
          <SociosContainer items={translation["socios"]}/>
          <MeetTeamContainer items={translation["meet"]}/>
          <div className='w-full bg-repeat-x h-[85px]' style={{backgroundImage: `url(${letrasCreel})`}}></div>
          <FormContainer items={translation["form"]}/>
      </MainLayout>
    );
  }
  
export default Home;