import React from 'react'
import Tabs from '../../components/common/Tabs'
import ShowMore from '../../components/common/ShowMore'


function EquipoContainer({items, team, setIsModalOpen}){
    const socios = <div className='flex flex-col items-center'><div className='flex sm:flex-row flex-wrap sm:justify-start justify-between max-w-[1090px]'> {team.socios.map(socio =>
        <div key={socio.name} className='flex mx-[10px] sm:w-[270px] sm:h-[287px] w-[147px] h-[147px] sm:m-0 my-[15px]'>
            <ShowMore item={socio} setIsModalOpen={setIsModalOpen}/>
        </div>
        )} 
     </div>
    </div>
    
    const consejeros =   <div className='flex flex-col items-center'><div className='flex sm:flex-row flex-wrap sm:justify-start justify-between max-w-[1090px]'> {team.consejeros.map(socio =>
        <div key={socio.name} className='flex mx-[10px] sm:w-[270px] sm:h-[287px] w-[147px] h-[147px] sm:m-0 my-[15px]'>
            <ShowMore item={socio} setIsModalOpen={setIsModalOpen} />
        </div>
        )} 
     </div>
    </div>

    const asociados =   <div className='flex flex-col items-center'><div className='flex sm:flex-row flex-wrap sm:justify-start justify-between max-w-[1090px]'> {team.asociados.map(socio =>
        <div key={socio.name} className='flex mx-[10px] sm:w-[270px] sm:h-[287px] w-[147px] h-[147px] sm:m-0 my-[15px]'>
            <ShowMore item={socio} setIsModalOpen={setIsModalOpen} />
        </div>
        )} 
    </div>
    </div>
     const pasantes =   <div className='flex flex-col items-center'><div className='flex sm:flex-row flex-wrap sm:justify-start justify-between max-w-[1090px]'> {team.pasantes.map(socio =>
        <div key={socio.name} className='flex mx-[10px] sm:w-[270px] sm:h-[287px] w-[147px] h-[147px] sm:m-0 my-[15px]'>
            <ShowMore pasantes item={socio} setIsModalOpen={setIsModalOpen} />
        </div>
        )} 
    </div>
    </div>

    return(
        <div className='flex justify-center sm:p-[90px] p-[20px]'>
            <Tabs center tabs={items.tabs} contents={[socios, consejeros, asociados, pasantes]} />
        </div>
    )
}

export default EquipoContainer