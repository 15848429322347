import React, { createContext, useState, useEffect } from 'react'
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const languageStorage = localStorage.getItem("language")
  const [language, setLanguage] = useState(languageStorage);

  useEffect(() => {
    if(!languageStorage){
      localStorage.setItem("language", "es");
      setLanguage('es')
    }
  }, [])

  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    setLanguage(lang)
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
