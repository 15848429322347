const en = {
    "/partners/1": {
        "link": "/partners/1",
        "image": require('../../assets/equipo/Socios/Biografia/CarlosCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Carlos_Creel C..vcf'),
        "name": "Carlos Creel C.",
        "subtitle": "Senior Partner",
        "description": ["Corporate and Commercial ", "Finance", "Mergers and Acquisitions"],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010",
            "email": "carlos.creel@creelabogados.com"
        },
        "p": ["Carlos is the Senior Partner of our Firm. Carlos professional practice focuses on general corporate work, and banking and securities law matters. His corporate practice includes advising public and private corporations and other entities in a variety of industries in connection with M&A, corporate governance matters, cross-border joint ventures and strategic alliances, as well as investment funds in their formation and a variety of transactional matters. He also has extensive experience in corporate finance transactions, including secured financing and syndicated lending, as well as in capital markets transactions, representing issuers and investment banks in a broad range of public and private transactions, including initial public offerings, secondary offerings and cross-border securities offerings.", 
        "Carlos represents family investment offices and family-owned or controlled businesses on both specialized and strategic matters, including succession planning and generational transition. Carlos also regularly and actively advises not-for-profit institutions on governance and related matters. He is member of the Boards and Executive Committees of Fundación Empresarios por la Educación Básica (ExEb) and Alianza Médica para la Salud (AMSA)."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education and Professional Experience ",
                    "p": ["Law Degree - Escuela Libre de Derecho (1984)", "Foreign Associate - Shearman & Sterling, New York (1984-1985)", "Senior Management Program AD-2 – IPADE (2018)"]
                },
                {
                    "title": "Academic Experience ",
                    "p": ["Carlos has been a Professor of Corporate Law at the Law Faculty of the Instituto Tecnológico Autónomo de México (ITAM), Adjunct Professor of International Private Law at the Escuela Libre de Derecho and since 2011 is Professor of Corporate Governance and Private Equity in the LL.M. Program of the Universidad Panamericana."]
                },
                {
                    "title": "Publications",
                    "p": ["Carlos has published articles and essays on a wide variety of fields of Mexican law, including corporate governance and private equity:", 
                    "“México” Chapter in 'Corporate Governance 2022', published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2022.", 
                    "• “México” Chapter in 'Corporate Governance 2020', published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2020.", 
                    "• “Mexico” Chapter in “NYSE: Corporate Governance Guide”, published in association with the New York Stock Exchange, edited by Steven A. Rosenblum, Karessa L. Cain and Sabastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2015.", 
                    "• “Mexico” in “Corporate Laws of the World” - “Gesellschaftsrecht des Auslands”, edited by Gerhard Wegen, Andreas Spahlinger and Marcel Barth, Gleiss Lutz, published by Verlag C.H.BECK, 2013.", 
                    "• “La Sociedad Anónima Promotora de Inversión”, published in Obra Jurídica Enciclopédica en Homenaje a la Escuela Libre de Derecho en su Primer Centenario, Derecho Mercantil, by Editorial Porrua, México, 2012.",
                    "• “Duties and Liabilities of Directors of Mexican Stock Corporations”, published in The International Who's Who of Corporate Governance Lawyers, 2010.",
                    "• “Mexico” in “Self-Cleaning in Public Procurement Law”, edited by Hermann Pünder, Hans-Joachim Priess, published by Carl Heymanns Verlag, 2009."

                ]
                }
        ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations",
            "p": ["• Mexican Bar Association", "• Ilustre y Nacional Colegio de Abogados"]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/2": {
        "link": "/partners/2",
        "image": require('../../assets/equipo/Socios/Biografia/JavierPortas_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg'),
        "vcard": require('../../assets/vcards/Javier_Portas L..vcf'),
        "name": "Javier Portas L.",
        "subtitle": "Partner",
        "description": ["Tax", "Compliance"],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3037",
            "email": "javier.portas@creelabogados.com"
        },
        "p": ["Javier is a Partner of our Firm. Javier focuses his practice on Mexican and international corporate taxation. He provides corporate tax and international tax advice to foreign and domestic corporations, including partnerships, limited liability companies, joint venture entities, trusts and funds. Javier also represents clients in tax litigation at federal and local courts. His practice also includes international trade matters and the application of several Foreign Trade Agreements entered into by Mexico, such as the North American Free Trade Agreement. He represents clients in connection with the review by Mexican tax authorities of compliance with determination of rules of origin and procedures followed to challenge the authorities’ determination, both administratively, as well as before federal courts.", "Javier has collaborated with customs authorities in drafting complementary procedural rules applicable to origin determination review processes. He has participated in several negotiations of tax benefits with local tax authorities, mainly in connection with the establishment of new investments within Mexico."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": [
                        "Law Degree- Escuela Libre de Derecho (1988)", 
                        "LL.M Degree in Tax Law – Universidad Panamericana (1991)", 
                        "Postgraduate Degree in Tax Counseling - Universidad Panamericana (1991)", 
                        "Tax Law Program - Academia de Estudios Fiscales de la Contaduría Pública, A.C. (1991)", 
                        "Legal English and Orientation in the U.S. Legal System Program - International Law Institute - George Washington University Law School (1997)"
                    ]
                },
                {
                    "title": "Academic Experience",
                    "p": ["Javier has been a Professor of Law at the Tourism Faculty of the Universidad Anahuac and Assistant Professor of Roman Law at the Escuela Libre de Derecho."]
                },
                {
                    "title": "Publications",
                    "p": ["• “Mexico, cahiers de droit fiscal international” (“Mexico: cases of international tax law”) Double non-taxation, Volume 89a, published by the International Fiscal Association, 2004 Vienna Congress."]
                }
        ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations ",
            "p": ["• Mexican Chapter of the International Tax Association ", 
            "• International Tax Committee of the Colegio Mexicano de Contadores Públicos Titulados"]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/3": {
        "link": "/partners/3",
        "image": require('../../assets/equipo/Socios/Biografia/GustavoStruck_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
        "vcard": require('../../assets/vcards/Gustavo_Struck Creel.vcf'),
        "name": "Gustavo Struck Creel",
        "subtitle": "Partner",
        "description": ["Corporate and Commercial ", "Mergers and Acquisitions", "Finance", "Real Estate"],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3108",
            "email": "gustavo.struck@creelabogados.com"
        },
        "p": ["Gustavo is a Partner of our Firm. Gustavo specializes in Corporate Law, Finance and Real Estate. Gustavo represents Mexican and foreign companies across a broad range of industries on their local and international in transactions involving M&A, joint ventures and corporate governance. He regularly advices private equity firms in fund formation and transactional work. Additionally, he has an active Finance practice, advising clients in domestic and cross-border corporate finance transactions, including syndicated lending and secured financing, with a deep regulatory and transactional knowledge of the non-banking sector. Gustavo’s Real Estate practice covers a wide variety of transactions and participants in this industry, highlighting his wide experience in hospitality and industrial projects. Gustavo serves as external counsel to a number of family offices and participates in several Board of Directors and Committees."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education and Professional Experience",
                    "p": ["Law Degree – Escuela Libre de Derecho (2001) ", 
                    "LL.M Degree – Northwestern Pritzker School of Law (2002)", 
                    "Foreign Associate – Morrison & Foerster, San Francisco (2002-2003)"
                   ]
                },
                {
                    "title": "Publications",
                    "p": ["• “México” Chapter in “Corporate Governance 2022”, published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2022.",
                    "• “Mexico: An Introduction to Corporate/M&A”, published in Chambers and Partners Global Guide, 2022.",
                    "• “México” Chapter in “Corporate Governance 2020”, published by ICLG, edited by Adam O. Emmerich and Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2020.",
                    "• “Relevant Issues Involving M&A of Mid-Size Mexican Companies”, published by Latin American Law & Business Report, Thomson Reuters, August 2012",
                    "• “Update to the Proposed Amendments to the Mexican Competition Law”, published by Latin American Law & Business Report, Thomson Reuters, August 2010",
                    "• “Structuring Real Estate Projects in Mexico, Recent Experience”, published by North American Free Trade & Investment Report, WorldTrade Executive, March 2009",
                    "• “SAPI’s as an Efficient Vehicle for Private Equity Investments in Mexico”, published by Latin American Law & Business Report, WorldTrade Executive, April 2007",
                    "“New Amendments to Mexican Finances Serve to Deregulate Various Activities”, published by Latin American Law & Business Report, WorldTrade Executive, August 2006"
                ]
                }
        ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations ",
            "p": ["• American Bar Association"]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/4": {
        "link": "/partners/4",
        "image": require('../../assets/equipo/Socios/Biografia/AlfonsoRazu_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
        "vcard": require('../../assets/vcards/Alfonso_Razú A..vcf'),
        "name": "Alfonso Razú A.",
        "subtitle": "Partner",
        "description": ["Corporate and Commercial", "Mergers and Acquisitions", "Real Estate", "Finance", "Antitrust and Competition", "Natural Resources"],
        "contact": {
            "title": "Contact",
            "location": "Mexico City| Merida",
            "phone": "+52 (55) 1167-3027",
            "email": "alfonso.razu@creelabogados.com"
        },
        "p": ["Alfonso is a Partner of our Firm. Alfonso is the Partner responsible for our office located in Merida, Yucatan. He specializes in Corporate Law, including Mergers & Acquisitions and Private Equity, as well as in Banking and Finance. Alfonso’s professional practice has been centered in providing advice to Mexican and foreign groups, including corporate governance structures, joint ventures, investment and contracting models, both private and public, corporate reorganizations and in a variety of M&A transactions. Alfonso also focuses his practice on all types of real estate transactions, as well as financial transactions, including corporate financings and syndicated loans, and capital and debt markets deals."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["Law Degree – Instituto Tecnológico Autónomo de México (2003)",
                    "LL.M Degree – The University of Texas at Austin (2005)"
                   ]
                },
                {
                    "title": "Publications",
                    "p": ["• “Acquisition Finance Mexico”, published by LatinLawyer, 2015-2016."
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/5": {
        "link": "/partners/5",
        "image": require('../../assets/equipo/Socios/Biografia/CesarMorales_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
        "vcard": require('../../assets/vcards/César_Morales G..vcf'),
        "name": "César Morales G.",
        "subtitle": "Partner",
        "description": ["Corporate and Commercial",
        "Mergers and Acquisitions",
        "Real Estate",
        "Finance"
    ],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3038",
            "email": "cesar.morales@creelabogados.com"
        },
        "p": ["Cesar is a Partner of our Firm. Cesar specializes in Corporate Law, including M&A, Real Estate and Finance, as well is in matters related to Gaming Law and Sports Law. César has over 20 years of experience in prestigious Mexican law firms and throughout his active professional career he has advised domestic and foreign clients in establishing their operations in Mexico, as well as in a wide variety of joint ventures, mergers and acquisitions and corporate restructurings, in fund formation and in real estate projects, as well as in all types of financing transactions."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education and Professional Experience",
                    "p": ["Law Degree – Escuela Libre de Derecho (2004)",
                   "LL.M. Degree – The London School of Economics and Political Science (2006)",
                   "Foreign Associate – Morrison & Foerster, New York (2007-2008)"]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/6": {
        "link": "/partners/6",
        "image": require('../../assets/equipo/Socios/Biografia/DiegoValdesEsc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Diego_Valdés A..vcf'),
        "name": "Diego Valdés A. ",
        "subtitle": "Partner",
        "description": ["Corporate and Commercial",
        "Mergers and Acquisitions",
        "Finance",
        "Real Estate",
        "Compliance"],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3039",
            "email": "diego.valdes@creelabogados.com"
        },
        "p": ["Diego is a Partner of our Firm. Diego practice is primarily focused on M&A, Banking and Finance, Private Equity, Commercial Contracting and Corporate Governance, with vast transactional experience in deals involving companies and assets in Mexico, the United States, Latin America, Asia and Europe. Further, Diego provides advice on complex corporate restructurings and in foreign investment, data privacy and general regulatory compliance. Moreover, Diego advises clients in the drafting and structuring of all types of agreements, including, without limitation, trust agreements, purchase agreements of any type of asset, agency agreements, shareholders agreements, credit agreements, pledge and mortgage agreements, lease agreements, among others."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education and Professional Experience",
                    "p": ["Educación y experiencia profesional",
                    "Law Degree - Universidad Panamericana (2004)",
                    "Postgraduate Degree in Commercial Law – Universidad Panamericana (2005)",
                    "LL.M. Degree – Stockholm University (2006)",
                    "Foreign Associate – Gibson, Dunn & Crutcher, New York (2007-2009)"]
                },
                {
                    "title": "Academic Experience",
                    "p": ["Mr. Valdés has been Professor of Property Rights at the Centro de Investigación y Docencia Económica (CIDE) and of Corporate Law at Universidad Anáhuac del Sur."]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English", "• Portuguese"]
        }
    },
    "/partners/7": {
        "link": "/partners/7",
        "image": require('../../assets/equipo/Socios/Biografia/PabloArellano_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Arellano Q..vcf'),
        "name": "Pablo Arellano Q.",
        "subtitle": "Partner",
        "description": ["Corporate and Commercial",
        "Mergers and Acquisitions",
        "Finance",
        "Real Estate"
        ],
        "contact": {
            "title": "Contact",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3040",
            "email": "pablo.arellano@creelabogados.com"
        },
        "p": ["Pablo is a Partner of our Firm. Pablo specializes in Corporate Law, including M&A, as well as in Banking and Finance, and Real Estate. Pablo has ample experience on M&A transactions and corporate restructurings, as well as on the structuring and implementation of finance transactions, including domestic and cross-border credits, and security packages. Likewise, Pablo advices foreign and domestic clients in the structuring of real estate trusts, purchase and sale, and lease agreements."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": [
                    "Law Degree – Universidad Iberoamericana (2010)",
                    "LL.M. Degree – University of California at Berkeley (2015)"]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/8": {
        "link": "/partners/8",
        "image": require('../../assets/equipo/Socios/Biografia/ErnestoSaldate_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
        "vcard": require('../../assets/vcards/Ernesto_Saldate A..vcf'),
        "name": "Ernesto Saldate del Alto",
        "subtitle": "Partner",
        "description": ["Litigation and Dispute Resolution", "Compliance"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3028",
            "email": "ernesto.saldate@creelabogados.com"
        },
        "p": ["Ernesto is a Partner of our Firm. Ernesto specializes in Civil and Commercial Litigation, and Arbitration. His practice encompasses advising and representing national and foreign companies in all types of complex litigation, before local and federal courts, including constitutional, administrative, civil, and commercial litigation. In addition, Ernesto represents clients in alternative dispute resolution (ADR) mechanisms such as mediation and arbitration, participating in ad hoc procedures and before the main administrative institutions, both national and foreign. In coordination with our Corporate Practice, Ernesto represents clients in the design and implementation of prevention, management and remedial programs for regulatory compliance in anti-corruption, data privacy, antitrust, anti-money laundering and regulatory compliance in general, as well as in related audit and investigation procedures."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["Law Degree - Universidad Panamericana (2001)",
                   "Postgraduate Degree in Corporate and Commercial Law – Escuela Libre de Derecho 2007",
                   "Postgraduate Degree in Criminal Law – Escuela Libre de Derecho (2008)", 
                   "Postgraduate Degree in Civil and Commercial Procedural Law – Universidad Panamericana (2009)",
                   "Postgraduate Degree in Civil and Commercial Law – Universidad Panamericana (2010)"
                   ]
                },
                {
                    "title": "Publications",
                    "p": ["• “Shareholder claims against M&A transactions in Mexico”, published by Lexology: Getting the Deal Through, April 2022.",
                    "•  “M&A Litigation in Mexico”, publicado por Lexology: Getting the Deal Through, Abril 2021."
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/partners/9": {
        "link": "/partners/9",
        "image": require('../../assets/equipo/Socios/Biografia/SofiaGomez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/SofiaGomez_Esc.png'),
        "vcard": require('../../assets/vcards/Sofía_Gómez B..vcf'),
        "name": "Sofía Gómez B.",
        "subtitle": "Partner",
        "description": ["Labor and Employment", "Compliance", "ASG"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3015",
            "email": "sofia.gomez@creelabogados.com"
        },
        "p": ["Sofía is a Partner of our Firm. Sofía has ample knowledge experience practicing all subspecialties of Labor and Employment Law, such as individual and collective employment matters, social security and immigration. She focuses her practice on employment consultancy advising international and domestic clients in managing their relationships with their employees or unions. Likewise, Sofía participates in labor due diligences related to M&A transactions. She designs and implements defense strategies in individual and collective employment actions, as well as in calls for strike procedures or stoppages, including conducting negotiations with the union, terminating the collective bargaining agreement, and preventing of unfair labor practices. Sofia also provides advice in the structuring of saving funds and private retirement plans, and equity or any other incentives plans, and in implementing preventive strategies for the productive development of employment relationships."],
        "qualifications": {
            "title": "Trajectory",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Anahuac México Norte (2011)",
                   " • LL.M. Degree in Employment Law – Escuela Libre de Derecho (2015)",
                   "• LL.M. Degree - Pritzker School of Law, Northwestern University (2019)"
                ]
                }
        ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations",
            "p": ["• Asociación Nacional de Abogados de Empresa", "• Confederación de Cámaras Industriales de los Estados Unidos Mexicanos", "• Abogadas MX", "• American Bar Association", "• International European Employment Law Alliance"]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/1": {
        "link": "/advisers/1",
        "image": require('../../assets/equipo/Consejeros/Biografia/LuisCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/LuisJCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Luis_J. Creel C..vcf'),
        "name": "Luis J. Creel C.",
        "subtitle": "Honorary Counsel",
        "description": [""],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3000",
            "email": "luis.creel@creelabogados.com"
        },
        "p": ["Luis is Honorary Counsel and founder of our Firm, together with Luis J. Creel Luján.", "Upon the passing of his father, Luis led our Firm until the late 90s. For more than 40 years, Luis focused his professional practice on Corporate and Securities Law, considered one of the most recognized lawyers in the areas of his expertise, representing the most relevant business conglomerates and financial groups of our country. He was founder and Executive Chairman of the Board of Directors of IXE Grupo Financiero (now part of Grupo Financiero Banorte), and member of the Board and Executive Committees of several companies listed in the Mexican Stock Exchange.", "Luis was founder and Chairman of the Board of Trustee of Fundación Casa Alianza México, and currently is Vice-chairman of the Board of Trustees Ciudad UP-IPADE."],
        "qualifications": {
            "title": "Qualifications",
            "items":[{
                "title": "Education and Professional Experience",
                "p": [
                    "• Lawyer - Escuela Libre de Derecho", 
                    "• Postgraduate Degree in American Law - Princeton University",
                    "• LL.M Degree - Harvard University",
                    "• Foreign Associate - Hill & Barlow, Boston",
                    "• Foreign Associate - Hill & Barlow, Boston",
                    "• AD-2 Senior Management Program - IPADE",
                    "• Postgraduate Degree in Tax Law - Universidad Panamericana"
                ]
            },
            {
                "title": "Academic Experience",
                "p":["Luis was Research Assistant of has been Professor Louis B. Sohn, at Harvard University, Founding Professor of the Law Department at Instituto Panamericano de Alta Dirección de Empresas (IPADE) and Professor of International Private Law at Universidad Iberoamericana."]
            }
        ]
        },
        "affiliations": {
            "title": "Memberships & Affiliations",
            "p": ["• Mexican Bar Association", "• International Committee of the Mexican Bar Association", "• Harvard Alumni Association"]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/2": {
        "link": "/advisers/2",
        "image": require('../../assets/equipo/Consejeros/Biografia/JorgeTorres_esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jorge_Torres B..vcf'),
        "name": "Jorge Torres B.",
        "subtitle": "Senior Counsel",
        "description": ["Real Estate",
        "Corporate and Commercial"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010 ",
            "email": "jorge.torres@creelabogados.com"
        },
        "p": ["Jorge is Senior Counsel of our Firm. During his long-standing career, he has developed a unique Real Estate practice. He has participated in some of the most relevant real estate matters and projects in Mexico, actively representing important Mexican and international groups in the acquisition, lease and development of real estate properties for hospitality, retail, industrial and residential projects throughout Mexico. Likewise, he has ample experience leading numerous corporate and commercial transactions, including M&A, representing clients in several industry sectors."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education and Professional Experience",
                    "p": ["• Law Degree - Escuela Libre de Derecho (1985)",
                   "• Postgraduate Degree in International Commercial Arbitration – Escuela Libre de Derecho (1995)",
                   "• Foreign Associate - Gardere & Wynne – Dallas (1988)"
                   ]
                },
                {
                    "title": "Publications",
                    "p": ["• “Mexico” in “PLC Cross-border Corporate Real Estate Handbook”, published by Practical Law Company, United Kingdom, First to Ninth Editions (2004 to 2013).",
                    "• “Mexico” in “The Real Estate Law Review”, published by Law Business Research, 2012.",
                    "• “Derecho Inmobiliario, Temas Selectos y Perspectivas del Sector”, published by Editorial Porrúa and Instituto Tecnológico de México, 2010.",
                    "• “Overview of Mexican Real Estate Investments”, PLC Cross-border Corporate Real Estate Handbook, published by Practical Law Company, Fourth Edition 2009.",
                    "• “Real Estate Investments in Mexico”, PLC Cross-border Corporate Real Estate Handbook, published by Practical Law Company, Third Edition 2008."
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/advisers/3": {
        "link": "/advisers/3",
        "image": require('../../assets/equipo/Consejeros/Biografia/AlejandroCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
        "vcard": require('../../assets/vcards/Alejandro_Creel Y..vcf'),
        "name": "Alejandro Creel Y.",
        "subtitle": "Counsel",
        "description": ["Corporate and Commercial", "Mergers and Acquisitions", "Finance",
        "Real Estate"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 55-1167-3041, ext. 160",
            "email": "alejandro.creel@creelabogados.com"
        },
        "p": ["Alejandro is Counsel of our Firm. Alejandro specializes in Corporate Law and Real Estate. He focuses his Corporate practice in advising national and international corporations and funds in M&A transactions, corporate restructurings, joint ventures and corporate governance. On the Real Estate front, he actively participates in complex real estate transactions and projects, representing investors, developers and operators in the structuring of trust, purchase and sale and lease agreements, as well as in the formation of condominium property, time-sharing and fractional ownership regimes."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Iberoamericana (2006)",
                   "• LL.M. Degree - Instituto de Empresa (IE), Madrid, Spain (2009)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    // "/advisers/5": {
    //     "name": "Claudia Lelo de Larrea M.",
    //     "subtitle": "Consejera",
    //     "description": ["Competencia Económica",
    //     "Cumplimiento Regulatorio" ],
    //     "contact": {
    //         "title": "Contact information",
    //         "location": "Mexico City",
    //         "phone": "+52 (55) 1167-3021",
    //         "email": "claudia.lelodelarrea@creelabogados.com"
    //     },
    //     "p": ["Claudia es Consejera del despacho. Se especializa en Competencia Económica. Claudia brinda asesoría a agentes económicos sobre procedimientos ante la Comisión Federal de Competencia Económica y el Instituto Federal de Telecomunicaciones, así como sobre mejores prácticas en materia de competencia económica. Su práctica incluye la atención de notificaciones de concentraciones, investigaciones por condiciones de competencia efectiva, barreras a la competencia e insumos esenciales. Asimismo, cuenta con amplia experiencia en investigaciones y procedimientos sobre prácticas monopólicas absolutas, relativas y concentraciones ilícitas. Claudia lleva a cabo la coordinación con nuestra clientela del diseño e implementación de programas de cumplimiento (compliance) en materia de competencia económica."],
    //     "qualifications": {
    //         "title": "Qualifications",
    //         "items":[ 
    //             {
    //                 "title": "Educación",
    //                 "p": ["Licenciada en Derecho (Mención Honorífica) - Universidad Nacional Autónoma de México (2010)",
    //                "Maestría en Derecho Económico Internacional y Política (con distinción) – Universidad de Barcelona, España (2012)",
    //                "Actualmente, cursando un Doctorado en Derecho - Instituto de Investigaciones Jurídicas (UNAM)"
    //             ]
    //             }
    //     ]
    //     },
    //     "languages": { 
    //         "title": "Languages",
    //         "p": ["• Spanish", "• English", "• Francés"]
    //     }
    // },
    // "/advisers/6": {
    //     "name": "Patricia López Padilla B.",
    //     "subtitle": "Consejera",
    //     "description": ["Derecho Fiscal"],
    //     "contact": {
    //         "title": "Contact information",
    //         "location": "Mexico City",
    //         "phone": "+52 (55) 1167-3016",
    //         "email": "patricia.lopezpadilla@creelabogados.com"
    //     },
    //     "p": ["Patricia es Consejera del despacho. Se especializa en Derecho Fiscal, incluyendo consultoría fiscal general, y litigio fiscal y administrativo. Patricia enfoca su sólida práctica fiscal en la atención de consultas en materia tributaria, asesorando a empresas nacionales e internacionales en una amplia variedad de temas fiscales relacionados con operaciones corporativas y financieras. Patricia ha representado clientes de diversas industrias en litigios de carácter fiscal y procedimientos administrativos ante autoridades federales y locales."],
    //     "qualifications": {
    //         "title": "Qualifications",
    //         "items":[ 
    //             {
    //                 "title": "Educación",
    //                 "p": ["Licenciado en Derecho (Mención Honorífica) - Universidad Panamericana (2001)"
    //             ]
    //             },
    //             {
    //                 "title": "Experiencia académica",
    //                 "p": ["Patricia ha sido Profesora adjunta en las cátedras de Derecho Fiscal, Impuesto Sobre la Renta y Derecho Procesal Fiscal en la Universidad Panamericana (2004-2008), y actualmente es Profesora en la Maestría de Derecho Fiscal en la Universidad Panamericana, Campus Guadalajara, Culiacán y Mazatlán, y Profesora titular de las cátedras de Código Fiscal de la Federación e Impuesto sobre la Renta en la Licenciatura en Derecho en la Universidad Panamericana."
    //             ]
    //             }
    //     ]
    //     },
    //     "languages": { 
    //         "title": "Languages",
    //         "p": ["• Spanish", "• English"]
    //     }
    // },
    "/associates/1": {
        "link": "/associates/1",
        "image": require('../../assets/equipo/Asociados/Biografía/PaulinaAparicio_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/PaulinaAparicio_Esc.jpg'),
        "vcard": require('../../assets/vcards/Paulina_Aparicio L..vcf'),
        "name": "Paulina Aparicio L.",
        "subtitle": "Associate",
        "description": ["Labor and Employment"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3031",
            "email": "paulina.aparicio@creelabogados.com"
        },
        "p": ["Paulina has extensive experience, focusing on Employment litigation. Likewise, Paulina assists and represents national and international clients in employment lawsuits filed before the Labor Boards and Labor Courts at the federal and state level, as well as in conducting negotiations at the Conciliation and Mediation Center She also advices clients in a variety of Labor related matters, including attending labor on-site inspections, carrying out termination processes, executing and terminating collective bargaining agreements, designing strategies for restructuring corporate structures that comply with the subcontracting/outsourcing regime, and managing employment relationships."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Panamericana (2018)",
                    "• LL.M. Degree – Universidad Panamericana (2022)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/13": {
        "link": "/associates/13",
        "image": require('../../assets/equipo/Asociados/Biografía/SebastiandePalma.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/SebastianDePalma_Esc.jpg'),
        "vcard": require('../../assets/vcards/Sebastián_de Palma C..vcf'),
        "image_team": '',
        "vcard": '',
        "name": "Sebastián de Palma C.",
        "subtitle": "Associate",
        "description": ["Tax"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3000, ext. 128",
            "email": "sebastian.depalma@creelabogados.com"
        },
        "p": ["Sebastian specializes in Tax Law. He represents clients from diverse industries in Tax consultations deriving from their day-to-day operations, as well as in Tax audits. He also has experience in transactional work, focusing on the tax aspects of domestic and cross-border M&A and corporate and debt restructurings, as well as in foreign investments matters."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["Law Degree –Universidad Anahuac (2021)"]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/4": {
        "link": "/associates/4",
        "image": require('../../assets/equipo/Asociados/Biografía/Tsen_Esc.jpg'),
        "image_team":  require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg'),
        "vcard": require('../../assets/vcards/Tsen-Fang Lin_.vcf'),
        "name": "Tsen Fang-Lin",
        "subtitle": "Associate",
        "description": ["Corporate and Commercial", "Finance"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3034",
            "email": " tsen-fang.lin@creelabogados.com"
        },
        "p": ["Lin advises Mexican and international clients in corporate and commercial matters, including the daily maintenance of their corporations in Mexico, as well as the drafting and negotiation of a wide variety of contracts and counseling in M&A transactions. Her practice involves foreign investment matters, as well as data protection and anti-money laundering regulations. Likewise, Lin provides advice on finance transactions, including domestic and cross-border credits and the implementation of their corresponding security packages."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Escuela Libre de Derecho (2019)"]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English", "• Mandarin", "• Minnan Taiwanese"]
        }
    },
    "/associates/5": {
        "link": "/associates/5",
        "image": require('../../assets/equipo/Asociados/Biografía/KarlaGrajales_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/KarlaGrajales_Esc.jpg'),
        "vcard": require('../../assets/vcards/Karla_Grajales M..vcf'),
        "name": "Karla Grajales M.",
        "subtitle": "Associate",
        "description": ["Corporate and Commercial", "Mergers and Acquisitions", "Finance", "Real Estate", "Compliance"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext. 3033",
            "email": "karla.grajales@creelabogados.com "
        },
        "p": ["Karla focuses her practice in local and cross-border M&A transactions, corporate restructurings, corporate finance, general corporate and commercial matters, compliance in foreign investment and data protection regulations, as well as corporate governance. Likewise, She provides advice on the structuring of real estate trusts, purchase and sale, and lease agreements. She also has experience in coordinating legal due diligences and providing advice on regulatory aspects relating to health, consumer protection, occupational safety and health, and environmental."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad del Tepeyac (2009)", 
                    "• Postgraduate Degree in Private Law - Universidad Nacional Autonoma de Mexico (2010-2011)", 
                    "• LL.M. Degree in Spanish Law - Universidad de Salamanca, Spain (2014)",
                    "• Postgraduate Degree in Business Law - Instituto Tecnologico Autonomo de Mexico (2017)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/6": {
        "link": "/associates/6",
        "image": require('../../assets/equipo/Asociados/Biografía/SandraLopez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/SandraLopez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Sandra_López CH..vcf'),
        "name": "Sandra López Ch.",
        "subtitle": "Associate",
        "description": ["Corporate and Commercial", "Finance", "Regulatory Compliance"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3022",
            "email": "sandra.lopez@creelabogados.com"
        },
        "p": ["Sandra advises Mexican and foreign clients on corporate and commercial matters, including the daily maintenance of their corporations, and in the preparation and negotiation of a vast variety of contracts relating to their regular business. Her practice involves providing advice on consultations relating to compliance of foreign investment, data protection, anti-money laundering and anti-corruption regulations. Likewise, she has actively participated in a wide variety of financing transactions, both domestic and cross-border, as well as in advising clients in financial regulation consultations."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree - Universidad Iberoamericana (2017)", 
                    "• Postgraduate Degree and LL.M. Degree in Private Law – Universidad Anáhuac (2017-2019)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/7": {
        "link": "/associates/7",
        "image": require('../../assets/equipo/Asociados/Biografía/CarolinaLopez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/CarolinaLopez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Carolina_López de Llergo P..vcf'),
        "name": "Carolina López de Llergo P.",
        "subtitle": "Associate",
        "description": ["Labor and Employment", "ESG"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3030",
            "email": "carolina.lopez@creelabogados.com"
        },
        "p": ["Carolina provides advice to national and international clients on compliance, occupational health and safety in the workplace, public and private conciliations, M&A related due diligences and acquisitions, on-site labor inspections. She also has experience in the design and implementation of strategies that prevents conflicts in the workplace."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Anahuac México (2022)"]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English", "• French"]
        }
    },
    "/associates/8": {
        "link": "/associates/8",
        "image": require('../../assets/equipo/Asociados/Biografía/FernandaMelgar_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg'),
        "vcard": require('../../assets/vcards/María_Fernanda Melgar G..vcf'),
        "name": "María Fernanda Melgar G.",
        "subtitle": "Associate",
        "description": ["Corporate and Commercial", "Mergers and Acquisitions", "Finance", "Antitrust and Competition"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext.131",
            "email": "fernanda.melgar@creelabogados.com"
        },
        "p": ["Fernanda focuses her practice in M&A transactions, corporate restructurings, general corporate and commercial matters, and the daily maintenance of corporations, including foreign investment and regulatory compliance, as well as in coordinating legal due diligences. Likewise, she provides advice on regulatory matters involving banking (FinTech) and participates in the structuring of loan and security agreements. She provides advice in Antitrust matters, including the representation of clients in notification procedures before the Federal Economic Competition Commission."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Iberoamericana (2020)"]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/9": {
        "link": "/associates/9",
        "image": require('../../assets/equipo/Asociados/Biografía/MoisesProchovnik_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/Moises_Esc.jpg'),
        "vcard": require('../../assets/vcards/Moisés_Prochovnik A..vcf'),
        "name": "Moisés Prochovnik A. ",
        "subtitle": "Associate",
        "description": ["Corporate and Commercial", "Finance"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext. 122",
            "email": "moises.prochovnik@creelabogados.com"
        },
        "p": ["Moisés focuses his practice in M&A transactions, corporate restructurings, issuance of securities in capital markets, structuring and negotiation of loan agreements and advice to all types of financial entities. Likewise, he provides advice on the structuring of trusts and stock purchase agreements. He has extensive experience in advising companies and financial entities in securities transactions, including private and public offerings, and on regulatory matters before the Mexican Stock Exchanges and the National Banking and Securities Commission."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Escuela Libre de Derecho (2017)",
                    "• Postgraduate Degree in Insurance and Bonds – Escuela Libre de Derecho (2015)",
                    "• LL.M. Degree – Columbia University (2022)",
                    "• Currently, collaborating as Foreign Associate at Winston & Strawn LLP, New York"
                ]
                },
                {
                    "title": "Academic Experience",
                    "p": ["Moises has been Assistant Professor of Securities Law at Escuela Libre de Derecho."]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English", "• Hebrew"]
        }
    },
    "/associates/10": {
        "link": "/associates/10",
        "image": require('../../assets/equipo/Asociados/Biografía/JonathanSegura_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/JonathanSegura_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jonathan_Segura M..vcf'),
        "name": "Jonathan Segura M.",
        "subtitle": "Associate",
        "description": ["Litigation and Dispute Resolution"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3010, ext. 118",
            "email": "jonathan.segura@creelabogados.com"
        },
        "p": ["Jonathan concentrates his practice in all types of civil, commercial, administrative and constitutional proceedings and litigation, as well as in mediation, conciliation and other alternative means of dispute resolution. He has experience in ordinary and special proceedings in the different judicial and administrative instances. Likewise, he provides advice on the preparation of claims, offering and evaluation of evidence, and appeals, as well as in the drafting of civil and commercial contracts, including purchases and sale, leases, mortgages and trusts, and recognition and payment of debts."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Universidad Nacional Autónoma de México (2021)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English"]
        }
    },
    "/associates/11": {
        "link": "/associates/11",
        "image": require('../../assets/equipo/Asociados/Biografía/PabloTorres_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Torres A..vcf'),
        "name": "Pablo Torres A.",
        "subtitle": "Associate",
        "description": ["Corporate and Commercial", "Regulatory Compliance"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3021",
            "email": "pablo.torres@creelabogados.com"
        },
        "p": ["Pablo focuses his practice on general corporate and commercial matters, and the daily maintenance of corporations, including corporate governance and compliance with foreign investment, data protection and anti-money laundering regulations. He also has experience in transactional corporate work, particularly in M&A transactions Likewise, he provides advice on the coordination of corporate due diligences and the preparation of all types of commercial contracts. He has experience in Intellectual Property matters, including the registration and maintenance of IP rights, as well as in IP due diligences."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO) (2021)", 
                    "• Postgraduate Degree in Corporate Law - Instituto Tecnológico Autónomo de México (2022)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish", "• English", "• German"]
        }
    },
    "/associates/12": {
        "link": "/associates/12",
        "image": require('../../assets/equipo/Asociados/Biografía/Rodrigo Estrada 2.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/RodrigoEstrada1.jpg'),
        "vcard": require('../../assets/vcards/Rodrigo_Estrada C..vcf'),
        "name": "Rodrigo Estrada C.",
        "subtitle": "Associate",
        "description": ["Labor and Employment"],
        "contact": {
            "title": "Contact information",
            "location": "Mexico City",
            "phone": "+52 (55) 1167-3021",
            "email": "rodrigo.estrada@creelabogados.com"
        },
        "p": ["Rodrigo specializes in Labor and Employment Law. He actively represents and provides advice to companies in connection with labor related matters, including the hiring and termination of employees, transfer of employees as a consequence of asset-based acquisitions and mergers, labor implications with respect to M&A, as well as labor audits implemented by the labor authorities. Likewise, Rodrigo has participated in numerous, labor trials, strike procedures, mass employee terminations, and in drafting and negotiating collective bargaining agreement."],
        "qualifications": {
            "title": "Qualifications",
            "items":[ 
                {
                    "title": "Education",
                    "p": ["• Law Degree – Tecnologico de Monterrey (20202)", 
                    "Postgraduate Degree in Compliance - Escuela Libre de Derecho (2023)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Languages",
            "p": ["• Spanish"]
        }
    }
}

export default en;