import React from "react";
const Twitter = (props) => (
  <svg
    id="layer1"
    xmlns="http://www.w3.org/2000/svg"
    width={19.563}
    height={18.31}
    viewBox="0 0 19.563 18.31"
    {...props}
  >
    <path
      id="path1009"
      d="M489.628,374.75l7.553,10.1-7.6,8.211h1.711l6.654-7.189,5.376,7.189h5.821l-7.978-10.667,7.075-7.643h-1.711l-6.128,6.62-4.952-6.62Zm2.516,1.26h2.674L506.627,391.8h-2.674Z"
      transform="translate(-489.58 -374.75)"
      fill="#fff"
    />
  </svg>
);
export default Twitter;