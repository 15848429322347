import React from 'react'
import PropTypes from 'prop-types'
import bgvideo from '../../assets/home/VideoDesk_layer.mp4'

function VideoContainer({children, changeLanguage, language}){
    const normalClass = 'text-[#fff] mr-[5px] font-medium cursor-pointer'
    const activeClass = 'text-[#fff] mr-[5px] font-medium cursor-pointer underline decoration-solid'

    return(
        <div id='Eyecatcher' className='relative w-screen h-screen bg-cover'>
            <p className='text-[#fff] absolute z-10 ml-[90px] mt-[10px] text-[28px] font-medium hidden sm:block'>Creel/abogados</p>
            <div className='absolute z-20 right-0 sm:mr-[20px] flex sm:mt-[10px] m-[25px]'>
                <button onClick={() =>changeLanguage('es')} className={language === 'es' ? activeClass : normalClass}>ESP</button>
                <p className='text-[#fff]'>/</p>
                <button onClick={() =>changeLanguage('en')} className={language === 'en' ? activeClass : normalClass}>ENG</button>
            </div>
            <video className='w-full h-full object-cover absolute z-0' src={bgvideo} muted autoPlay loop></video>
            <div className='absolute z-10 flex flex-col justify-center items-center w-full h-full'> 
                {children} 
            </div>
        </div>
    )
}

VideoContainer.propTypes = {
    children: PropTypes.any,
    changeLanguage: PropTypes.func,
    language: PropTypes.string
}
export default VideoContainer;