import React, { useState } from 'react';
import ArrowDown from '../../assets/common/ArrowDown'

const Accordion = ({ title, content, image }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`border-b border-[#D1C5B2] ${isOpen ? 'px-[30px] pt-[25px]' : 'p-0'}`}>
      <div
        className={`flex items-center justify-left p-4 cursor-pointer ${isOpen ? 'bg-[#AA9775] rounded-t-lg' : '' }`}
        onClick={toggleAccordion}
      > 
      <div className='flex flex-wrap'>
        <h3 className={`text-[20px] ${isOpen ? 'font-Poppins-Bold text-[#fff]' : 'font-Poppins-Light text-primary'}`}>{title}</h3>
      </div>
        <div className={`ml-auto p-[10px] w-[41px] h-[41px] mr-[10px] transform transition-transform ${
                isOpen ? 'rotate-180' : 'rotate-0'
            }`}>
            <ArrowDown width="21px" fill={`${isOpen ? '#fff' : '#5D2648'} `} />
        </div>
      </div>
      {isOpen && (
        <div className="p-4">
          <img src={image} className='w-full'></img>
          {
            content.map(p =>  <p dangerouslySetInnerHTML={{ __html: p }} style={{fontFamily: 'Poppins Light'}} className="text-[#666666] py-[10px]" />
            )
          }
        </div>
      )}
    </div>
  );
};

export default Accordion;
