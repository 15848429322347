const es = {
    "/partners/1": {
        "image": require('../../assets/equipo/Socios/Biografia/CarlosCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Carlos_Creel C..vcf'),
        "link": "/partners/1",
        "name": "Carlos Creel C.",
        "subtitle": "Socio Senior",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones Derecho Financiero", "Derecho Financiero"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010",
            "email": "carlos.creel@creelabogados.com"
        },
        "p": ["Carlos es el Socio Senior del Despacho. Centra su práctica profesional en las áreas de Derecho Corporativo, Derecho Bancario y Derecho Bursátil. Su práctica incluye asesorar a empresas públicas y privadas en operaciones de Fusiones y Adquisiciones, al igual que en asuntos de gobierno corporativo, co-inversiones (<span style='font-family: Poppins Italic'>joint ventures</span>) y alianzas estratégicas, así como a fondos de capital privado en su formación, y en sus operaciones de inversión y desinversión. Asimismo, cuenta con amplia experiencia en operaciones de financiamiento corporativo, incluyendo financiamiento garantizado y préstamos sindicados, así como en operaciones en los mercados de capitales y de deuda, representando a emisoras y bancos de inversión en ofertas públicas iniciales, ofertas secundarias y ofertas globales.", "Carlos representa a oficinas de familia (<span style='font-family: Poppins Italic'>family offices</span>) y a empresas propiedad de familias o controladas por estas en consultas especializadas y proyectos estratégicos, incluyendo la planeación sucesoria y transición generacional. De manera regular y activa, Carlos asesora a instituciones filantrópicas en su gobierno corporativo y operaciones relacionadas. Es miembro del Consejo y de los Comités Ejecutivos de Fundación Empresarios por la Educación Básica (ExEb) y Alianza Médica para la Salud (AMSA)."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado - Escuela Libre de Derecho (1984)", "Asociado Extranjero - Shearman & Sterling, Nueva York (1984-1985)", "Programa de Alta Dirección AD-2 – IPADE (2018)"]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Carlos ha sido Profesor de Derecho Corporativo en el Instituto Tecnológico Autónomo de México (ITAM), Profesor Adjunto de Derecho Internacional Privado en la Escuela Libre de Derecho y desde el 2011 es Profesor de Gobierno Corporativo y Capital Privado en el Programa de Maestría en Derecho de la Empresa de la Universidad Panamericana."]
                },
                {
                    "title": "Publicaciones",
                    "p": ["El Lic. Creel ha publicado artículos y ensayos en publicaciones especializadas, sobre una amplia variedad de áreas de Derecho Mexicano, incluyendo gobierno corporativo y capital privado:", 
                    "• Capítulo “México” en “Corporate Governance 2022”, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, publicado por ICLG, 2022.", 
                    "• Capítulo “México” en “Corporate Governance 2020”, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, publicado por ICLG, 2020.", 
                    "• Capítulo “México” en “NYSE: Corporate Governance Guide”, publicado en asociación con la Bolsa de Valores de Nueva York, editado por Steven A. Rosenblum, Karessa L. Cain y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2015.", 
                    "• Capítulo “México” en “Corporate Laws of the World” – “Gesellschaftsrecht des Auslands”, editado por Gerhard Wegen, Andreas Spahlinger y Marcel Barth, Gleiss Lutz, publicado por Verlag C.H.BECK, 2013.", 
                    "• “La Sociedad Anónima Promotora de Inversión, publicado en la Obra Jurídica Enciclopédica en Homenaje a la Escuela Libre de Derecho en su Primer Centenario, Derecho Mercantil, por Editorial Porrúa, México, 2012.",
                    "• “Duties and Liabilities of Directors of Mexican Stock Corporations”, publicado en The International Who’s Who of Corporate Governance Lawyers, 2010."
                ]
                }
        ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["• Barra Mexicana, Colegio de Abogados", "• Ilustre y Nacional Colegio de Abogados"]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/2": {
        "name": "Javier Portas L.",
        "link": "/partners/2",
        "image": require('../../assets/equipo/Socios/Biografia/JavierPortas_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg'),
        "vcard": require('../../assets/vcards/Javier_Portas L..vcf'),
        "subtitle": "Socio",
        "description": ["Derecho Fiscal", "Cumplimiento Regulatorio"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3037",
            "email": "javier.portas@creelabogados.com"
        },
        "p": ["Javier es Socio del Despacho. Su práctica se enfoca en tributación corporativa, mexicana e internacional. Brinda asesoría fiscal corporativa a sociedades nacionales y extranjeras, incluyendo todo tipo de entidades mercantiles y civiles, empresas de co-inversión, así como fideicomisos y fondos de inversión. La práctica de Javier también incluye la atención de litigios fiscales en las cortes federales y locales.", "Su práctica asimismo se enfoca en asuntos de comercio internacional y la aplicación de los diversos Tratados de Libre Comercio que México ha firmado a nivel internacional, como el Tratado de Libre Comercio de América del Norte, en la revisión por parte de las autoridades fiscales Mexicanas del cumplimiento de reglas de origen, así como el procedimiento de revisión a nivel administrativo y en las cortes federales.,", "Javier ha colaborado con las autoridades aduaneras en la redacción de reglas de procedimiento complementarias aplicables a los procesos de revisión de origen.", "A nivel local, ha participado en diversas ocasiones en negociaciones de estímulos fiscales con las autoridades fiscales de diversos Estados de la República Mexicana, en particular para el establecimiento de nuevas inversiones en nuestro país."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Abogado - Escuela Libre de Derecho (1988)", "Maestría en Derecho Tributario – Universidad Panamericana (1991)", "Postgrado en Asesoría Fiscal - Universidad Panamericana (1991)", "Programa Fiscal - Academia de Estudios Fiscales de la Contaduría Pública, A.C. (1991)", "Legal English and Orientation in the U.S. Legal System Program - International Law Institute - George Washington University Law School (1997)"]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Javier ha sido Profesor de Derecho en la Escuela de Turismo de la Universidad Anáhuac y Profesor Adjunto de Derecho Romano en la Escuela Libre de Derecho."]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• “Mexico, cahiers de droit fiscal international” (“Mexico: cases of international tax law”) Double non-taxation, Volumen 89a, publicado por International Fiscal Association, Congreso de Viena 2004."]
                }
        ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["• Capítulo Mexicano de la Asociación Fiscal Internacional", "• Comité Fiscal Internacional del Colegio Mexicano de Contadores Públicos Titulados"]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/3": {
        "link": "/partners/3",
        "image": require('../../assets/equipo/Socios/Biografia/GustavoStruck_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
        "vcard": require('../../assets/vcards/Gustavo_Struck Creel.vcf'),
        "name": "Gustavo Struck Creel",
        "subtitle": "Socio",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Financiero", "Derecho Inmobiliario", "Recursos Naturales", "ASG"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3108",
            "email": "gustavo.struck@creelabogados.com"
        },
        "p": ["Gustavo es Socio del Despacho. Su práctica la ha desarrollado en las áreas de Derecho Corporativo, Financiero e Inmobiliario. Gustavo representa a compañías nacionales y extranjeras dentro de una amplia gama de industrias en sus operaciones locales e internacionales, incluyendo operaciones de Fusiones y Adquisiciones, co-inversiones (<span style='font-family: Poppins Italic'>joint ventures</span>) y gobierno corporativo. Regularmente asesora a fondos de inversión, en sus procesos de formación y en operaciones de compraventa. Adicionalmente, cuenta con una activa práctica de Derecho Financiero, asesorando clientes en operaciones locales y transfronterizas de financiamiento corporativo, incluyendo créditos sindicados y financiamiento garantizado, con un sólido conocimiento del sector de entidades financieres no-bancarias. Su práctica en materia de Derecho Inmobiliario cubre una gran variedad de operaciones y participantes en la industria, destacando su experiencia en proyectos turísticos e industriales. Gustavo participa como abogado externo de múltiples oficinas familiares (<span style='font-family: Poppins Italic'>family offices</span>) y es miembro de varios Consejos de Administración y Comités."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación y Experiencia Profesional ",
                    "p": ["Licenciado en Derecho – Escuela Libre de Derecho (2001) ", 
                    "Maestría en Derecho – Northwestern Pritzker School of Law (2002)", 
                    "Asociado Internacional – Morrison & Foerster, San Francisco (2002-2003) "
                   ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• Capítulo “México” en “Corporate Governance 2022”, publicado por ICLG, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2022.",
                    "• “Mexico: An Introduction to“Corporate/M&A”, publicado en Chambers and Partners Global Guide, 2022.",
                    "• Capítulo “México” en “Corporate Governance 2020“, publicado por ICLG, editado por Adam O. Emmerich y Sebastian V. Niles, Wachtell, Lipton, Rosen & Katz, 2020.",
                    "• “Relevant Issues Involving M&A of Mid-Size Mexican Companies”, publicado por Latin American Law & Business Report, Thomson Reuters, Agosto 2012.",
                    "• “Update to the Proposed Amendments to the Mexican Competition Law”, publicado por Latin American Law & Business Report, Thomson Reuters, Agosto 2010.",
                    "• “Structuring Real Estate Projects in Mexico, Recent Experience”, publicado por North American Free Trade & Investment Report, WorldTrade Executive, Marzo 2009.",
                    "• “SAPI’s as an Efficient Vehicle for Private Equity Investments in Mexico”, publicado por Latin American Law & Business Report, WorldTrade Executive, Abril 2007."
                ]
                }
        ]
        },
    "affiliations": {
        "title": "Asociaciones",
        "p": ["American Bar Association"]
    },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/4": {
        "link": "/partners/4",
        "image": require('../../assets/equipo/Socios/Biografia/AlfonsoRazu_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
        "vcard": require('../../assets/vcards/Alfonso_Razú A..vcf'),
        "name": "Alfonso Razú A.",
        "subtitle": "Socio",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Inmobiliario", "Derecho Financiero", "Competencia Económica", "Recursos Naturales"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México | Mérida",
            "phone": "+52 (55) 1167-3027",
            "email": "alfonso.razu@creelabogados.com"
        },
        "p": ["Alfonso es Socio del Despacho. Es el Socio responsable de nuestra oficina ubicada en Mérida, Yucatán. Ha centrado su práctica en Derecho Corporativo, incluyendo fusiones y adquisiciones y Capital Privado, así como en Derecho Financiero. Alfonso brinda atención integral a empresas mexicanas y extranjeras en el país, desde el diseño y estructuración de esquemas de gobierno corporativo, modelos de inversión, co-inversión (<span style='font-family: Poppins Italic'>joint ventures</span>) y contratación, privados y públicos, programas de reorganización corporativa y en una amplia variedad de operaciones de fusiones y adquisiciones. Su práctica abarca asimismo la atención de todo tipo de operaciones inmobiliarias, así como operaciones financieras, incluyendo financiamiento corporativo y créditos sindicados, y operaciones en los mercados de deuda y capitales."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Instituto Tecnológico Autónomo de México (2003)",
                    "• Maestría en Derecho – The University of Texas at Austin (2005)"
                   ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• “Acquisition Finance - Mexico”, publicado por Latin Lawyer, 2015-2016."
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/5": {
        "link": "/partners/5",
        "image": require('../../assets/equipo/Socios/Biografia/CesarMorales_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
        "vcard": require('../../assets/vcards/César_Morales G..vcf'),
        "name": "César Morales G.",
        "subtitle": "Socio",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Inmobiliario", "Derecho Financiero"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3038",
            "email": "cesar.morales@creelabogados.com"
        },
        "p": ["César es Socio del Despacho. Su práctica se enfoca en Derecho Corporativo, incluyendo Fusiones y Adquisiciones, Derecho Inmobiliario y Derecho Financiero, así como en asuntos relacionados con el área de Juegos y Sorteos y Derecho Deportivo. César tiene más de 20 años de experiencia en firmas de México y durante su activa carrera profesional ha asesorado a clientes nacionales y extranjeros en el establecimiento de sus operaciones en México, en una amplia variedad de co-inversiones (<span style='font-family: Poppins Italic)'>joint ventures</span>), fusiones y adquisiciones y reestructuras corporativas, en la formación de fondos y en proyectos inmobiliarios, así como en todo tipo de operaciones de financiamiento."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado – Escuela Libre de Derecho (2004)",
                   "Maestría en Derecho Comercial Internacional – The London School of Economics and Political Science (2006)",
                   "Asociado Extranjero – Morrison & Foerster, Nueva York (2007-2008)"]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/6": {
        "link": "/partners/6",
        "image": require('../../assets/equipo/Socios/Biografia/DiegoValdesEsc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Diego_Valdés A..vcf'),
        "name": "Diego Valdés A. ",
        "subtitle": "Socio",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones","Derecho Financiero", "Derecho Inmobiliario", "Cumplimiento Regulatorio"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3039",
            "email": "diego.valdes@creelabogados.com"
        },
        "p": ["Diego es Socio del Despacho. Concentra su práctica Corporativa en operaciones de Fusiones y Adquisiciones, que complementa con su especialidad en proyectos bancarios, financieros y de capital privado, contratos mercantiles y gobierno corporativo. Cuenta con amplia experiencia transaccional en operaciones que involucran empresas y activos en México, Estados Unidos, Latinoamérica, Asia y Europa. Además, presta asesoría en procesos complejos de reestructuras corporativas, cumplimiento regulatorio en materia de inversión extranjera, protección de datos y en asuntos regulatorios en general. Asimismo, Diego brinda asesoría en la estructuración de todo tipo de contratos, incluyendo contratos de fideicomiso, compraventa de cualquier tipo de activo, convenios entre accionistas, comisión, contratos de crédito, contratos de prenda e hipoteca y arrendamiento, entre otros."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": [
                    "Licenciado en Derecho - Universidad Panamericana (2004)",
                    "Especialidad en Derecho Mercantil – Universidad Panamericana (2005)",
                    "Maestría en Derecho – Stockholm University (2006)",
                    "Asociado Extranjero – Gibson, Dunn & Crutcher, Nueva York (2007-2009)"]
                },
                {
                    "title": "Experiencia académica",
                    "p": ["Diego ha sido Profesor de Bienes y Derechos Reales en el Centro de Investigación y Docencia Económica (CIDE) y de Sociedades Mercantiles en la Universidad Anáhuac del Sur."]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Portugués"]
        }
    },
    "/partners/7": {
        "link": "/partners/7",
        "image": require('../../assets/equipo/Socios/Biografia/PabloArellano_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Arellano Q..vcf'),
        "name": "Pablo Arellano Q.",
        "subtitle": "Socio",
        "description": ["Derecho Corporativo y Mercantil",
        "Fusiones y Adquisiciones",
        "Derecho Financiero",
        "Derecho Inmobiliario",
        "ASG"
        ],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3040",
            "email": "pablo.arellano@creelabogados.com"
        },
        "p": ["Pablo es Socio del Despacho. Su práctica se ha centrado en Derecho Corporativo, incluyendo Fusiones y Adquisiciones, así como en Derecho Financiero y Derecho Inmobiliario. Pablo cuenta con amplia experiencia en operaciones de Fusiones y Adquisiciones y reestructuraciones corporativas, así como en el diseño, preparación y ejecución de operaciones de financiamiento, incluyendo créditos domésticos y transfronterizos, y estructuras de garantías. Asimismo, dentro de su práctica de Derecho Inmobiliario, Pablo asesora a clientes nacionales y extranjeros en la estructuración y negociación de contratos de fideicomiso, compraventa y arrendamiento de inmuebles."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": [
                    "Licenciado en Derecho - Universidad Iberoamericana (2010)",
                    "Maestría en Derecho – University of California at Berkeley (2015)"]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/8": {
        "link": "/partners/8",
        "image": require('../../assets/equipo/Socios/Biografia/ErnestoSaldate_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
        "vcard": require('../../assets/vcards/Ernesto_Saldate A..vcf'),
        "name": "Ernesto Saldate del Alto",
        "subtitle": "Socio",
        "description": ["Litigio y Resolución de Controversias", "Cumplimiento Regulatorio"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3028",
            "email": "ernesto.saldate@creelabogados.com"
        },
        "p": ["Ernesto es Socio del Despacho. Se especializa en Litigio Civil y Mercantil, y Arbitraje. Su práctica abarca la atención de todo tipo de litigios complejos, ante tribunales locales y federales, incluyendo litigio constitucional, administrativo, civil y mercantil. Asimismo, Ernesto representa clientes en mecanismos alternativos de solución de conflictos (MASC) como mediación y arbitraje, participando en procedimientos ad hoc y ante las principales instituciones administradoras, nacionales y extranjeras. En coordinación con el Área de Práctica de Derecho Corporativo, Ernesto representa a clientes en el diseño e implementación de programas de prevención, gestión y remedición de cumplimiento normativo, así como en procesos de auditoría e investigaciones relacionadas."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Panamericana (2001)",
                   "Especialidad en Derecho Mercantil y Corporativo – Escuela Libre de Derecho (2007)",
                   "Especialidad en Derecho Penal – Escuela Libre de Derecho (2008)", 
                   "Especialidad en Derecho Procesal Civil y Mercantil – Universidad Panamericana (2009)",
                   "Especialidad en Derecho Civil y Mercantil – Universidad Panamericana (2010)"
                   ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• “Shareholders claims against M&A transactions in Mexico”, publicado por Lexology: Getting the Deal Through, Abril 2022.",
                    "• “M&A Litigation in Mexico”, publicado por Lexology: Getting the Deal Through, Abril 2021."
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/partners/9": {
        "link": "/partners/9",
        "image": require('../../assets/equipo/Socios/Biografia/SofiaGomez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Socios/Home/SofiaGomez_Esc.png'),
        "vcard": require('../../assets/vcards/Sofía_Gómez B..vcf'),
        "name": "Sofía Gómez B.",
        "subtitle": "Socia",
        "description": ["Derecho Laboral",
        "Cumplimiento Regulatorio",
        "ASG"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3015",
            "email": "sofia.gomez@creelabogados.com"
        },
        "p": ["Sofía es Socia del Despacho. Su especialidad es el Derecho Laboral. Sofía cuenta con amplios conocimientos y experiencia ejerciendo todas las sub-especialidades del Derecho Laboral, tales como derecho individual y colectivo de trabajo, seguridad social y migratorio. Sofía concentra su práctica en la consultoría laboral, asistiendo a clientes nacionales e internacionales en asuntos relacionados con el manejo y administración de sus relaciones con los empleados y organizaciones sindicales. Asimismo, en coordinación con nuestra área de práctica de Derecho Corporativo, Sofía participa en auditorías en materia laboral relacionada con operaciones de Fusiones y Adquisiciones. Diseña e implementa estrategias de defensa en juicios laborales, así como asesora a clientes en procedimientos de emplazamientos a huelga o paros, incluyendo la negociación con el sindicato, terminación del contrato, y prevención de prácticas ilegales. Sofía asimismo proporciona asesoría en la estructuración de planes de ahorro y de retiro privado, de acciones y cualquier otro incentivo, y en la implementación de estrategias preventivas para el desarrollo productivo de las relaciones de trabajo."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciada en Derecho - Universidad Anáhuac (2011)",
                   "Maestría en Derecho del Trabajo – Escuela Libre de Derecho (2015)",
                   "Maestría en Derecho – Pritzker School of Law, Northwestern University (2019)"
                ]
                }
        ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["• Asociación Nacional de Abogados de Empresa", "• Confederación de Cámaras Industriales de los Estados Unidos Mexicanos", "• Abogadas MX", "• American Bar Association", "• International European Employment Law Alliance"]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/1": {
        "link": "/advisers/1",
        "image": require('../../assets/equipo/Consejeros/Biografia/LuisCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/LuisJCreel_Esc.jpg'),
        "vcard": require('../../assets/vcards/Luis_J. Creel C..vcf'),
        "name": "Luis J. Creel C.",
        "subtitle": "Consejero Honorario",
        "description": [""],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000",
            "email": "luis.creel@creelabogados.com"
        },
        "p": ["Luis es Consejero Honorario y fundador del Despacho, junto con Don Luis J. Creel Luján.", "Luis dirigió el Despacho desde el fallecimiento de su padre y hasta finales de los años 90. Por más de 40 años, Luis enfocó su práctica profesional en las áreas de Derecho Corporativo y Derecho Bursátil, convirtiéndose en uno de los más respetados abogados experto en sus áreas de especialidad, representando a los más relevante grupos empresariales y financieros del país. Fue fundador y Presidente Ejecutivo del Consejo de Administración de IXE Grupo Financiero (ahora parte de Grupo Financiero Banorte), así como miembro del Consejo y de los Comités Ejecutivos de diversas empresas listas en la Bolsa Mexicana de Valores.", "Luis fue fundador y Presidente del Patronato de Fundación Casa Alianza México y actualmente es Vicepresidente del Patronato Ciudad UP-IPADE."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado - Escuela Libre de Derecho (1966)", "Postgrado en Derecho Americano – Princeton University (1966)", "Maestría en Derecho – Harvard University (1966-1967)", "Asociado Extranjero – Hill & Barlow, Boston (1967-1968)", "Programa de Alta Dirección AD-2 – IPADE (1972-1973)", "Postgrado en Derecho Fiscal – Universidad Panamericana (1979)"]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Luis fue Asistente Investigador del Profesor Louis B. Sohn, en la Universidad de Harvard, Profesor Fundador del Área de Derecho en el Instituto Panamericano de Alta Dirección de Empresas (IPADE) y Profesor de Derecho Internacional Privado en la Universidad Iberoamericana."]
                },
        ]
        },
        "affiliations": {
            "title": "Asociaciones",
            "p": ["Barra Mexicana, Colegio de Abogados", "Comité Internacional de la Barra Mexicana de Abogados", "Harvard Alumni Association"]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/2": {
        "link": "/advisers/2",
        "image": require('../../assets/equipo/Consejeros/Biografia/JorgeTorres_esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jorge_Torres B..vcf'),
        "name": "Jorge Torres B.",
        "subtitle": "Consejero Senior",
        "description": ["Derecho Inmobiliario",
        "Derecho Corporativo y Mercantil"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010 ",
            "email": "jorge.torres@creelabogados.com"
        },
        "p": ["Jorge es Consejero Senior del Despacho. Durante su larga trayectoria, Jorge ha desarrollado una práctica de Derecho Inmobiliario excepcional. Ha participado en los asuntos y proyectos inmobiliarios de mayor relevancia en México, representando activamente a importantes grupos mexicanos e internacionales en la adquisición, arrendamiento y desarrollo de propiedades inmobiliarias para los sectores turístico, comercial, industrial y residencial en diversas regiones del país. Asimismo, Jorge cuenta con una amplia experiencia liderando numerosas operaciones de naturaleza corporativa y comercial, incluyendo Fusiones y Adquisiciones, representando a clientes en diversos sectores industriales."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado - Escuela Libre de Derecho (1985)",
                   "Postgrado en Arbitraje Comercial Internacional - Escuela Libre de Derecho (1995)",
                   "Asociado Extranjero - Gardere & Wynne, Dallas (1988)"
                   ]
                },
                {
                    "title": "Publicaciones",
                    "p": ["• Capítulo “México” en PLC Cross-border Corporate Real Estate Handbook, publicado por Practical Law Company, primera a quinta y séptima a novena ediciones (2004/5 a 2007/8, 2010/11 a 2012/13).",
                    "• Capítulo “México” en The Real Estate Law Review, Law Business Research, 2012.",
                    "• “Derecho Inmobiliario, Temas Selectos y Perspectivas del Sector”, publicado por Editorial Porrúa e Instituto Tecnológico Autónomo de México, 2010.",
                    "• “Overview of Mexican Real Estate Investments” en PLC Cross-border Corporate Real Estate Handbook, publicado por Practical Law Company, Cuarta Edición 2009.",
                    "• “Real Estate Investments in Mexico” en PLC Cross-border Corporate Real Estate Handbook, publicado por Practical Law Company, Tercera Edición, 2008."
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/advisers/3": {
        "link": "/advisers/3",
        "image": require('../../assets/equipo/Consejeros/Biografia/AlejandroCreel_Esc.jpg'),
        "image_team": require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
        "vcard": require('../../assets/vcards/Alejandro_Creel Y..vcf'),
        "name": "Alejandro Creel Y.",
        "subtitle": "Consejero",
        "description": ["Derecho Corporativo y Mercantil",
        "Fusiones y Adquisiciones",
        "Derecho Financiero",
        "Derecho Inmobiliario"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 55-1167-3041, ext. 160",
            "email": "alejandro.creel@creelabogados.com"
        },
        "p": ["Alejandro es Consejero del Despacho. Ha desarrollado su práctica en Derecho Corporativo y Derecho Inmobiliario. Alejandro concentra su práctica Corporativa en la asesoría a empresas y fondos nacionales e internacionales en operaciones de Fusiones y Adquisiciones, reestructuras corporativas, co-inversiones (<span style='font-family: Poppins Italic'>joint ventures</span>) y gobierno corporativo. En el campo de Derecho Inmobiliario, Alejandro participa activamente en operaciones inmobiliarias complejas, representando a inversionistas, desarrolladores y operadores en la estructuración de contratos de fideicomiso, compraventa y arrendamiento de inmuebles, así como en la constitución de regímenes de propiedad en condominio, tiempo compartido y propiedad fraccionada."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Iberoamericana (2006)",
                   "Maestría en Derecho – Instituto de Empresa (IE), Madrid, España (2009)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/1": {
        "link": "/associates/1",
        "image": require('../../assets/equipo/Asociados/Biografía/PaulinaAparicio_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/PaulinaAparicio_Esc.jpg'),
        "vcard": require('../../assets/vcards/Paulina_Aparicio L..vcf'),
        "name": "Paulina Aparicio L.",
        "subtitle": "Asociada",
        "description": ["Derecho Laboral"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3031",
            "email": "paulina.aparicio@creelabogados.com"
        },
        "p": ["Paulina se especializa en Derecho del Trabajo tanto individual como colectivo. Asimismo, Paulina asiste y representa a clientes nacionales e internacionales en juicios laborales interpuestos ante las Juntas de Conciliación y Arbitraje y Tribunales laborales tanto a nivel federal y local; así como en conciliaciones prejudiciales sustanciaras ante los Centros de Conciliación; atiende todo tipo de inspecciones de trabajo; lleva a cabo terminaciones laborales; celebra y termina CCT; diseña estrategias para reestructurar grupos corporativos a fin de cumplir con las reglas de subcontratación vigente y en el manejo de relaciones laborales."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciada en Derecho - Universidad Panamericana (2018).",
                    "Maestría en Derecho Laboral Mexicano – Universidad Panamericana (2022)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/13": {
        "link": "/associates/13",
        "image": require('../../assets/equipo/Asociados/Biografía/SebastiandePalma.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/SebastianDePalma_Esc.jpg'),
        "vcard": require('../../assets/vcards/Sebastián_de Palma C..vcf'),
        "name": "Sebastián de Palma C.",
        "subtitle": "Asociado",
        "description": ["Derecho Fiscal"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000, ext. 128",
            "email": "sebastian.depalma@creelabogados.com"
        },
        "p": ["Sebastián se especializa en Derecho Fiscal. Representa a clientes de diversas industrias en consultoría Fiscal derivada de sus operaciones cotidianas, así como en auditorias fiscales, Asimismo, cuenta con experiencia en asesoría transaccional, concentrándose en los aspectos fiscales de operaciones de fusiones y adquisiciones y restructuraciones corporativas y financieras, domésticas y transfronterizas, así como en asuntos de inversión extranjera."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho –Universidad Anáhuac (2021)"]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    }, 
    "/associates/4": {
        "link": "/associates/4",
        "image": require('../../assets/equipo/Asociados/Biografía/Tsen_Esc.jpg'),
        "image_team":  require('../../assets/equipo/Asociados/Home/Tsen_Esc.jpg'),
        "vcard": require('../../assets/vcards/Tsen-Fang Lin_.vcf'),
        "name": "Tsen Fang-Lin",
        "subtitle": "Asociada",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Financiero"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3034",
            "email": "tsen-fang.lin@creelabogados.com"
        },
        "p": ["Lin se especializa en Derecho Corporativo y Financiero. Lin asesora clientes nacionales y extranjeros en asuntos corporativos y mercantiles, incluyendo el mantenimiento diario de sus sociedades en México, la redacción y negociación de una variedad amplia de contratos y la atención de operaciones de Fusiones y Adquisiciones. Asimismo, la práctica de Lin comprende la asesoría en operaciones de financiamientos, incluyendo créditos domésticos y transfronterizos y su correspondiente estructura de garantías."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Abogado – Escuela Libre de Derecho (2019)"]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Mandarín", "• Taiwanés Minnan"]
        }
    },
    "/associates/5": {
        "link": "/associates/5",
        "image": require('../../assets/equipo/Asociados/Biografía/KarlaGrajales_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/KarlaGrajales_Esc.jpg'),
        "vcard": require('../../assets/vcards/Karla_Grajales M..vcf'),
        "name": "Karla Grajales M.",
        "subtitle": "Asociada",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Financiero", "Derecho Inmobiliario", "Cumplimiento Regulatorio"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 3033",
            "email": "karla.grajales@creelabogados.com "
        },
        "p": ["Karla se especializa en Derecho Corporativo. Concentra su práctica en operaciones de Fusiones y Adquisiciones nacionales e internacionales, reestructuraciones corporativas, finanzas corporativas, asuntos societarios y mercantiles en general, cumplimiento regulatorio en materia de inversión extranjera y protección de datos personales, así como en temas de gobierno corporativo. Asimismo, Karla brinda asesoría en la redacción, revisión y negociación de contratos de fideicomiso, compraventa y arrendamiento de inmuebles. Karla cuenta también con experiencia en la coordinación de auditorías legales y en la atención de asuntos regulatorios en materia de salud, protección al consumidor, seguridad y salud en el trabajo, y medio ambiente."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciada en Derecho – Universidad del Tepeyac (2009)", 
                    "Especialidad en Derecho Civil – Universidad Nacional Autónoma de México (2010-2011)", 
                    "Maestría en Derecho Español – Universidad de Salamanca, España (2014)",
                    "Diplomado en Derecho Empresarial – Instituto Tecnológico Autónomo de México (2017)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/6": {
        "link": "/associates/6",
        "image": require('../../assets/equipo/Asociados/Biografía/SandraLopez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/SandraLopez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Sandra_López CH..vcf'),
        "name": "Sandra López Ch.",
        "subtitle": "Asociada",
        "description": ["Derecho Corporativo y Mercantil","Fusiones y Adquisiciones",
            "Derecho Financiero",
            "Cumplimiento Regulatorio"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3022",
            "email": "sandra.lopez@creelabogados.com"
        },
        "p": ["Sandra se especializa en Derecho Corporativo. Sandra asesora clientes nacionales y extranjeros en asuntos corporativos y mercantiles, incluyendo el mantenimiento diario de sus sociedades, y en la elaboración y negociación de una amplia variedad de contratos relacionados con su operación ordinaria. Su práctica abarca la atención de consultas en el cumplimiento regulatorio en materia de inversión extranjera, protección de datos personales, anti-lavado de dinero y anti-corrupción. Sandra asimismo cuenta con experiencia en Derecho Financiero, habiendo participado activamente en una amplia variedad de operaciones de financiamiento local y transfronterizo, así como en la atención de consultas en materia de regulación financiera."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Universidad Iberoamericana (2017)", 
                    "Especialidad y Maestría en Derecho Privado - Universidad Anáhuac (2017–2019)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/7": {
        "link": "/associates/7",
        "image": require('../../assets/equipo/Asociados/Biografía/CarolinaLopez_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/CarolinaLopez_Esc.jpg'),
        "vcard": require('../../assets/vcards/Carolina_López de Llergo P..vcf'),
        "name": "Carolina López de Llergo P.",
        "subtitle": "Asociada",
        "description": ["Derecho del Trabajo", "ASG"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000 ext. 114",
            "email": "carolina.lopez@creelabogados.com"
        },
        "p": ["Carolina se especializa en Derecho Laboral. Carolina concentra su práctica en consultoría laboral. Brinda asesoría legal a clientes nacionales y extranjeros en todo lo concerniente al grado de cumplimiento de las obligaciones laborales, seguridad y salud en el empleo, conciliaciones prejudiciales y extrajudiciales, auditorías laborales relacionadas con operaciones de Fusiones y Adquisiciones, inspecciones de trabajo y diseño e implementación de estrategias laborales para la prevención de conflictos en el lugar de trabajo."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Universidad Anáhuac (2022)."]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Francés"]
        }
    },
    "/associates/8": {
        "link": "/associates/8",
        "image": require('../../assets/equipo/Asociados/Biografía/FernandaMelgar_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/MariaFernandaMelgar_Esc.jpg'),
        "vcard": require('../../assets/vcards/María_Fernanda Melgar G..vcf'),
        "name": "María Fernanda Melgar G.",
        "subtitle": "Asociada",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Financiero", "Competencia Económica"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext.131",
            "email": "fernanda.melgar@creelabogados.com"
        },
        "p": ["Fernanda se especializa en Derecho Corporativo y Derecho Financiero. Fernanda concentra su práctica en operaciones de Fusiones y Adquisiciones, reestructuraciones corporativas, asuntos corporativos y mercantiles en general, el mantenimiento diario de sociedades, incluyendo cumplimiento en materia de inversión extranjera y regulatoria, así como la coordinación de auditorías. Asimismo, Fernanda brinda asesoría en temas regulatorios de Derecho Bancario (<span style='font-family: Poppins Italic'>FinTech</span>) y participa en la estructuración de contratos de crédito y de garantía. Fernanda presta asesoría en asuntos de Competencia Económica, incluyendo la representación de compañías en procesos de notificación de concentraciones ante la Comisión Federal de Competencia Económica."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Iberoamericana (2020)"]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/9": {
        "link": "/associates/9",
        "image": require('../../assets/equipo/Asociados/Biografía/MoisesProchovnik_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/Moises_Esc.jpg'),
        "vcard": require('../../assets/vcards/Moisés_Prochovnik A..vcf'),
        "name": "Moisés Prochovnik A. ",
        "subtitle": "Asociado",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones", "Derecho Financiero"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 122",
            "email": "moises.prochovnik@creelabogados.com"
        },
        "p": ["Moisés se especializa en Derecho Corporativo y Derecho Financiero. Moisés concentra su práctica en operaciones de Fusiones y Adquisiciones, reestructuraciones corporativas, emisiones de valores en bolsa, preparación y negociación de contratos de créditos y asesoría a todo tipo de entidades financieras. Asimismo, brinda asesoría en la estructuración de contratos de fideicomisos y contratos de compraventa de acciones. Cuenta con amplia experiencia en asesorar compañías e instituciones financieras en operaciones bursátiles, incluyendo ofertas privadas y públicas de valores, y en asuntos regulatorios ante la Bolsa Mexicana de Valores, BIVA y la Comisión Nacional Bancaria y de Valores."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación y Experiencia Profesional",
                    "p": ["Abogado – Escuela Libre de Derecho (2017)",
                    "Diplomado en Seguros y Fianzas – Escuela Libre de Derecho (2015)",
                    "Maestría en Derecho – Columbia University (2022)",
                    "Actualmente, colabora como Asociado Extranjero en Winston & Strawn LLP, Nueva York"
                ]
                },
                {
                    "title": "Experiencia Académica",
                    "p": ["Moisés ha sido Profesor Adjunto en la materia de Derecho Bursátil en la Escuela Libre de Derecho."]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Hebreo"]
        }
    },
    "/associates/10": {
        "link": "/associates/10",
        "image": require('../../assets/equipo/Asociados/Biografía/JonathanSegura_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/JonathanSegura_Esc.jpg'),
        "vcard": require('../../assets/vcards/Jonathan_Segura M..vcf'),
        "name": "Jonathan Segura M.",
        "subtitle": "Asociado",
        "description": ["Litigio y Solución de Controversias"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 118",
            "email": "jonathan.segura@creelabogados.com"
        },
        "p": ["Jonathan se especializa en Litigio Civil y Mercantil. Jonathan concentra su práctica en todo tipo de procedimientos y litigio civil, mercantil, administrativo y constitucional, así como en asuntos de mediación, conciliación y otros medios alternos de solución de controversias. Cuenta con experiencia en juicios ordinarios y especiales en las distintas instancias judiciales y administrativas. Asimismo, Jonathan brinda asesoría en elaboración de demandas, ofrecimiento y valoración de pruebas, y medios de impugnación, así como en la elaboración de contratos civiles y mercantiles, incluyendo compraventas, arrendamientos, hipotecas y fideicomisos, y reconocimiento y pago de deudas."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho - Universidad Nacional Autónoma de México (2021)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés"]
        }
    },
    "/associates/11": {
        "link": "/associates/11",
        "image": require('../../assets/equipo/Asociados/Biografía/PabloTorres_Esc.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/PabloTorres_Esc.jpg'),
        "vcard": require('../../assets/vcards/Pablo_Torres A..vcf'),
        "name": "Pablo Torres A.",
        "subtitle": "Asociado",
        "description": ["Derecho Corporativo y Mercantil", "Fusiones y Adquisiciones","Cumplimiento Regulatorio"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3010, ext. 123",
            "email": "pablo.torres@creelabogados.com"
        },
        "p": ["Pablo se especializa en Derecho Corporativo y Mercantil. Pablo concentra su práctica en asuntos corporativos y mercantiles en general, y el mantenimiento diario de sociedades, incluyendo gobierno corporativo y cumplimiento regulatorio en materia de inversión extranjera, protección de datos personales y prevención de lavado de dinero. Cuenta con experiencia en operaciones corporativas, particularmente en Fusiones y Adquisiciones. Asimismo, Pablo brinda asesoría en la coordinación de auditorías corporativas y la estructuración de todo tipo de contratos mercantiles. Pablo cuenta con experiencia en materia de Propiedad Industrial, incluyendo el registro y mantenimiento de derechos de propiedad industrial, así como en auditorías marcarias."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO) (2021)", 
                    "Diplomado en Derecho Corporativo – Instituto Tecnológico Autónomo de México (2022)"
                ]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español", "• Inglés", "• Alemán"]
        }
    },
    "/associates/12": {
        "link": "/associates/12",
        "vcard": require('../../assets/vcards/Rodrigo_Estrada C..vcf'),
        "image": require('../../assets/equipo/Asociados/Biografía/Rodrigo Estrada 2.jpg'),
        "image_team": require('../../assets/equipo/Asociados/Home/RodrigoEstrada1.jpg'),
        "name": "Rodrigo Estrada C.",
        "subtitle": "Asociado",
        "description": ["Derecho Laboral"],
        "contact": {
            "title": "Datos de contacto",
            "location": "Ciudad de México",
            "phone": "+52 (55) 1167-3000 ext. 144",
            "email": "rodrigo.estrda@creelabogados.com"
        },
        "p": ["Rodrigo se especializa en Derecho Laboral. Representa activamente a empresas en consultoría general sobre asuntos laborales, incluyendo contratación y terminación de empleados, transferencias de empleados como consecuencia de compraventas de activos y fusiones, implicaciones laborales de Fusiones y Adquisiciones, así como inspecciones de trabajo implementadas por las autoridades laborales. Asimismo, Rodrigo ha trabajado en la atención de juicios laborales, procesos de huelga, despidos masivos de empleados y en la redacción y negociación de contratos colectivos de trabajo."],
        "qualifications": {
            "title": "Trayectoria",
            "items":[ 
                {
                    "title": "Educación",
                    "p": ["Licenciado en Derecho – Tecnológico de Monterrey (2020)", "Diplomado en Compliance - Escuela Libre de Derecho (2023)"]
                }
        ]
        },
        "languages": { 
            "title": "Idiomas",
            "p": ["• Español"]
        }
    }
}
export default es