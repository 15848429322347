import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../../assets/sidebar/Logo'
import Insta from '../../assets/common/Insta'
import Twitter from '../../assets/common/Twitter'
import Fb from '../../assets/common/Fb'
import Search from '../../assets/common/Search'
import { Link } from 'wouter'


function Sidebar({ items, onclose }){
    return(
        <div className='bg-[#131313] sm:w-[671px] w-screen h-screen shadow-2xl sm:p-[24px] p-0'>
            <div className='w-full h-full flex sm:justify-start justify-center items-center flex-col'> 
                <div className='flex mt-[35px] sm:w-full justify-center relative w-[57px]'> 
                    <Logo /> 
                    <div className='text-[#fff] absolute right-0 text-4xl cursor-pointer hidden sm:block' onClick={onclose}>X</div>
                </div>
                <ul className='text-[#fff] flex justify-center items-center flex-col my-[20px] font-Poppins-Light'>
                    {
                        items.map(item => 
                            <li className='my-[27px]'>
                                <Link href={item.link}> <p className='cursor-pointer'>{item.value}</p></Link>
                            </li> )
                    }
                </ul>
                <div className='my-[50px] invisible'>
                    <div className="flex items-center">
                    <input
                        type="email"
                        className="w-4/5 h-[49px] sm:w-auto px-4 py-2 rounded-l border border-solid border-[#A79879] bg-[#131313] text-[#fff] focus:outline-none focus:border-black"
                        placeholder=""
                    />
                    <button className="w-1/5 h-[49px] sm:w-auto px-4 py-2 rounded-r bg-[#131313] text-black font-medium border border-solid border-[#A79879]">
                        <div className='w-[16px]'>
                            <Search className='w-[16px] h-[16px]' fill="#fff"/>
                        </div>
                    </button>
                    </div>
                </div>
                <div className='flex w-[100px] justify-between'>
                   <a href='https://ar.linkedin.com/company/creel-abogados-s-c-' target="a_blank"> <Insta /> </a> <Twitter/>
                </div>
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    items: PropTypes.array,
    onclose: PropTypes.func
}

export default Sidebar;