import React, { useState } from 'react';
import { useLocation } from "wouter";
import PropTypes from 'prop-types'
import useSlider from '../../hooks/slider'
import Button from '../common/Button';
import ArrowLeft from '../../assets/common/ArrowLeft';
import ArrowRight from '../../assets/common/ArrowRight';


function HeroSlider({ items }) {
  const [isHovering, setIsHovering] = useState(false)
  const [location, setLocation] = useLocation();
  const slider = useSlider(items)

  const handleMouseHover = () => {
    setIsHovering(!isHovering)
  }
  const navigate = (url) => {
      setLocation(url);
    };

    return (
      <section className='flex justify-center items-center'>
        <div className='flex items-center'>
          <div className='lg:mr-[85px] mr-0'> 
            <Button click={slider.handlePrevious} hover="lg:hover:bg-[#D1C5B2] hover:none" backgroundColor="lg:bg-primary bg-none" width="w-[50px] h-[50px] flex justify-center items-center" border="lg:rounded-[8px] lg:border-solid lg:border-2 lg:border-[#fff] border-none">
            <div className='lg:w-[9px] w-[16px]'>
              <ArrowLeft fill='#fff'/>
            </div>
            </Button> 
          </div>
            <div className='bg-[#000] bg-opacity-[.64] lg:max-w-[682px] lg:w-[682px] w-[280px] lg:h-[202px] h-[260px] flex items-center flex-col'>
              <p className='block lg:hidden text-[#fff] mt-[28px] text-[28px]'>Creel/abogados</p>
              <p style={{fontFamily: 'Poppins Extra Light'}} className='text-[#fff] lg:text-[30px] text-[24px] lg:my-[34px] mb-[20px] text-center'>{items[slider.currentSlide].title}</p>
              <div onMouseOut={handleMouseHover} onMouseOver={handleMouseHover}>
                <Button 
                  click={() => navigate(items[slider.currentSlide].link)}
                  width="min-w-[164px]" height="h-[48px]" hover="hover:bg-[#D1C5B2] hover:text-[#714061]" padding="px-[9px]"> 
                  {isHovering ? items[slider.currentSlide].button_hover : items[slider.currentSlide].button } 
                  </Button>
              </div>
            </div>
          <div className='lg:ml-[85px] ml-0'> 
            <Button click={slider.handleNext} hover="lg:hover:bg-[#D1C5B2] hover:none" backgroundColor="lg:bg-primary bg-none" width="w-[50px] h-[50px] flex justify-center items-center" border="lg:rounded-[8px] lg:border-solid lg:border-2 lg:border-[#fff] border-none">
              <div className='lg:w-[9px] w-[16px]'>
                  <ArrowRight fill='#fff'/>
                </div>
            </Button> 
          </div>
        </div>
      </section>
    );
  }

  HeroSlider.propTypes = {
    items: PropTypes.array
  }
  export default HeroSlider;
