import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext'
import MainLayout from '../../Layout/MainLayout'
import Accordion from '../../components/AreasPractica/Accordion'
import bg from '../../assets/practica/bg_Header.jpg'

import esLang from '../../translations/areas/es.js'
import enLang from '../../translations/areas/en.js'

function AreasPractica(){
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang

    const [currentIndex, setCurrentIndex] = useState(-1)

    function anchorId(index){
        let anchor;
        switch(index){
        case 0 :
            anchor = 'corporate-and-commercial-law'
            break;
        case 1:
            anchor = 'finance'
            break;
        case 2: 
            anchor = 'tax'
            break;
        case 3: 
            anchor = 'labor-and-employment'
            break;
        case 4:
            anchor = 'real-estate'
            break;
        case 5:
            anchor = 'antitrust-and-competition'
            break;
        case 6:
            anchor = 'natural-resources'
            break;
        case 7:
            anchor = 'litigation-and-dispute-resolution'
            break;
        case 8:
            anchor = 'esg'
            break;
        case 9:
            anchor = 'compliance'
            break;
        default: 
            anchor = ''
        }
        return anchor
    }

    useEffect(() => {
        const { hash } = window.location
        const decodedHash = decodeURI(hash).replace('#', '')
        let element = document.getElementById(decodedHash)
        if(hash) {
             const active = translation.accordion.reduce((idx, acc, index) => {
                if(anchorId(index) === decodedHash) {
                    idx.push(index)
                }
                return idx
               }, [])
        setCurrentIndex(active[0])
        element.scrollIntoView({behavior: "smooth"})
        }
        else {
          window.scrollTo(0, 0);
        }
      }, []);

    const toggleAccordion = (idx) => {
       if(currentIndex === idx){
        setCurrentIndex(-1)
       }
       else {
        setCurrentIndex(idx)
       }
      };

    return (
        <MainLayout>
            <div className='w-screen md:max-h-[429px] h-screen md:h-full'>
                <div className='w-full h-full relative'>
                    <div className='bg-[#000] absolute bg-opacity-[.69] h-full w-full'/>
                    <img src={bg} className='object-cover w-full md:max-h-[429px] h-full'></img>
                    <div className='w-full h-full flex justify-center items-center absolute top-0 md:p-[95px] p-[30px]'>
                        <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] lg:text-[30px] text-[26px] text-center'>{translation.header}</p>
                    </div>
                </div>
            </div>
            <div className='w-screen md:h-[400px] md:p-[90px] p-[40px] flex flex-col justify-center items-center'>
                <p style={{fontFamily: 'Merriweather Bold'}} className='text-primary md:text-[40px] text-[28px] text-center'>{translation.title}</p>
                <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] md:text-[40px] text-[20px] text-center'>{translation.subtitle}</p>
            </div>
            <div className='md:p-[60px]'>
                {
                    translation.accordion.map((acc, index) => <Accordion title={acc.title} content={acc} index={index} currentIndex={currentIndex} toggleAccordion={toggleAccordion} anchor={anchorId(index)}/>)
                }
            </div>
        </MainLayout>
    )
}

export default AreasPractica;