import React, { useState } from 'react';
import Input from '../../components/common/Input';
import LogoMobile from '../../assets/sidebar/Logo'
import LogoCreelColor from '../../assets/home/LogoCreelColor';
import { useLocation } from "wouter";
import { postData } from '../../utils/form'


const FormContainer = ({items}) => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [correo, setCorreo] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefono, setTelefono] = useState('');
  const [pais, setPais] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [statusMsg, setStatusMsg] = useState('')

  const [isNombreInvalid, setNombreInvalid] = useState(false);
  const [isApellidoInvalid, setApellidoInvalid] = useState(false);
  const [isCorreoInvalid, setCorreoInvalid] = useState(false);
  const [isTelefonoInvalid, setTelefonoInvalid] = useState(false);
  const [termns, setTermns] = useState(false)
  const disabledButton = nombre && apellido && correo && telefono && comentarios && termns

  const [location, setLocation] = useLocation();

  const navigate = (url) => {
    setLocation(url);
  };

  const handleSubmit = () => {
    if (!nombre || !apellido || !correo || !telefono) {
      setNombreInvalid(!nombre);
      setApellidoInvalid(!apellido);
      setCorreoInvalid(!correo);
      setTelefonoInvalid(!telefono)
      return; 
    }
    const body = {
      name: `${nombre} ${apellido}`,
      email: correo,
      telephone: telefono,
      country: pais,
      company: empresa,
      comments: comentarios
    }
    postData(body)
    .then(response => response.status === 200 ? (setStatusMsg(response.data.msg), setNombre(''), setApellido(''), setCorreo(''), setEmpresa(''), setTelefono(''), setPais(''), setComentarios(''), setTermns(false)) 
        : setStatusMsg(response.data.message))
  };

  return (
    <div id='contact' className='w-screen h-full flex flex-col sm:flex-row'>
        <div className='xl:w-2/6'>
            <div className='block xl:hidden w-[74px] h-[74px]'>
                <LogoMobile/>
            </div>
            <div className='w-full h-full pt-[40px] hidden sm:block'>
                <div className='w-[448px] h-[448px] flex justify-center'>
                    <LogoCreelColor width={290} height={290} />
                </div>
            </div>
        </div>
        <div className='xl:pl-[40px] p-0'>
            <div className='flex flex-col xl:justify-start justify-center xl:items-baseline items-center'>
                <p style={{fontFamily: 'Merriweather Bold'}} className='text-primary xl:text-[40px] text-[30px] pt-[30px] px-[20px] xl:px-0'>{items.text.title}</p>
                <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] xl:text-[28px] text-[18px] max-w-[900px] text-left mb-[15px] mt-[20px] px-[20px] xl:px-0 xl:w-auto w-full'>{items.text.subtitle}</p>
                <p style={{fontFamily: 'Poppins'}} className='text-[#7F7F7F] xl:text-[18px] text-[14px] mb-[15px] mt-[20px] px-[20px] xl:px-0 xl:w-auto w-full'>{items.text.description}</p>
                <p style={{fontFamily: 'Poppins'}} className='xl:w-auto w-full px-[20px] xl:px-0 xl:text-[14px] text-[9px]'>*{items.text.fields}</p>
            </div>
                <form onSubmit={handleSubmit} className="flex flex-col items-start mb-[80px]">
                    <div className='xl:max-w-[820px] px-[40px] xl:p-0 w-full flex xl:flex-wrap xl:flex-row flex-col items-center justify-between'>
                        <div className='w-full xl:w-[404px] my-[20px]'>
                            <Input
                            type="text"
                            placeholder={`*${items.fields.name}`}
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            required
                            isInvalid={isNombreInvalid}
                        />
                        </div>
                        <div className='w-full xl:w-[404px] my-[20px]'>
                            <Input
                                type="text"
                                placeholder={`*${items.fields.lastname}`}
                                value={apellido}
                                onChange={(e) => setApellido(e.target.value)}
                                required
                                isInvalid={isApellidoInvalid}
                            />
                        </div>
                        <div className='w-full xl:w-[404px] my-[20px]'>
                            <Input
                                type="email"
                                placeholder={`*${items.fields.email}`}
                                value={correo}
                                onChange={(e) => setCorreo(e.target.value)}
                                required
                                isInvalid={isCorreoInvalid}
                            />
                        </div>
                        <div className='w-full xl:w-[404px] my-[20px]'>
                            <Input
                                type="text"
                                placeholder={items.fields.company}
                                value={empresa}
                                onChange={(e) => setEmpresa(e.target.value)}
                            />
                         </div>
                         <div className='w-full xl:w-[404px] my-[20px]'>
                            <Input
                                type="tel"
                                placeholder={`*${items.fields.phone}`}
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)}
                                required
                                isInvalid={isTelefonoInvalid}
                            />
                            </div>
                            <div className='w-full xl:w-[404px] my-[20px]'>
                                <Input
                                    type="text"
                                    placeholder={items.fields.country}
                                    value={pais}
                                    onChange={(e) => setPais(e.target.value)}
                                />
                            </div>
                    </div>
                    <div className='flex flex-col xl:items-start items-center justify-center w-full max-w-[820px] px-[40px] xl:p-0'>
                        <textarea
                            placeholder={items.fields.comments}
                            value={comentarios}
                            onChange={(e) => setComentarios(e.target.value)}
                            className="xl:w-full w-full h-[140px] my-[15px] border border-primary rounded px-4 py-2 text-primary focus:outline-none focus:border-primary placeholder-primary::placeholder">
                            </textarea>
                            <div className="flex w-full xl:justify-start justify-center">
                                <input onChange={() => setTermns(!termns)} checked={termns} className="mr-2 w-[22px] h-[22px]" type="checkbox"/>
                                <label onClick={() => {navigate('/privacy-notice')}} className="md:w-2/3 flex underline cursor-pointer">
                                <span className="text-xl text-[#707070]">
                                    {items.text.termns}
                                </span>
                                </label>
                            </div>
                            <button disabled={!disabledButton} onClick={handleSubmit} type="button" className="w-[211px] h-[51px] bg-primary text-[#FFF] rounded px-4 py-2 mt-4 disabled:opacity-50">
                              {items.text.button}
                            </button>
                            <p style={{fontFamily: 'Poppins Medium'}} className='text-[16px] text-primary mt-[20px]'> {statusMsg} </p>
                    </div>
            </form>
        </div>
    </div>
  );
};

export default FormContainer;
