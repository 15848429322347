import React from 'react'
import Button from '../../components/common/Button'
import { useLocation } from "wouter";

function BannerContainer({items}){
    const [location, setLocation] = useLocation();
    const navigate = (url) => {
        setLocation(url);
      };
    return (
        <div className='w-screen sm:p-[30px] flex justify-center'>
            <div className='bg-[#E4E4E4] w-full flex flex-col sm:px-[110px] py-[56px] px-[30px] items-center justify-center'>
                <p style={{fontFamily: 'Merriweather Bold'}} className='text-primary text-center text-[30px]'>{items.title}</p>
                <p style={{fontFamily: 'Poppins Light'}} className='text-[#131313] text-[18px] pt-[30px] pb-[60px] text-center'>{items.subtitle}</p>
                <Button click={() => navigate('/our-history')} width='w-[254px]' backgroundColor='bg-[#714061]' hover='hover:none'>{items.button}</Button>
            </div>
        </div>
    )
}

export default BannerContainer