import React from 'react'

function ExpertosContainer({ items }){
    return(
        <div className='w-screen bg-[#fff] flex flex-col items-center justify-center py-[45px]'>
            <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-primary sm:text-[40px] text-[30px] m-1 text-center sm:text-start px-[20px] sm:py-[45px] sm:px-0'> {items.title} </h1>
            <div style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1T5B2] sm:text-[28px] text-[20px] text-center sm:text-start px-[20px] sm:p-0'>
                <p className='text-center'>{items.subtitle}</p>
                <p className='text-center'>{items.subtitle2}</p>        
            </div>
            <div style={{fontFamily: 'Poppins Light'}} className='text-[#7f7f7f] sm:text-[28px] text-[18px] pt-[45px] pb-[90px] px-[20px] sm:px-0'>
                <p className='text-center'>{items.text}</p>
                <p className='text-center'>{items.text2}</p>
            </div>
        </div>
    )
}
export default ExpertosContainer;