import React, { useState } from 'react';
import ArrowDown from '../../assets/common/ArrowDown'

const Accordion = ({ title, content, currentIndex, index, toggleAccordion }) => {

  return (
    <div className="border-b border-[#D1C5B2]">
      <div
        className="flex items-center justify-left p-4 cursor-pointer"
        onClick={() => toggleAccordion(index)}
      > 
        <div className={`p-[10px] w-[41px] h-[41px] mr-[35px] rounded-full bg-primary transform transition-transform ${
                currentIndex === index ? 'rotate-180' : 'rotate-0'
            }`}>
            <ArrowDown width="21px" fill="#D1C5B2" />
        </div>
        <h3 style={{fontFamily: 'Poppins'}} className="sm:text-[25px] text-[18px] text-[#131313]">{title}</h3>
      </div>
      {currentIndex === index && (
        <div className="p-4">
          <p style={{fontFamily: 'Poppins Light'}} className="text-[#666666] px-[60px]">{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
