const es = {
    timeline: {
        name: "Línea de Tiempo",
        items:  ["1936", "1950", "1955", "1972", "1977", "2000", "Hoy"]
    },
    subtitle: "Nos sentimos orgullosos de nuestra historia y valoramos nuestra tradición y sólida cultura construida durante más de cinco décadas.",
    title: "Creel/abogados: un legado de excelencia y tradición",
    t1: {
        title: "1936: Luis J. Creel Luján",
        description: ["Las raíces de <span style='font-family: Poppins Medium'>Creel/abogados</span> parten de la visión de Don Luis J. Creel Luján, quien el 22 de julio de 1936 recibió su título profesional de la Universidad Nacional Autónoma de México. Don Luis tuvo sus primeros acercamientos a la profesión de abogado en la reconocida firma de Don Carlos Sánchez Mejorada Domínguez. Bajo la supervisión y dirección de su mentor, Don Luis centró su práctica profesional en la representación de empresas inglesas, canadienses y americanas, que mantenían importantes participaciones en la industria minera mexicana.", "Durante los años siguientes, Don Luis se desplaza a la ciudad de Pachuca para atender los asuntos legales de Real del Monte, uno de los grupos mineros de mayor tradición y relevancia en el país.", "A partir de su regreso a la Ciudad de México y durante los años 40, Don Luis continúa la representación de inversionistas extranjeros y se involucra activamente en la Barra Mexicana, Colegio de Abogados, siendo miembro de varias comisiones, publicando diversos artículos en materia fiscal -su área predilecta de análisis doctrinal- y actuando como Director de la Revista “El Foro”."],
        image: [require('../../assets/historia/img_1936.jpg')],
        image_description: "Don Luis J. Creel Luján en su oficina del Piso 18 del Edificio del Banco Internacional.",   
    },
    t2: {
        title: "1950",
        description: ["A partir de los años 50, Don Luis inicia su práctica independiente en una modesta oficina ubicada en Avenida Juárez, dentro del primer cuadro de la Ciudad de México, enfocando sus servicios en la atención de asuntos mineros, fiscales y de inversión extranjera."],
        image: [require('../../assets/historia/img_1950-1.jpg'), require('../../assets/historia/img_1950-2.jpg'),]
    },
    t3: {
        title: "1955 - 1966",
        description: ["Don Luis mantuvo la sede original de su práctica hasta 1955, cuando muda sus oficinas a Paseo de la Reforma 122, desde donde continuó por más de una década asesorando a compañías extranjeras en sus negocios en México, con el apoyo de su incondicional asociado, el Lic. Alberto Hoyos Tello. En el año de 1966, tras un desafortunado incendio en su sede que destruye no solo sus instalaciones, sino una buena parte de los expedientes conservados en el archivo, Don Luis lleva a cabo un nuevo cambio de oficinas al entonces imponente Edificio del Banco Internacional, localizado en Paseo de la Reforma 156."],
        image: [require('../../assets/historia/img_1955-1.jpg'), require('../../assets/historia/img_1955-2.jpg')]
    },
    t4: {
        title: "1972: Creel/abogados",
        description: ["La práctica de Don Luis se fortalece con la incorporación de su hijo Luis J. Creel Carrera, con quien en el año de 1972 funda <span style='font-family: Poppins Medium'>Creel/abogados </span>, estableciendo tres años después la nueva sede del Despacho en el Edificio Desc, dentro de la vanguardista zona de Bosques de las Lomas."],
        image: [require('../../assets/historia/img_1972-1.jpg'), require('../../assets/historia/img_1972-2.jpg')]
    },
    t5: {
        title: "1977 - 1999",
        description: ["Don Luis fallece en 1977 y su hijo Luis asume el liderazgo de la firma familiar e inicia su institucionalización transformando <span style='font-family: Poppins Medium'>Creel/abogados</span> en una firma corporativa.", "Luis crece la estructura societaria invitando a Samuel García-Cuellar S. y Carlos Müggenburg R.V., entre otros destacados abogados, y amplía la plataforma de servicios legales, incorporando las Áreas de Práctica de Derecho Bancario y Bursátil, así como de Derecho Fiscal e Inmobiliario, logrando ubicar al Despacho, en las siguientes dos décadas, dentro del nivel más alto de las firmas corporativas del país. Luis se mantiene al frente del Despacho hasta finales de los años 90."],
        image: [require('../../assets/historia/img_1977-1.jpg'), require('../../assets/historia/img_1977-2.jpg')]
    },
    t6: {
        title: "2000 - 2013",
        description: ["En el año 2000, Carlos Creel Carrera es nombrado Socio Director y con su liderazgo durante los siguientes años nuestros socios desarrollaron sus prácticas profesionales en las áreas estructurales del Despacho, formaron un sólido equipo de abogados que comparten su visión y valores, y consolidaron su estrategia de cobertura local e internacional, estableciendo estrechas relaciones con reconocidas firmas de abogados y asesores en América y Europa. Desde el 2013 Carlos se desempeña como Socio Senior de <span style='font-family: Poppins Medium'>Creel/abogados.</span>"],
        image: [require('../../assets/historia/EdificioMerida2.jpg'), require('../../assets/historia/NH-00-13_escritorio.jpg')]
    },
    t7: {
        title: "2015 - hoy",
        description: ["Durante las primeras décadas de este nuevo siglo XXI, <span style='font-family: Poppins Medium'>Creel/abogados</span> ha mantenido su dedicada atención a representar a grupos empresariales y financieros, fondos de inversión y desarrolladores inmobiliarios nacionales y extranjeros; ampliando su oferta de servicios legales especializados para enfocarse en nuevas Áreas de Práctica surgidas por el avance de la tecnología financiera y comercial, así como por el crecimiento de modelos de negocio innovadores.", "Reconociendo la dinámica y profunda transformación de nuestro entorno, impulsamos el fortalecimiento de nuestra práctica de Derecho Laboral, Recursos Naturales, Cumplimiento Regulatorio y ASG, de la mano de la incorporación de talentosos socios, consejeros y asociados.", "En el año 2021, establecimos nuestra Oficina en Mérida, ciudad que se ha convertido en el más relevante centro de negocios del sureste de México. Nuestra Oficina en Mérida nos ha permitido ampliar nuestra oferta de servicios legales especializados para atender las necesidades de asesoría jurídica de inversionistas locales e internacionales, con un enfoque en operaciones y proyectos Corporativos, incluyendo Fusiones y Adquisiciones, Inmobiliarios, Laborales y Financieros."],
        image: [require('../../assets/historia/NH-2015Retrato_escritorio.jpg'),require('../../assets/historia/img_2015.jpg')],
        image_description: "Retrato de Don Luis J. Creel Luján presidiendo nuestra Sala de Consejo en el Piso 29 de nuestras oficinas en el complejo de oficinas Arcos."
    },
    t8: {
        title: "Hoy",
        description: ["<p style='text-align:center'>En esta segunda década del siglo XXI, celebrando nuestro 50° Aniversario y establecidos en la actual sede de <span style='font-family: Poppins Medium'>Creel/abogados</span> en el complejo de oficinas Arcos, continuamos brindando asesoría jurídica corporativa, con la calidad y profundidad que nos ha caracterizado durante nuestra historia, sustentada en el legado de excelencia y profesionalismo inculcado por nuestros fundadores originales, y manteniendo vigentes el espíritu y la cultura que durante décadas nos han distinguido en el foro mexicano.</p>", "<p style='text-align:center'>Nuestra visión nos mantiene a la vanguardia de la transformación de la industria legal para atender los requerimientos de un mundo cada vez más complejo, dinámico y conectado.</p>"],
        image: [require('../../assets/historia/hoy.jpg')]
    }
}

export default es;