import React from 'react'
import sustentabilidad from '../../assets/despacho/sustentabilidad/ND-Sustentabilidad_escritorio.jpg'

function Sustentabilidad ({items}){
    return (
        <div id="sustainability" className='bg-primary w-screen h-full flex justify-center items-center flex-col py-[90px] px-[30px] sm:px-0'>
            <div className='mb-[50px]'>
                <img className='w-[167px] sm:w-auto' src={sustentabilidad}/>
            </div>
            <div className='max-w-[590px] flex justify-center items-center flex-col'>
                <h1 style={{fontFamily: 'Merriweather Bold'}} className="sm:text-[40px] text-[30px] text-[#fff] pb-[30px]">{items.title}</h1>
                <p style={{fontFamily: 'Merriweather Italic Bold'}} className="sm:text-[30px] text-[18px] text-[#D1C5B2] pb-[30px] text-center">{items.subtitle}</p>
            </div>
            <div className='max-w-[920px]'>
                <div style={{fontFamily: 'Poppins Light'}} className='text-[#fff] text-[16px] text-center'>
                        <p className='pb-[30px]'>{items.p1}</p>
                        <p>{items.p2}</p>
                </div>
            </div>
        </div>
    )
}
export default Sustentabilidad;