const es = {
    title: ["Consulta y descarga nuestras Notas Jurídicas y Artículos", "para conocer y estar actualizado en temas legales relevantes en México."],
    highlight:{
        title: "Regulación FinTech en Latinoamérica segunda edición.",
        image: require('../../assets/recursos/Nota1.jpg'),
        description: {
            title: "Publicación:",
            name: "Llorenda Camacho",
            extra: "Con las contribuciones de Creel Abogados, S.C.",
            button: "LEER MÁS"
        }
    },
    articles: {
        title: "Artículos",
        more: "Ver todo",
        items: [
            {
                title: "Regulación FinTech en Latinoamérica - Tercera Edición 2022",
                description: "Nos complace presentar la segunda edición de “Regulación FinTech en Latinoamérica”",
                more: "Leer más",
                date: "2022",
                link: require('../../assets/recursos/articulos/Regulación Fintech Latinoamérica - Tercera Edición 2022.pdf')
            }
        ]
    },
    notes: {
        title: "Notas Jurídicas",
        more: "Ver todo",
        items: [
            {
                title: "Reforma Regulación de la Subcontratación Laboral",
                description: "El 23 de abril de 2021, se publicó en el Diario Oficial de la Federación (“DOF”), el Decreto(“Decreto”) por medio del...",
                more: "Leer más",
                date: "26/04/2021",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Jurídica - Reforma Regulación de la Subcontratación Laboral Abril 2021.pdf')
            },
            {
                title: "Ampliación Plazos Reforma de Subcontratación Laboral",
                description: "El 31 de julio de 2021, se promulgó en la edición vespertina del Diario Oficial de la Federación el Decreto que modifica...",
                more: "Leer más",
                date: "31/07/2021",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Jurídica - Ampliación de Plazos Reforma de Subcontratación Julio 2021.pdf')
            },
            {
                title: "Lineamientos para la Continuidad Saludable de las Actividades Económicas",
                description: "La Secretaría de Salud, la Secretaría del Trabajo y Previsión Social y la Secretaria de Economía, de forma...",
                more: "Leer más",
                date: "10/12/2022",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Lineamientos para la Continuidad Saludable de las Actividades Económicas Octubre 2022.pdf')
            },
            {
                title: "Acuerdo STPS Subcontratación Industria Hotelera",
                description: "El 12 de diciembre de 2022, fue publicado en el Diario Oficial de la Federación, el acuerdo por medio del cual...",
                more: "Leer más",
                date: "12/12/2022",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Acuerdo STPS Subcontratación Industria Hotelera Diciembre 2022.pdf')
            },
            {
                title: "Reforma a la LFT en Materia de Vacaciones",
                description: "El 14 de diciembre de 2021, el Pleno del Senado aprobó la iniciativa de reforma a los Artículos 76 y 78 de la Ley Federal del Trabajo (“LFT”)...",
                more: "Leer más",
                date: "14/12/2022",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Jurídica - Reforma a la LFT en Materia de Vacaciones Diciembre 2022.pdf')
            },
            {
                title: "Decreto Cierre Aeropuerto para Carga",
                description: "El 3 de febrero de 2023, la Secretaría del Trabajo y Previsión Social (la “STPS”), en ejercicio de sus funciones, publicó en el Diario Oficial de la Federación...",
                more: "Leer más",
                date: "02/02/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Decreto Cierre Aeropuerto para Carga Febrero 2023.pdf')
            },
            {
                title: "Acuerdo Modificatorio Lineamientos REPSE",
                description: "El 3 de febrero de 2023, la Secretaría del Trabajo y Previsión Social (la “STPS”), en ejercicio de sus funciones, publicó en el Diario Oficial de la Federación...",
                more: "Leer más",
                date: "03/02/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Acuerdo Modificatorio Lineamientos REPSE Febrero 2023.pdf')
            },
            {
                title: "Programa de Verificación Voluntaria",
                description: "La Secretaría del Trabajo y Previsión Social (la “Autoridad Laboral”) publicó el pasado 20 de abril de 2023, los “Lineamientos Generales de Operación del...",
                more: "Leer más",
                date: "24/04/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Programa de Verificación Voluntaria Abril 2023_1.pdf')
            },
            {
                title: "Iniciativa de Reforma a la LGSM – Asambleas Telemáticas ",
                description: "El 27 de abril de 2023, las Comisiones Unidas de Economía y de Estudios Legislativos de la Cámara de Senadores, aprobaron la Minuta con...",
                more: "Leer más",
                date: "27/04/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Iniciativa de Reforma a la LGSM - Asambleas Telemáticas Abril 2023.pdf')
            },
            {
                title: "Renovación y Cancelación REPSE",
                description: "La Secretaría del Trabajo y Previsión Social (“STPS”) tiene programadas para el resto de 2023 aproximadamente 42,000 inspecciones...",
                more: "Leer más",
                date: "24/05/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Renovación y Cancelación REPSE Mayo 2023.pdf')
            },
            {
                title: "Beneficiario Controlador",
                description: "El 1 de enero de 2022 el Código Fiscal de la Federación (“Código Fiscal”) adicionó los Artículos 32-B Ter, 32-B Quáter y 32-B Quinquies...",
                more: "Leer más",
                date: "2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Jurídica - Beneficiario Controlador Junio 2023.pdf')
            },
            {
                title: "NOM037 Teletrabajo",
                description: "De acuerdo con lo establecido en el Artículo Transitorio Segundo del Decreto de reforma a la Ley Federal del Trabajo (“LFT”) en materia...",
                more: "Leer más",
                date: "2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Jurídica - NOM037 Teletrabajo Junio 2023.pdf')
            },
            {
                title: "Ventanilla Única para Inversionistas y Registro Único de Proyectos de Inversión",
                description: "El 26 de julio de 2023, se publicó en el Diario Oficial de la Federación el Decreto Presidencial (el “Decreto”)...",
                more: "Leer más",
                date: "27/07/2023",
                link: require('../../assets/recursos/notas/Creel Abogados - Nota Informativa - Ventanilla Única para Inversionistas y Registro Único de Proyectos de Inversión Julio 2023.pdf')
            }
        ]
    }
}

export default es;