import React from 'react'

const Input = ({ type, placeholder, value, onChange, required, isInvalid }) => {
    return (
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          className={`w-full h-[53px] border border-primary rounded px-4 py-2 text-primary focus:outline-none focus:border-primary`}
        />
      );
  };

export default Input;