import React, { useContext } from 'react';
import ArrowLeft from '../../assets/common/ArrowLeft'
import ArrowRight from '../../assets/common/ArrowRight'
import { LanguageContext } from '../../context/LanguageContext'

const Pagination = ({ totalItems, itemsPerPage, onPageChange, currentPage }) => {
 const { language } = useContext(LanguageContext);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevClick = () => {
    onPageChange('prev');
  };

  const handleNextClick = () => {
    onPageChange('next');
  };

  return (
    <div className="flex">
      <button className='cursor-pointer' onClick={handlePrevClick} disabled={currentPage === 1}>
        <ArrowLeft fill='#5D2648' width='22px' height='22px' />
      </button> 
      <p style={{fontFamily: 'Poppins Medium'}} className='text-[#666666] sm:text-[16px] text-[14px]'> {language === 'es' ? 'Página' : 'Page'} {currentPage} {language === 'es' ? 'de': 'of'} {totalPages}</p>
      <button className='cursor-pointer' onClick={handleNextClick} disabled={currentPage === totalPages}>
         <ArrowRight fill='#5D2648' width='22px' height='22px' />
      </button>
    </div>
  );
};

export default Pagination;
