import React from 'react'
import PropTypes from 'prop-types'
import ShowMore from '../../components/Home/ShowMore'

function NegociosContainer({ items }){
    return (
        <section id='practice-areas' className='w-screen h-full bg-[#fff] sm:px-[60px] p-[15px] md:h-full lg:pb-[65px]'>
            <div className='flex flex-col justify-center items-center'>
                <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-primary sm:text-[40px] text-[30px] mb-[32px] mt-[32px] text-center'>{items.text.title}</h1>
                <h2 style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] sm:text-[28px] text-[20px] max-w-[900px] text-center mb-[15px]'>{items.text.subtitle}</h2>
                <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] max-w-[1058px] sm:text-[18px] text-[16px] mt-[32px]'>
                    <p className='mb-[32px] text-center'>{items.text.p1}</p>
                    <p className='text-center'>{items.text.p2}</p>
                </div>
                <div className='flex sm:flex-row flex-col max-w-[1200px] flex-wrap w-full sm:justify-between sm:mt-[45px] mt-0 items-center'>
                   {items &&
                    items.showMore.map(item => <ShowMore key={item.text} items={item}/>)
                   }
                </div>
            </div>
        </section>
    )
}

NegociosContainer.propTypes = {
    items: PropTypes.object
}
export default NegociosContainer;