import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types'
import { useTransition } from 'transition-hook'
import Footer from '../components/common/Footer'
import Hamburger from '../components/common/Hamburger';
import Sidebar from '../components/common/Sidebar';

import { LanguageContext } from '../context/LanguageContext'
import esSidebar from '../translations/Sidebar/es.json'
import enSidebar from '../translations/Sidebar/en.json'
import esFooter from '../translations/footer/es.json'
import enFooter from '../translations/footer/en.json'

function Layout({ showLanguage, showLanguageWhite, children, noScrollNavbar, noFooter, modal, noNavbar }) {
  const [sidebar, setSidebar] = useState(false)
  const { stage, shouldMount } = useTransition(sidebar, 0)
  const { language, changeLanguage } = useContext(LanguageContext);
  const normalClass = `${showLanguageWhite ? 'text-[#fff]' : 'text-[#415C87]' } mr-[5px] font-medium cursor-pointer`
  const activeClass = `${showLanguageWhite ? 'text-[#fff]' : 'text-[#415C87]' } mr-[5px] font-medium cursor-pointer underline decoration-solid`
  const translationSidebar = language === 'es' ? esSidebar : enSidebar
  const translationFooter = language === 'es' ? esFooter : enFooter
    
  const handleSidebar = () => {
    setSidebar(!sidebar)
  }
  return (
    <div className='layout relative'>
      <div className={`w-fit ${noNavbar ? 'hidden' : 'block'} ${noScrollNavbar ? 'absolute' : 'fixed'} top-0 sm:z-40 z-40 sm:p-[7px] p-[25px]`} onMouseMove={() => setSidebar(true)}>
        <Hamburger/>
      </div>
    { (showLanguage || showLanguageWhite) && <div>
        <div className='absolute z-20 right-0 sm:mr-[20px] flex sm:mt-[10px] m-[25px]'>
          <button onClick={() =>changeLanguage('es')} className={language === 'es' ? activeClass : normalClass}>ESP</button>
           <p className={`${showLanguageWhite ? 'text-[#fff]' : 'text-[#415C87]'}`}>/</p>
           <button onClick={() =>changeLanguage('en')} className={language === 'en' ? activeClass : normalClass}>ENG</button>
        </div>
      </div>
      }
      {
       shouldMount && <div style={{
        transition: '.3s',
        opacity: stage === 'enter' ? 1 : 0,
        transform: stage === 'enter' ? 'translateX(0)' : 'translateX(-100%)'
      }} onMouseLeave={handleSidebar} className={`fixed top-0 z-30 sm:z-50`}> 
        <Sidebar items={translationSidebar["menu"]} onclose={handleSidebar} />
      </div>        
        }
      <div className={`overlay ${modal ? 'fixed inset-0' : ''}`}>
        <div className='content'>
          {children}
        </div>
      </div>
      {
        !noFooter && <Footer items={translationFooter} />
      }
    </div>
  );
}

Layout.defaultProps = {
  showLanguage: false,
  showLanguageWhite: false
}

export default Layout;
