import React from 'react';
import bg from '../../assets/recursos/Header.jpg'

function HeroContainer({items}){
    return (
        <div style={{backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}  className='w-screen flex justify-center items-center bg-[#E2E2E2] h-[590px] px-[40px] py-[90px]'>
            <div className='w-[98%] max-w-[1460px] flex flex-col justify-center items-center bg-[#000000] bg-opacity-[.69] h-[95%] lg:p-[120px] p-[20px]'>
                <div>
                {
                    items.title.map(title => 
                        <h1 style={{fontFamily:'Merriweather Italic'}} className='text-[#D1C5B2] lg:text-[30px] text-[26px]'> {title} </h1>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HeroContainer