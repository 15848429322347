import React from "react";
import PropTypes from 'prop-types'

function Button({ children, width, height, backgroundColor, hover, color, border, fontSize, click, padding, disabled }){
    return (
        <button
            disabled={disabled}
            onClick={() => click()}
            className={`${padding} ${backgroundColor} ${color} ${width} ${height} ${border} ${hover} ${fontSize} ${disabled ? 'bg-opacity-25' : ''}`}
            >
                { children }
        </button>
    )
}

Button.propTypes = {
    children: PropTypes.any,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    click: PropTypes.func,
    padding: PropTypes.string,
}

Button.defaultProps = {
    children: 'Conocer más',
    width: 'w-[164px]',
    height: 'h-[48px]',
    backgroundColor: 'bg-primary',
    color: 'text-[#fff]',
    border: 'rounded-[4px]',
    hover: 'hover:bg-[#D1C5B2]',
    fontSize: 'text-[12px]'
}

export default Button;