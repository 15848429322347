import React from 'react'
import Mail from '../../assets/equipo/Mail';
import Phone from '../../assets/equipo/Phone';

function Modal({items, closeModal}){
    return (
        <div className='w-[840px] h-[352px] p-[20px] flex flex-col bg-primary'>
            <div className='text-[#fff] flex justify-end pr-[20px]'><p onClick={() => closeModal(false)} className='text-[30px] cursor-pointer w-fit'>X</p></div>
            <div className='flex w-full h-full'>
                <div className='w-1/3'></div>
                <div className='w-2/3 flex flex-col justify-between pb-[20px]'>
                    <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-[#fff] text-[48px]'>{items.name}</h1>
                    <p style={{fontFamily: 'Merriweather Italic Bold'}} className='text-[16px] text-[#D1C5B2]'>{items.jobTitle}</p>
                    <p style={{fontFamily: 'Poppins'}} className='text-[20px] text-[#D1C5B2] mb-[15px] mt-[20px]'>{items.contact}</p>
                    <div className='text-[#fff] flex'>
                        <Mail fill="#fff"  width="19" height="19"/>
                        <p style={{fontFamily: 'Poppins Light'}} className='text-[#fff ml-[10px]'>{items.phone}</p>
                    </div>
                    <div className='flex'>
                        <Phone fill="#fff" width="19" height="19" />
                        <p style={{fontFamily: 'Poppins Light'}} className='text-[#fff] ml-[10px]'>{items.email}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Modal