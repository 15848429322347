import React from "react";

const LogoCreel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={576.177}
    height={104.049}
    viewBox="0 0 576.177 104.049"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rect\xE1ngulo 1575"
          fill="#fff"
          d="M0 0h576.177v104.049H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 5098" clipPath="url(#a)" fill="#fff">
      <path
        data-name="Trazado 18748"
        d="M104.048 0H0v29.422a47.362 47.362 0 0 1 27.554-8.3c20.617 0 42.968 13.115 43.629 36.141H46.294c-1.341-9.643-8.836-14.727-19.275-14.727C10.953 42.539 4.8 56.727 4.8 70.916s6.151 28.377 22.217 28.377c11.645 0 18.337-6.693 19.275-17.672h24.891a41.778 41.778 0 0 1-7.457 22.428h40.322Z"
      />
      <path
        data-name="Trazado 18749"
        d="M153.98 48.776c-.612-4.356-4.8-7.676-9.582-7.676-8.66 0-11.914 7.373-11.914 15.046 0 7.31 3.254 14.683 11.914 14.683 5.9 0 9.211-4.054 9.95-9.829h9.332c-.978 10.929-8.535 17.934-19.282 17.934-13.571 0-21.558-10.133-21.558-22.788C122.84 43.128 130.827 33 144.4 33c9.644 0 17.748 5.648 18.915 15.78Z"
      />
      <path
        data-name="Trazado 18750"
        d="M169.337 46.137h8.285v5.9h.125a11.213 11.213 0 0 1 10.13-6.754 6.631 6.631 0 0 1 1.9.3v8.112a15.653 15.653 0 0 0-3.131-.308c-6.388 0-8.6 4.6-8.6 10.194v14.31h-8.719Z"
      />
      <path
        data-name="Trazado 18751"
        d="M198.075 64.135c.244 5.528 2.948 8.046 7.8 8.046 3.5 0 6.332-2.15 6.877-4.118h7.681c-2.457 7.492-7.681 10.688-14.861 10.688-10.012 0-16.213-6.878-16.213-16.708 0-9.521 6.57-16.763 16.213-16.763 10.808 0 16.027 9.088 15.416 18.855Zm14.182-5.53c-.8-4.425-2.7-6.757-6.936-6.757a6.866 6.866 0 0 0-7.246 6.757Z"
      />
      <path
        data-name="Trazado 18752"
        d="M232.1 64.135c.245 5.528 2.946 8.046 7.8 8.046 3.5 0 6.323-2.15 6.879-4.118h7.675c-2.459 7.492-7.675 10.688-14.862 10.688-10.012 0-16.22-6.878-16.22-16.708 0-9.521 6.575-16.763 16.22-16.763 10.8 0 16.025 9.088 15.416 18.855Zm14.186-5.53c-.8-4.425-2.7-6.757-6.941-6.757A6.871 6.871 0 0 0 232.1 58.6Z"
      />
      <path
        data-name="Rect\xE1ngulo 1574"
        d="M259.181 34.037h8.725v43.854h-8.725z"
      />
      <path
        data-name="Trazado 18753"
        d="M295.168 33h7.006l-17.138 45.933h-7Z"
      />
      <path
        data-name="Trazado 18754"
        d="M303.972 55.94c.493-8.185 7.816-10.643 14.951-10.643 6.338 0 13.96 1.415 13.96 9.045v16.552c0 2.89.317 5.784 1.114 7.074h-8.856a12.761 12.761 0 0 1-.619-3.077c-2.769 2.894-6.83 3.94-10.7 3.94-6.034 0-10.831-3.018-10.831-9.539 0-7.2 5.415-8.918 10.831-9.659 5.35-.8 10.339-.619 10.339-4.185 0-3.75-2.59-4.306-5.664-4.306-3.324 0-5.477 1.359-5.783 4.8Zm20.185 6.46c-1.48 1.291-4.556 1.355-7.264 1.853-2.705.545-5.166 1.469-5.166 4.674 0 3.256 2.519 4.055 5.35 4.055 6.829 0 7.08-5.415 7.08-7.318Z"
      />
      <path
        data-name="Trazado 18755"
        d="M337.912 34.037h8.739v16h.119c2.155-3.257 6.094-4.737 10.03-4.737 6.338 0 13.164 5.1 13.164 16.737 0 11.687-6.826 16.8-13.164 16.8-4.672 0-8.552-1.415-10.458-4.922h-.124v4.06h-8.306Zm15.876 17.842c-5.168 0-7.446 4.862-7.446 10.211 0 5.293 2.278 10.159 7.446 10.159s7.446-4.866 7.446-10.159c0-5.349-2.28-10.211-7.446-10.211"
      />
      <path
        data-name="Trazado 18756"
        d="M389.3 45.3c10.032 0 16.49 6.646 16.49 16.793 0 10.094-6.458 16.74-16.49 16.74-9.965 0-16.423-6.646-16.423-16.74 0-10.147 6.458-16.793 16.423-16.793m0 26.952c5.972 0 7.755-5.11 7.755-10.159 0-5.1-1.783-10.211-7.755-10.211-5.906 0-7.692 5.109-7.692 10.211 0 5.049 1.786 10.159 7.692 10.159"
      />
      <path
        data-name="Trazado 18757"
        d="M440.011 75.937c0 5.23-1.848 14.091-16.489 14.091-6.274 0-13.6-2.954-14.021-10.214h8.666c.8 3.258 3.446 4.369 6.523 4.369 4.857 0 7.079-3.326 7.017-7.878v-4.182h-.128c-1.909 3.32-5.724 4.92-9.534 4.92-9.536 0-13.6-7.258-13.6-16 0-8.246 4.738-15.75 13.658-15.75 4.187 0 7.384 1.414 9.472 5.1h.128V46.16h8.3Zm-8.3-14.273c0-5.172-1.786-9.785-7.446-9.785-4.922 0-7.077 4.306-7.077 9.045 0 4.553 1.721 9.532 7.077 9.532 4.981 0 7.446-4.243 7.446-8.792"
      />
      <path
        data-name="Trazado 18758"
        d="M444.515 55.94c.485-8.185 7.809-10.64 14.954-10.64 6.332 0 13.963 1.415 13.963 9.045v16.549c0 2.89.3 5.784 1.1 7.074h-8.86a13 13 0 0 1-.616-3.077c-2.766 2.894-6.829 3.94-10.705 3.94-6.028 0-10.83-3.018-10.83-9.539 0-7.2 5.415-8.918 10.83-9.659 5.356-.8 10.338-.619 10.338-4.185 0-3.75-2.585-4.306-5.663-4.306-3.321 0-5.477 1.359-5.78 4.8Zm20.179 6.46c-1.475 1.291-4.552 1.355-7.261 1.853-2.707.545-5.165 1.469-5.165 4.674 0 3.256 2.519 4.055 5.352 4.055 6.827 0 7.074-5.415 7.074-7.318Z"
      />
      <path
        data-name="Trazado 18759"
        d="M500.867 73.908h-.124c-2.033 3.444-5.6 4.922-9.664 4.922-9.593 0-14.273-8.249-14.273-17.043 0-8.554 4.737-16.49 14.088-16.49a10.951 10.951 0 0 1 9.418 4.737h.122v-16h8.738v43.934h-8.3Zm-7.635-22.029c-5.721 0-7.687 4.922-7.687 10.155 0 4.981 2.282 10.215 7.687 10.215 5.784 0 7.511-5.047 7.511-10.278 0-5.17-1.85-10.092-7.511-10.092"
      />
      <path
        data-name="Trazado 18760"
        d="M528.557 45.3c10.031 0 16.491 6.646 16.491 16.793 0 10.094-6.46 16.74-16.491 16.74-9.965 0-16.424-6.646-16.424-16.74 0-10.147 6.459-16.793 16.424-16.793m0 26.952c5.968 0 7.757-5.11 7.757-10.159 0-5.1-1.789-10.211-7.757-10.211-5.9 0-7.687 5.109-7.687 10.211 0 5.049 1.785 10.159 7.687 10.159"
      />
      <path
        data-name="Trazado 18761"
        d="M555.015 67.627c.063 3.818 3.262 5.355 6.706 5.355 2.523 0 5.722-.982 5.722-4.054 0-2.651-3.63-3.57-9.9-4.924-5.047-1.111-10.093-2.9-10.093-8.5 0-8.117 7.012-10.211 13.844-10.211 6.95 0 13.347 2.338 14.028 10.15h-8.3c-.247-3.381-2.833-4.305-5.967-4.305-1.973 0-4.865.366-4.865 2.952 0 3.138 4.922 3.57 9.907 4.741 5.11 1.164 10.089 3.011 10.089 8.921 0 8.363-7.259 11.074-14.516 11.074-7.385 0-14.585-2.767-14.955-11.2Z"
      />
    </g>
  </svg>
);
export default LogoCreel;
