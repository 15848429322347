const es = {
    "header": "En Creel/abogados contamos con una variedad de Áreas de Práctica especializadas para brindar soluciones personalizadas de acuerdo a las necesidades e intereses de cada uno de nuestros clientes.",
    "title": "Descubre a detalle cada una de nuestras Áreas de Práctica",
    "subtitle": "y conoce al Equipo de especialistas de cada área.",
    "accordion": [
        {
            "title": "Derecho Corporativo y Mercantil", 
            "p": [
                {
                    title: "",
                    items: ["Prestamos asesoría jurídica integral en todas las áreas relacionadas con la actividad comercial y de negocios de nuestra clientela, incluyendo Derecho Corporativo, Contratación Mercantil, Fusiones y Adquisiciones, Capital Privado y Gobierno Corporativo."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[20px]'>Derecho Corporativo </h3>",
                    items: [`<p>Atendemos las necesidades corporativas cotidianas, así como las operaciones más complejas y sofisticadas de nuestros clientes. Desde la constitución, mantenimiento, transformación y disolución y liquidación de todo tipo de entidades mercantiles y civiles, hasta la conformación de grupos y conglomerados de empresas y sociedades controladoras (<span style='font-family:Poppins Italic'>holdings</span>) y su reestructuración corporativa.</p>`]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Contratación Mercantil </h3>",
                    items: ["Nos especializamos en la redacción y negociación de todo tipo de contratos mercantiles y títulos de crédito que derivan de los negocios sociales y resultan indispensables para dotar de seguridad jurídica a las operaciones comerciales de nuestros clientes."],
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Fusiones y Adquisiciones </h3>",
                    items: ["Representamos a compradores y vendedores en operaciones de fusiones y adquisiciones, incluyendo compraventas de valores y activos, tangibles e intangibles, adquisiciones negociadas y financiadas, subastas privadas y públicas de venta de acciones y activos, así como co-inversiones (<span style='font-family:Poppins Italic'>joint ventures</span>) y alianzas estratégicas. Nuestros servicios comprenden la realización de auditorías en materia legal, la redacción y negociación de cartas de intención, memoranda de entendimiento, contratos de compraventa, convenios entre accionistas, convenios de suscripción, convenios de fusión y toda la documentación corporativa relacionada con reestructuras corporativas. Igualmente, asesoramos a nuestros clientes en la gestión y tramitación de todo tipo de permisos o autorizaciones gubernamentales requeridas para el perfeccionamiento de las operaciones de fusiones y adquisiciones en las que participamos."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Capital Privado </h3>",
                    items: ["Brindamos asesoría a fondos de capital privado y de deuda en una variedad de asuntos y operaciones, incluyendo la formación de fondos, la estructuración de vehículos de inversión y administración, inversiones y adquisiciones de posiciones minoritarias y de control, estructuras de financiamiento, así como en eventos de liquidez."],
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Gobierno Corporativo </h3>",
                    items: ["Ofrecemos un asesoramiento integral respecto al gobierno de empresas privadas y de sociedades listadas en la Bolsa de Valores, incluyendo el diseño y la estructuración de esquemas de control accionario, compartición de gestión, organización de controles administrativos en disposiciones estatutarias y convenios entre accionistas, así como asesoría en la integración y operación de órganos de administración y comités."],
                }
            ],
            "images": {
                "title": "Contactos de Área",
                 items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
                        name: "Carlos Creel C."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel"
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
                        name: "Alfonso Razú A."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
                        name: "César Morales G."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg'),
                        name: "Diego Valdés A."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q."
                    }
                ]
            },
            "banner": [require('../../assets/practica/02_CorporativoMercantil/AP-DCorporativo_escritorio.jpg')],
            "measures": {
                width: 447,
                height: 327
            }
        },
        {
            "title": "Derecho Financiero",
            "p": [
                {   
                    title: "",
                    items: ["Nuestra práctica de Derecho Financiero abarca asuntos transaccionales y regulatorios. Brindamos asesoría en operaciones financieras y bursátiles, representando a acreedores, bancarios y no-bancario, y deudores, así como a emisoras de valores y agentes colocadores. Asimismo, asesoramos a entidades reguladas por las autoridades financieras mexicanas en sus operaciones cotidianas y en el ámbito de su cumplimiento regulatorio, así como a entidades extranjeras en la realización de operaciones domésticas y transnacionales.", `En el campo de la tecnología financiera (<span style='font-family:Poppins Italic'>FinTech</span>), asistimos a nuestros clientes en el establecimiento de empresas y plataformas de financiamiento, así como para la prestación de servicios y comercialización de productos.`]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[40px]'>Derecho Bancario </h3>",
                    items: ["Representamos a compañías, fondos e instituciones financieras, bancarias y no-bancarias, en la estructuración y negociación de diversos tipos de operaciones de crédito, incluyendo créditos simples y sindicados, créditos quirografarios y garantizados, créditos para adquisiciones, créditos puente y mezanine, así como en la estructuración de paquetes de garantías.",  "Nuestros servicios en esta práctica incluyen asesoría en materia de refinanciación y reestructuración de deuda."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[15px] mt-[30px]'>Derecho Bursátil y Mercados de Capitales </h3>",
                    items: ["Asesoramos a empresas emisoras, agentes colocadores, coordinadores globales y bancos de inversión, en todo tipo de operaciones en los mercados de capitales, incluyendo en emisiones de FIBRAs, CKDs y bursatilizaciones. Habitualmente prestamos asesoría a sociedades listadas en la Bolsa Mexicana de Valores y BIVA, a sus accionistas, miembros del consejo de administración y comités, y directivos relevantes en materia de cumplimiento regulatorio y gobierno corporativo."]
                }
            ],
            images: {
                "title": "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/CarlosCreel_Esc.jpg'),
                        name: "Carlos Creel C."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel"
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
                        name: "Alfonso Razú A."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/CesarMorales_Esc.jpg'),
                        name: "César Morales G."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg'),
                        name: "Diego Valdés A."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q."
                    }
                ]
            },
            "banner": [require('../../assets/practica/03_Financiero/AP-DFinanciero_escritorio.jpg')],
            "measures": {
                width: 447,
                height: 327
            }
        },
        {
            "title": "Derecho Fiscal",
            "p": [
                {
                    title: "",
                    items: ["Nuestro Equipo de Derecho Fiscal proporciona asesoría especializada en materia impositiva relacionada con el diseño, estructuración, negociación e implementación de transacciones, considerando las necesidades específicas y las industrias o sectores en los que participan nuestros clientes."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[16px]'>Litigio Fiscal </h3>",
                    items: ["Representamos a clientes en la defensa de sus derechos frente a las autoridades fiscales en procedimientos contenciosos administrativos y jurisdiccionales a nivel federal y estatal, incluyendo asesoría en materia de recursos de revocación y otras instancias administrativas, juicios de nulidad y amparo fiscal."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[18px]'>Precios de Transferencia </h3>",
                    items: ["Prestamos asesoría en la revisión e implementación legal de las políticas de precios de transferencia de nuestros clientes. Los miembros de nuestra práctica se mantienen debidamente actualizados para proporcionar asesoría de conformidad con los desarrollos de la OCDE y los propios de las autoridades mexicanas."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[25px]'>Comercio Exterior </h3>",
                    items: ["Asistimos a nuestra clientela en asuntos de comercio exterior, brindando asesoría especializada y representándolos en la defensa de sus intereses ante las autoridades aduaneras, fundamentalmente en lo referente a procedimientos de antidumping y medidas compensatorias, clasificación arancelaria de productos de importación, así como la determinación de origen de productos provenientes de países con los que México ha celebrado Tratados Comerciales."]
                }
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/JavierPortas_Esc.jpg'),
                        name: "Javier Portas L."
                    }
                ]
            },
            "banner": [require('../../assets/practica/03_Fiscal/AP-DFiscal_escritorio.jpg')],
            "measures": {
                width: 451,
                height: 293
            }
        }, 
        {
            "title": "Derecho Laboral",
            "p": [
                {
                    title: "",
                    items: ["Nuestros servicios legales en Derecho Laboral están orientados a desarrollar un ambiente de trabajo sano y estable, en el que se prevengan y disminuyan conflictos en el centro de trabajo, siguiendo altos estándares éticos y profesionales.", "Asesoramos a nuestros clientes en procesos de contratación y terminación de relaciones laborales de todo tipo de empleados, incluyendo personal sindicalizado y altos ejecutivos, así como en la modificación de las condiciones generales de trabajo. De igual forma, elaboramos cartas oferta, convenios de confidencialidad, reglamentos interiores de trabajo, y planes y protocolos de prevención y erradicación de discriminación y violencia laboral. Asistimos a nuestra clientela respecto a la celebración, administración y terminación de contratos colectivos de trabajo con sindicatos locales y nacionales, de conformidad con las reglas de libre asociación sindical y negociación colectiva vigentes. Asimismo, participamos en el diseño y estructuración de planes de previsión social, incentivos, bonos, opción de compra de acciones, entre otros.", "<p class='mt-[35px] mb-[25px]'>Nuestra práctica de Derecho Laboral comprende la representación de nuestros clientes en litigios individuales y colectivos ante las Juntas de Conciliación y Arbitraje o Tribunales Laborales, a nivel local y federal.</p>", "Los miembros de nuestra práctica Laboral colaboran constantemente con los equipos de Fusiones y Adquisiciones en los procesos de auditoría legal y la revisión de contratos, con el fin de identificar aquellas contingencias laborales que pudieran impactar las operaciones, así como en la ejecución de procesos de transferencia de personal y modificación de condiciones laborales."]
                }
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        name: "Sofía Gómez B.",
                        image: require('../../assets/equipo/Socios/Home/SofiaGomez_Esc.png'),
                    }
                ]
            },
            "banner": [require('../../assets/practica/04_Laboral/AP-DLaboral_escritorio.jpg')],
            "measures": {
                width: 453,
                height: 443
            }
        },
        {
            "title": "Derecho Inmobiliario",
             "p": [
                {
                    title: "",
                    items: ["Prestamos asesoría especializada sobre una amplia gama de operaciones inmobiliarias dentro de los sectores residencial, industrial, comercial y turístico, entre las que destacan la compraventa y arrendamiento de activos inmobiliarios, el diseño y estructuración de regímenes de propiedad en condominio y de tiempo compartido, el desarrollo, construcción, administración y operación de proyectos inmobiliarios, así como la gestión de permisos y autorizaciones requeridas para dichos proyectos.", `<p class='mt-[38px]'>De igual forma, representamos a inversionistas en la formación de fondos inmobiliarios privados y públicos en México, y asesoramos a clientes tanto nacionales como extranjeros en co-inversiones (<span style='font-family:Poppins Italic'>joint ventures</span>) relacionadas con el mercado inmobiliario.</p>`]
                }
             ],
             images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Consejeros/Home/JorgeTorresB_Esc.jpg'),
                        name: "Jorge Torres B."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel"
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
                        name: "Alfonso Razú A."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg'),
                        name: "Diego Valdés A."
                    },
                ]
             },
             "banner": [require('../../assets/practica/05_Inmobiliario/AP-DInmobiliario_escritorio.jpg')],
             "measures": {
                width: 491,
                height: 375
            }
        },
        {
            "title": "Competencia Económica",
            "p": [
                {
                    title: "",
                    items: ["Prestamos asesoría jurídica integral en toda la gama de asuntos y operaciones relacionadas con la Ley Federal de Competencia Económica, incluyendo notificaciones de concentraciones, investigaciones por prácticas monopólicas relativas y absolutas, procedimientos administrativos, así como convenios de exclusividad y de no competencia.", "<p class='mt-[30px] mb-[90px]'>Nuestro Equipo especializado participa activamente en aquellas operaciones de Fusiones y Adquisiciones que pudieran tener implicaciones en materia de competencia económica, así como con nuestra Área de Cumplimiento Regulatorio para la revisión de cumplimiento normativo y la atención de investigaciones.</p>"]
                }
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
                        name: "Alfonso Razú A."
                    },
                ]
            },
            "banner": [require('../../assets/practica/06_CompetenciaEconomica/AP-Competencia_escritorio.jpg')],
            "measures": {
                width: 491,
                height: 402
            }

        },
        {
            "title": "Recursos Naturales",
            "p": [
                {
                    title: "",
                    items: ["Dentro de nuestra práctica de Recursos Naturales, asesoramos a empresas nacionales e internacionales en proyectos que involucran el uso y aprovechamiento de Recursos Naturales renovables y no renovables, incluyendo Energía y Minería."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[15px] mt-[32px]'>Derecho Energético</h3>",
                    items: ["Nuestro Equipo especializado en Derecho Energético representa a una gran variedad de inversionistas, fondos, acreedores, desarrolladores y operadores en todas las fases involucradas en proyectos energéticos en los sectores de electricidad, petróleo, petroquímico y de gas, así como en una amplia variedad de proyectos de infraestructura."]
                },
                {
                    title: "<h3 style='font-family: Poppins Light' class='text-secondary text-[20px] mb-[8px] mt-[32px]'>Derecho Minero</h3>",
                    items: ["Con una tradición de varias décadas que se remonta a los orígenes de nuestro Despacho y que constituyó la principal especialidad de nuestro fundador Don Luis J. Creel Luján, continuamos brindando asesoría en Derecho Minero a clientes nacionales y extranjeros, que incluye la obtención, mantenimiento y renovación de concesiones y permisos mineros, el diseño y estructuración de contratos de suministro, y la adquisición y financiamiento de proyectos mineros."]
                }
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel"
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
                        name: "Alfonso Razú A."
                    },
                ]
            },
            "banner": [require('../../assets/practica/07_RecursosNaturales/AP-Recursos_escritorio.jpg')],
            "measures": {
                width: 451,
                height: 331
            }
        },
        {
            "title": "Litigio y Solución de Controversias",
            "p": [
                {
                    title: "",
                    items: ["Contamos con amplia experiencia en la asesoría y representación de empresas nacionales y extranjeras en todo tipo de litigios complejos ante tribunales locales y federales, incluyendo litigio constitucional, administrativo, civil y mercantil.", "<p class='mt-[35px]'>Nuestra práctica comprende, también, la representación de clientes en mecanismos alternativos de solución de conflictos (MASC), como mediación y arbitraje, participando en procedimientos ad hoc y ante las principales instituciones administradoras, nacionales y extranjeras.</p>"]
                }
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
                        name: "Ernesto Saldate A."
                    },
                ]
            },
            "banner": [require('../../assets/practica/08_LitigoSolucion/AP-Litigio_escritorio.jpg')],
            "measures": {
                width: 491,
                height: 488
            }
        },
        {
            "title": "ASG",
            "p": [
                {
                    title: "",
                    items: ["Contamos con un Equipo especializado y con amplia experiencia para brindar asesoría legal integral en ASG (Ambiental, Social y Gobierno Corporativo). Nuestro Equipo conjunta a abogados de varias de nuestras Áreas de Práctica, incluyendo Derecho Corporativo, Derecho Laboral y Derecho Inmobiliario, lo que nos permite profundizar en todas las necesidades de cumplimiento y mejores prácticas de EGS; entendiendo las particularidades de cada negocio y sector industrial de nuestros clientes."]
                }
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/GustavoStruck_Esc.jpg'),
                        name: "Gustavo Struck Creel"
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/PabloArellano_Esc.jpg'),
                        name: "Pablo Arellano Q."
                    },
                    {
                        image: require('../../assets/equipo/Consejeros/Home/Alejandro_Esc.jpg'),
                        name: "Alejandro Creel Y."
                    },
                    {
                        name: "Sofía Gómez B.",
                        image: require('../../assets/equipo/Socios/Home/SofiaGomez_Esc.png'),
                    }
                ]
            },
            "banner": [require('../../assets/practica/09_ASG/AP-ASG_escritorio.jpg')],
            "measures": {
                width: 491,
                height: 226
            }
        },
        {
            title: "Cumplimiento Regulatorio",
            "p": [
                {
                    title: "",
                    items: ["En coordinación con las Áreas de Práctica de Derecho Corporativo y Competencia Económica, representamos a clientes en el diseño e implementación de programas de prevención, gestión y remediación de cumplimiento regulatorio en temas Privacidad de Datos, Prevención de Lavado de Dinero, Competencia Económica y Anti-corrupción, en la elaboración de códigos de ética y manuales, así como en procesos de auditoría e investigaciones relacionados.", "<p style='height: 180px' class='mt-[20px]'>En materia de Anti-corrupción, en adición a nuestros servicios en materia de cumplimiento regulatorio de la normativa aplicable, asesoramos a empresas extranjeras con operaciones en México para el cumplimiento de legislación anticorrupción internacional, como el <span style='font-family:Poppins Italic'>Foreign Corrupt Practices Act</span> (FCPA) Estadounidense y la <span style='font-family:Poppins Italic'>Bribery Act</span> del Reino Unido.</p>"]
                },
            ],
            images: {
                title: "Contactos de Área",
                items: [
                    {
                        image: require('../../assets/equipo/Socios/Home/AlfonsoRazu_Esc.jpg'),
                        name: "Alfonso Razú A."
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/DiegoValdez_Esc.jpg'),
                        name: "Diego Valdés A."
                    },
                    {
                        name: "Sofía Gómez B.",
                        image: require('../../assets/equipo/Socios/Home/SofiaGomez_Esc.png'),
                    },
                    {
                        image: require('../../assets/equipo/Socios/Home/ErnestoSaldate_Esc.jpg'),
                        name: "Ernesto Saldate A."
                    },
                ]
            },
            "banner": [require('../../assets/practica/010_Cumplimiento/AP-Cumplimiento_escritorio.jpg')],
            "measures": {
                width: 475,
                height: 400
            }

        }
    ]
}

export default es;