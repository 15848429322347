import axios from 'axios';

export const postData = async (body) => {
    try {
      const response = await axios.post('https://apicreel.digitalcoaster.mx/api/registro-lead', {
       name: body.name,
       email: body.email,
       telephone: body.telephone,
       country: body.country,
       university_company: body.company,
       comments: body.comments
      });
      return response
    } catch (error) {
      console.error(error);
    }
  };
  