import React from 'react'
import ShowMore from '../../components/common/ShowMore';

function ExperienciaContainer({ items }){
    return(
        <div id='practice-areas' className='w-screen flex justify-center items-center sm:p-[60px] p-[5px] sm:pb-[90px] pb-[50px]'>
            <div className='w-[90%] max-w-[1190px] sm:pt-[20px] pt-[50px]'>
                <div className="w-full">
                    <div className='flex flex-col justify-center items-center'>
                        <p style={{fontFamily: 'Merriweather Bold'}} className='text-primary sm:text-[42px] text-[30px] text-center sm:text-start'>{items.title}</p>
                        <p style={{fontFamily: 'Merriweather Italic'}} className='text-[#D1C5B2] sm:text-[30px] text-[18px] text-center py-[25px] max-w-[900px]'>{items.subtitle}</p>
                        <p dangerouslySetInnerHTML={{ __html: items.p }} style={{fontFamily: 'Poppins light'}} className='pb-[60px] text-[#7F7F7F] sm:text-[20px] text-[16px] max-w-[900px]' />
                    </div> 
                </div>
                <div className="flex flex-wrap sm:gap-[40px] gap-[10px] justify-center items-end">
                    {
                        items.items.map(item => 
                                <div className="w-[162px]  sm:h-full sm:w-[265px] flex justify-end">
                                    <ShowMore primary item={item}/> 
                                </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default ExperienciaContainer