import React, { useContext, useState } from 'react'
import Tabs from '../../components/common/Tabs';
import Button from '../../components/common/Button'
import ShowMoreTeam from '../../components/Home/ShowMoreTeam';
import { LanguageContext } from '../../context/LanguageContext'
import esLang from '../../translations/Home/meetOurTeamEs.js'
import enLang from '../../translations/Home/meetOurTeamEn'
import abogadosEs from '../../translations/biography/es'
import abogadosEn from '../../translations/biography/en'

import { useLocation } from "wouter";

function MeetTeamContainer({ items }){
    const { language } = useContext(LanguageContext);
    const translation = language === 'es' ? esLang : enLang
    const languageSearch = language === 'es' ? abogadosEs : abogadosEn
    const [query, setQuery] = useState('');
    const [arrQuery, setArrQuery] = useState([])
    const [location, setLocation] = useLocation();

    const querryMap = arrQuery.length > 0 && query !== '' ? arrQuery.slice(0,4) : translation["team"]
    const handleSetQuery = (e) => {
       setQuery(e.target.value)
       matchQuery(e.target.value)
    }

    const matchQuery = (e) => {
        const arrayQuery = [];
        for (const search in abogadosEs){
            const queryParsed = e.toUpperCase()
            const searchParsed = languageSearch[search].name.toUpperCase()
            if(searchParsed.includes(queryParsed)){
                const body = {
                    name: languageSearch[search].name,
                    phone: languageSearch[search].contact.phone,
                    email: languageSearch[search].contact.email,
                    image: languageSearch[search].image_team,
                    more: 'Read more',
                    link: languageSearch[search].link
                }
                arrayQuery.push(body)
            }
        }
        setArrQuery(arrayQuery)
    }

    const navigate = () => {
        setLocation("/our-team");
      };

    const socios = <div className='flex flex-col items-center'><div className='flex sm:flex-row flex-wrap justify-center sm:mt-0 sm:mb-0 mt-[60px] mb-[60px] gap-y-[45px] sm:gap-y-0'> 
    { querryMap.map(socio =>
        <div key={socio.name} className='flex mx-[10px] sm:w-[270px] sm:h-[287px] w-[147px] h-[147px]'>
            <ShowMoreTeam item={socio} />
        </div>
        )} 
     </div>
        <div className='mt-[50px]'>
            <Button click={navigate} width='w-[248px] h-[48px] text-[14px]' backgroundColor='bg-[#D1C5B2]' color='text-[#000] font-bold'>{translation["button"]}</Button>
        </div>
    </div>
    const contents = [socios]
    return (
        <div id="our-team" className='flex justify-center sm:px-[110px] px-[20px] sm:py-[40px] lg:h-full lg:w-full w-screen sm:h-screen h-full bg-[#fff]'>
            <div className='xl:max-w-[1366px]'>
                <h1 style={{fontFamily: 'Merriweather Bold'}} className='text-primary text-[40px]'>{items.text.title}</h1>
                <div className='mt-[35px] sm:flex flex-col flex justify-evenly sm:w-auto w-full'>
                    <Tabs tabs={items.tabs} contents={contents} searchbar query={query} setQuery={handleSetQuery} />
                </div>
            </div>
        </div>
    )
}

export default MeetTeamContainer;