import React from 'react'

const Notes = () => (
    <svg id="Grupo_5474" data-name="Grupo 5474" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.29" height="23.291" viewBox="0 0 23.29 23.291">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectángulo_1638" data-name="Rectángulo 1638" width="23.29" height="23.291" fill="#6f2a43"/>
    </clipPath>
  </defs>
  <g id="Grupo_5419" data-name="Grupo 5419" transform="translate(0 0)" clip-path="url(#clip-path)">
    <path id="Trazado_18811" data-name="Trazado 18811" d="M8.228,0h11.6a.558.558,0,0,0,.106.037,3.742,3.742,0,0,1,3.352,3.826q0,8.17,0,16.34a3.413,3.413,0,0,1-.062.688,2.969,2.969,0,0,1-2.982,2.4q-5.2,0-10.4,0c-2.265,0-4.53.006-6.795,0A2.863,2.863,0,0,1,.4,21.76,6.66,6.66,0,0,1,0,20.57V15.716a.857.857,0,0,0,.039-.131,3.341,3.341,0,0,1,3.2-2.945.922.922,0,0,1,.459.107.551.551,0,0,1,.234.645.612.612,0,0,1-.616.428A2.153,2.153,0,0,0,1.2,16.005c-.008,1.44-.012,2.88,0,4.321a1.763,1.763,0,0,0,2.641,1.54,1.745,1.745,0,0,0,.907-1.694q0-6.042,0-12.084c0-1.479-.014-2.958.007-4.436A3.68,3.68,0,0,1,7.289.22c.3-.1.626-.148.939-.22M5.336,22.089c.091.005.135.01.179.01q7.394,0,14.788,0A1.76,1.76,0,0,0,22.1,20.289q.005-8.249,0-16.5A2.535,2.535,0,0,0,19.507,1.2q-5.488-.01-10.976,0a2.813,2.813,0,0,0-.86.137A2.554,2.554,0,0,0,5.926,3.9q0,8.018,0,16.036a3.4,3.4,0,0,1-.594,2.154" transform="translate(0 0)" fill="#6f2a43"/>
    <path id="Trazado_18812" data-name="Trazado 18812" d="M75.015,25.243c-.547,0-1.093,0-1.64,0a1.052,1.052,0,0,1-1.106-1.1q-.005-1.386,0-2.772a1.033,1.033,0,0,1,1.074-1.081q1.686-.009,3.373,0a1.013,1.013,0,0,1,1.065,1.037q.022,1.432,0,2.864a1.032,1.032,0,0,1-1.08,1.053c-.562,0-1.124,0-1.686,0M76.6,21.48H73.471v2.562H76.6Z" transform="translate(-57.658 -16.183)" fill="#6f2a43"/>
    <path id="Trazado_18813" data-name="Trazado 18813" d="M45.222,57.893q2.716,0,5.431,0c.452,0,.693.23.674.631a.558.558,0,0,1-.5.55,1.653,1.653,0,0,1-.231.008q-5.385,0-10.769,0a.611.611,0,0,1-.657-.362.591.591,0,0,1,.593-.828c.955,0,1.91,0,2.866,0h2.588" transform="translate(-31.217 -46.19)" fill="#6f2a43"/>
    <path id="Trazado_18814" data-name="Trazado 18814" d="M45.226,74.714q-2.727,0-5.453,0a.582.582,0,0,1-.6-.8.515.515,0,0,1,.458-.368c.092-.009.185-.008.277-.008H50.537c.077,0,.154,0,.231,0a.587.587,0,0,1-.019,1.174c-.909.005-1.818,0-2.727,0h-2.8" transform="translate(-31.221 -58.67)" fill="#6f2a43"/>
    <path id="Trazado_18815" data-name="Trazado 18815" d="M45.253,89.154q2.726,0,5.453,0a.581.581,0,0,1,.591.82.51.51,0,0,1-.449.346c-.092.009-.185.009-.277.009H39.919a2.243,2.243,0,0,1-.3-.012.588.588,0,0,1,.019-1.16,2.142,2.142,0,0,1,.231,0h5.384" transform="translate(-31.228 -71.132)" fill="#6f2a43"/>
    <path id="Trazado_18816" data-name="Trazado 18816" d="M44.105,22.755c-.585,0-1.171.006-1.756,0a.587.587,0,0,1-.525-.869.548.548,0,0,1,.527-.311c1.171,0,2.342-.008,3.513,0a.549.549,0,0,1,.585.6.558.558,0,0,1-.61.578c-.578.005-1.155,0-1.733,0" transform="translate(-33.314 -17.209)" fill="#6f2a43"/>
    <path id="Trazado_18817" data-name="Trazado 18817" d="M44.139,38.846c-.57,0-1.14,0-1.71,0a.587.587,0,1,1-.007-1.173q1.721-.006,3.443,0a.587.587,0,1,1,.007,1.172c-.578.005-1.155,0-1.733,0" transform="translate(-33.355 -30.055)" fill="#6f2a43"/>
  </g>
</svg>
)

export default Notes;