import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import ArrowDown from '../../assets/common/ArrowDown'

function ShowMore({ items }){
    const [onHover, setOnHover] = useState(false)
    const [showComponent, setShowComponent] = useState(false)
    useEffect(() => {
        let timeoutId;
    
        if (onHover) {
          timeoutId = setTimeout(() => {
            setShowComponent(true);
          }, 300);
        } else {
          clearTimeout(timeoutId);
          setShowComponent(false)
          setOnHover(false);
        }
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [onHover]);
    return(
        <div className='relative group' onMouseMove={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
            <div className='lg:w-[349px] w-[334px] lg:h-[352px] h-[166px] group-hover:h-[356px] lg:bg-[#71717A] lg:mt-0 mt-[35px]'>
                <img className='object-cover group-hover:mix-blend-darken lg:w-full w-[340px] lg:h-[352px] h-[166px] lg:group-hover:w-fit group-hover:w-[251px] group-hover:ml-[auto] lg:block hidden' alt={items.text} src={items.image}></img>
                <img className='object-cover group-hover:mix-blend-darken h-[166px] group-hover:w-[251px] group-hover:ml-[auto] lg:hidden block' alt={items.text} src={items.image_movil}></img>

            </div>
            <div className='absolute lg:left-[-25px] lg:bottom-[-16px] group-hover:left-0 
                group-hover:bottom-0 flex flex-col justify-center group-hover:justify-normal
                group-hover:items-center lg:w-[169px] lg:h-[167px] group-hover:lg:w-full group-hover:lg:h-full group-hover:w-[251px] group-hover:h-[250px] 
                px-[25px] bg-[#000] lg:bg-opacity-100 bg-opacity-[.69] transition-all duration-500 ease-in-out w-[273px] h-[63px] z-[10] bottom-0'>
                <div style={{fontFamily: 'Merriweather Light'}} className='group-hover:w-[238px] group-hover:h-[352px] group-hover:flex group-hover:flex-col flex lg:block items-center'>
                    <p className='text-[#fff] text-[18px] lg:text-[22px] lg:mt-[30px] lg:mb-[15px] group-hover:lg:w-[126px] '>{items.text}</p>
                    {showComponent && <p className='text-[#fff] text-[14px] hidden group-hover:block'>{items.description}</p>}
                    <a href={items.link} className='text-[#fff] text-[14px] ml-auto underline decoration-solid cursor-pointer group-hover:mt-auto group-hover:mb-[20px] hidden lg:block group-hover:block'>{items.more}</a>
                    <div className='w-[15px] lg:hidden block ml-[auto]'><ArrowDown fill="#fff"/></div>
                </div>
            </div>
        </div>
    )
}
ShowMore.propTypes = {
    items: PropTypes.object
}

export default ShowMore;