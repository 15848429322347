const en = {
    "title": "More than Fifty years of experience in your favor.",
    "subtitle": "We provide expert advice in the Practice Areas related to business.",
    "p": "<span style='font-family: Poppins Medium'> Creel/abogados </span> experience is reflected in our range of Practice Areas. Find out more about our experience in each specialty.",
    "items": [
        {
            "name": "Corporate and Commercial Law",
            "image": require('../../assets/despacho/experiencia/ND-DCorporativo_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Finance",
            "image": require('../../assets/despacho/experiencia/ND-DFinanciero_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Tax",
            "image": require('../../assets/despacho/experiencia/ND-DFiscal_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Labor and Employment",
            "image": require('../../assets/despacho/experiencia/ND-DLaboral_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Real Estate",
            "image": require('../../assets/despacho/experiencia/ND-DInmobiliario_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Antitrust and Competition",
            "image": require('../../assets/despacho/experiencia/ND-Competencia_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Natural Resources",
            "image": require('../../assets/despacho/experiencia/ND-Recursos_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Litigation and Dispute Resolution",
            "image": require('../../assets/despacho/experiencia/ND-Litigio_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "ESG",
            "image": require('../../assets/despacho/experiencia/ND-ASG_escritorio.jpg'),
            "link": "/practice-areas"
        },
        {
            "name": "Compliance",
            "image": require('../../assets/despacho/experiencia/ND-Cumplimiento_escritorio.jpg'),
            "link": "/practice-areas"
        }
    ]
}

export default en;