import  React from "react";
const Phone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={19.176}
    viewBox="0 0 19.176 19.176"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rect\xE1ngulo 1132"
          fill={props || "#5d2648"}
          d="M0 0h19.176v19.176H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 231" clipPath="url(#a)">
      <path
        data-name="Trazado 266"
        d="M9.583 0A9.588 9.588 0 1 1 0 9.589 9.59 9.59 0 0 1 9.583 0m2.365 16.57a5.025 5.025 0 0 0 1.459-.229 3.259 3.259 0 0 0 .941-.415.718.718 0 0 0 .245-1.112c-.121-.2-.257-.389-.388-.582A12.6 12.6 0 0 0 12.8 12.5c-.336-.344-.561-.354-.945-.062a7.6 7.6 0 0 0-.648.6c-.4.39-.682.389-1.084-.007l-.058-.06a13.3 13.3 0 0 1-2.746-4.5 3.378 3.378 0 0 1-.244-.914.513.513 0 0 1 .333-.574 2.686 2.686 0 0 1 .346-.117 7.661 7.661 0 0 0 .777-.247.578.578 0 0 0 .4-.806 10.185 10.185 0 0 0-.446-1.29 12.5 12.5 0 0 0-.727-1.533.663.663 0 0 0-.909-.312 1.512 1.512 0 0 0-.356.18 4.42 4.42 0 0 0-1.988 4.664 11.267 11.267 0 0 0 .972 2.811 20.588 20.588 0 0 0 1.332 2.381 12.18 12.18 0 0 0 2.246 2.71 4.32 4.32 0 0 0 2.893 1.146"
        fill={props || "#5d2648"}
        />
    </g>
  </svg>
);
export default Phone;
