import React from 'react'
import Tabs from '../../components/common/Tabs'
import Accordion from '../../components/NuestroDespacho/Accordion'

function SomosContainer({ items }){
    const valores = <div className='flex flex-col mt-[-40px]'>
            <div className='flex flex-col sm:flex-row'>
                <img src={items.content.valores.image}></img>
                <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] px-[20px] pt-[40px]'>
                    {
                        items.content.valores.items.map(p => <p className='leading-loose pb-[10px]' dangerouslySetInnerHTML={{ __html: p }} />)
                    }
                </div>
            </div> 
            <div className='text-[#6F2A43] text-[24px] pt-[40px] px-[60px]'>
                <p style={{fontFamily: 'Poppins Medium'}} className='text-center' >{items.content.text}</p>
            </div> 
        </div>

    const enfoque = <div className='flex flex-col mt-[-40px]'>
    <div className='flex flex-col sm:flex-row'>
        <img src={items.content.enfoque.image}></img>
        <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] px-[20px] pt-[80px]'>
            <p className='pb-[26px] leading-loose' dangerouslySetInnerHTML={{ __html: items.content.enfoque.p1 }} />
            <p className='pb-[26px] leading-loose' dangerouslySetInnerHTML={{ __html: items.content.enfoque.p2 }}/>
        </div>
    </div> 
    <div className='text-[#6F2A43] text-[24px] pt-[40px] py-[60px] px-[60px]'>
        <p className='text-center' style={{fontFamily: 'Poppins Medium'}}>{items.content.text}</p>
    </div> 
</div>

    const compromiso = <div className='flex flex-col mt-[-40px]'>
    <div className='flex flex-col sm:flex-row'>
        <img src={items.content.compromiso.image}></img>
        <div style={{fontFamily: 'Poppins Light'}} className='text-[#7F7F7F] text-[16px] px-[20px] pt-[80px]'>
            <p className='pb-[26px] leading-loose' dangerouslySetInnerHTML={{ __html: items.content.compromiso.p1}} />
            <p className='pb-[26px] leading-loose' dangerouslySetInnerHTML={{ __html: items.content.compromiso.p2}} />
            <p className='pb-[26px] leading-loose' dangerouslySetInnerHTML={{ __html: items.content.compromiso.p3}} />
        </div>
    </div> 
    <div className='text-[#6F2A43] text-[24px] pt-[40px] py-[60px] px-[60px]'>
        <p className='text-center' style={{fontFamily: 'Poppins Medium'}}>{items.content.text}</p>
    </div> 
</div>

    const accordionObject = [
        {
            title: "Valores",
            p: items.content.valores.items,
            image: items.content.valores.image
        },
        {
            title: "Enfoque",
            p: [items.content.enfoque.p1, items.content.enfoque.p2],
            image: items.content.enfoque.image
        },
        {
            title: "Compromiso",
            p: [items.content.compromiso.p1, items.content.compromiso.p2, items.content.compromiso.p3],
            image: items.content.compromiso.image
        }
]
    return (
        <div className='bg-[#E4E4E4] bg-opacity-[.70] w-screen flex flex-col'>
            <div className='flex flex-col py-[60px] justify-center items-center'>
                <p style={{fontFamily: 'Merriweather Bold'}} className="sm:text-[40px] text-[30px] text-primary py-[20px] px-[40px] md:px-0">{items.title}</p>
            </div>
            <div className='sm:px-[100px] pt-[20px] px-[10px] hidden sm:block'>
                <Tabs v2 tabs={items.tabs} width={245} contents={[valores, enfoque, compromiso]} />
            </div>
            <div className='sm:hidden block'>
                {
                    accordionObject.map(acc => <Accordion title={acc.title} content={acc.p} image={acc.image} />)
                }
            </div>
        </div>
    )
}
export default SomosContainer;