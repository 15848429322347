import React from "react";
const Insta = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.311}
    height={18.311}
    viewBox="0 0 18.311 18.311"
    {...props}
  >
    <g id="_x31_0.Linkedin" transform="translate(-10 -10)">
      <path
        id="Trazado_18810"
        data-name="Trazado 18810"
        d="M50.133,47.82V41.114c0-3.3-.71-5.814-4.555-5.814a3.974,3.974,0,0,0-3.593,1.968h-.046V35.6H38.3V47.82h3.8V41.754c0-1.6.3-3.136,2.266-3.136,1.945,0,1.968,1.808,1.968,3.227V47.8h3.8Z"
        transform="translate(-21.823 -19.509)"
        fill="#fff"
      />
      <path
        id="Trazado_18811"
        data-name="Trazado 18811"
        d="M11.3,36.6h3.8V48.822H11.3Z"
        transform="translate(-1.002 -20.512)"
        fill="#fff"
      />
      <path
        id="Trazado_18812"
        data-name="Trazado 18812"
        d="M12.2,10a2.209,2.209,0,1,0,2.2,2.2A2.2,2.2,0,0,0,12.2,10Z"
        transform="translate(0)"
        fill="#fff"
      />
    </g>
  </svg>
);
export default Insta;
